import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../_services/auth/auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  isLegalEntitySource = new BehaviorSubject<boolean | null>(null);
  isLegalEntity$ = this.isLegalEntitySource.pipe(
    switchMap((isLegalEntity) => {
      if (!this.authService.isUserLoggedIn()) return of(null);
      if (isLegalEntity === null) {
        return this.getGeneralSettings().pipe(
          tap((generalSettings) =>
            this.isLegalEntitySource.next(generalSettings.isLegalEntity),
          ),
        );
      }
      return of(isLegalEntity);
    }),
  );

  constructor(private http: HttpClient, private authService: AuthService) {}

  getAddressSetings(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}settings/address/${this.authService.getUserId()}`,
    );
  }

  getGeneralSettings(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}settings/general/${this.authService.getUserId()}`,
    );
  }

  getPosData(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}settings/pos_data/${this.authService.getUserId()}`,
    );
  }

  saveAddressSetings(settings): Observable<any> {
    settings.user_id = this.authService.getUserId();
    return this.http.put(`${environment.apiUrl}settings/address`, settings);
  }

  saveGeneralSetings(settings): Observable<any> {
    settings.user_id = this.authService.getUserId();
    return this.http
      .put(`${environment.apiUrl}settings/general`, settings, {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      })
      .pipe(
        tap((settings) => {
          this.isLegalEntitySource.next(settings.isLegalEntity);
        }),
      );
  }

  saveSecuritySettings(settings): Observable<any> {
    settings.user_id = this.authService.getUserId();
    return this.http.put(`${environment.apiUrl}settings/security`, settings, {
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR,
      ),
    });
  }

  validatePOSApiToken(settings): Observable<any> {
    settings.userId = this.authService.getUserId();
    return this.http.put(
      `${environment.apiUrl}settings/pos_verification`,
      settings,
    );
  }

  savePaymentSettings(settings): Observable<any> {
    settings.user_id = this.authService.getUserId();
    return this.http.put(`${environment.apiUrl}settings/payment`, settings);
  }

  preparePaymentIntegrationData(): Observable<any> {
    return this.http.get(
      `${
        environment.apiUrl
      }settings/prepare_payment_integration_data/${this.authService.getUserId()}`,
    );
  }

  registerPayment(url: string, data: any): Observable<any> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.set(key, data[key]);
    });
    return this.http.post(url, formData);
  }
}
