import { ElementRef, Renderer2 } from '@angular/core';
import { Color } from './dulo-image-editor/_models/color';

export const emailREG =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function passwordsMatchValidator(
  group: FormGroup,
): ValidationErrors | null {
  return group?.get('password').value === group?.get('passwordAgain').value ||
    !group?.get('passwordAgain').value
    ? null
    : { passwordsDoNotMatch: true };
}

export function priceSmallerThanBasePrice(
  control: FormGroup,
  priceControlName?: string,
): ValidationErrors | null {
  const price = control.get('price') || control.get(priceControlName);
  const basePrice = control.get('basePrice');

  return price && basePrice && +price.value < +basePrice.value
    ? { priceSmallerThanBasePrice: true }
    : null;
}

export function arrayComplement<T>(arr1: T[], arr2: T[]): T[] {
  // Use filter() to get elements in arr1 that are not in arr2
  return arr1.filter((item) => !arr2.includes(item));
}

export function arrayIntersection<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter((item) => arr2.includes(item));
}

export function hasChild(root: any, el: any, childrenKey: string): boolean {
  if (!root) {
    return false;
  }
  if (root === el) {
    return true;
  }
  let result = false;
  if (root[childrenKey]) {
    root[childrenKey].forEach((child) => {
      result = result || hasChild(child, el, childrenKey);
    });
  }
  return result;
}

export function convertTreeToList(tree: any, childrenKey: string): any[] {
  if (!tree || tree.length === 0) {
    return [];
  }
  let array = tree;
  tree.forEach((el) => {
    array = array.concat(convertTreeToList(el[childrenKey], childrenKey));
  });
  return array;
}

export function findElementBy(
  value: any,
  nodes: any,
  keyKey: string,
  childrenKey: string,
): any {
  for (const node of nodes) {
    if (node.data[keyKey] === value) {
      return node;
    }
    if (node[childrenKey]) {
      const result = findElementBy(
        value,
        node[childrenKey],
        keyKey,
        childrenKey,
      );
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function getLeaves(tree: any, leaves: any[], childrenKey: string): any {
  for (const node of tree) {
    if (node[childrenKey].length > 0) {
      getLeaves(node[childrenKey], leaves, childrenKey);
    } else {
      leaves.push(node);
    }
  }

  return leaves;
}

export function areArraysEqual(a, b): boolean {
  if (a.length !== b.length) return false;
  return a.every((id) => b.includes(id));
}

export function normalizeHeight(
  className: string,
  elRef: ElementRef,
  renderer: Renderer2,
): void {
  let maxElementsHeight = -1;
  const elements = elRef.nativeElement.querySelectorAll(
    '.' + className,
  ) as HTMLElement[];
  elements.forEach((el) => {
    renderer.removeStyle(el, 'height');
    maxElementsHeight =
      el.clientHeight > maxElementsHeight ? el.clientHeight : maxElementsHeight;
  });
  elements.forEach((pc) => {
    renderer.setStyle(pc, 'height', maxElementsHeight + 'px');
  });
}

export function createBackgroundColorString(color: Color): any {
  return 'rgba(' + color.red + ',' + color.green + ',' + color.blue + ',1)';
}

export function createBackgroundColor(color: Color): any {
  return {
    'background-color':
      'rgba(' + color.red + ',' + color.green + ',' + color.blue + ',1)',
  };
}

export function firstDivisibleByBiggerThan(
  denominator: number,
  numberOver: number,
): number {
  return denominator * (Math.floor(numberOver / denominator) + 1);
}

export function exportQueryParamsFromURL(url: string): any {
  const urlParts = url.split('?');
  if (urlParts.length === 1) {
    return {};
  }
  return url
    .split('?')[1]
    .split('&')
    .reduce((acc, curr) => {
      const keyValue = curr.split('=');
      const key = keyValue[0];
      const value = keyValue[1];
      acc[key] = value;
      return acc;
    }, {});
}

export function getFileType(event, file): string {
  const arr = new Uint8Array(event.target['result']).subarray(0, 4);
  let header = '';
  for (let i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return file.type;
  }
}

export const isEllipsisActive = (e: HTMLElement): boolean =>
  e.offsetWidth < e.scrollWidth;

export const isEllipsisActive$ = (e: HTMLElement): Observable<boolean> => {
  return fromEvent(e, 'mouseover').pipe(
    startWith(false),
    map(() => {
      return isEllipsisActive(e);
    }),
  );
};

import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function trimAndValidateEmail(
  control: AbstractControl,
): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null; // don't validate empty values to allow optional controls
  }
  return EMAIL_REGEXP.test(control.value.trim()) ? null : { email: true };
}

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

export const hexToRgb = (hex: string): any => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
};

export class BootstrapBreakpoints {
  static ExtraSmall = '(max-width: 575.98px)';
  static Small = '(min-width: 576px) and (max-width: 767.98px)';
  static Medium = '(min-width: 768px) and (max-width: 991.98px)';
  static Large = '(min-width: 992px) and (max-width: 1199.98px)';
  static ExtraLarge = '(min-width: 1200px) and (max-width: 1449.98px)';
  static ExtraExtraLarge = '(min-width: 1450px)';

  static breakpoints = [
    BootstrapBreakpoints.ExtraSmall,
    BootstrapBreakpoints.Small,
    BootstrapBreakpoints.Medium,
    BootstrapBreakpoints.Large,
    BootstrapBreakpoints.ExtraLarge,
    BootstrapBreakpoints.ExtraExtraLarge,
  ];
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class Regex {
  static emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static EMAIL_REGEX = 'EMAIL_REGEX';

  static REGEX: { [key: string]: RegExp } = {
    EMAIL_REGEX: Regex.emailRegex,
  };
}

export const mod97 = (br, os = 100): number => {
  let c,
    x,
    kb = 0;

  for (x = br.length - 1; !(x < 0); x--) {
    c = parseInt(br.charAt(x));
    kb = (kb + os * c) % 97;
    os = (os * 10) % 97;
  }

  kb = 98 - kb;

  return kb;
};

export const toCamelCase = (value: string): string =>
  value?.replace(/[_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

export function generateTableRequestParams(data: any, filtersMeta: any): any {
  const params = {
    size: data.rows,
    page: data.first / data.rows,
    sort_by: data.sortField
      ? toCamelCase(filtersMeta[data.sortField].key)
      : undefined,
    sort_order: data.sortOrder === 1 ? 'asc' : 'desc',
  };

  if (params.sort_by === undefined) {
    delete params.sort_by;
    delete params.sort_order;
  }

  Object.keys(data.filters).forEach((filter: any) => {
    let param = '';
    if (data.filters[filter].matchMode === 'numberBetween') {
      const [from, to] = Object.values(data.filters[filter].value);

      const matchMode = from && to ? `btn:` : from ? `gt:` : `lt:`;

      param =
        from && to
          ? `${matchMode}${from},${to}`
          : `${matchMode}${from ? from : to}`;
    } else {
      param =
        data.filters[filter].value instanceof Array
          ? `${filtersMeta[filter].matchMode}${data.filters[filter].value.join(
              ',',
            )}`
          : `${filtersMeta[filter].matchMode}${data.filters[filter].value}`;
    }

    params[filtersMeta[filter].key] = param;
  });

  return params;
}

export function doFormFiltersExist(filters: any, filtersMeta: any): boolean {
  return (
    Object.entries(filters).filter(([key, value]: [string, any]) => {
      return !(
        value === null ||
        value === undefined ||
        value === '' ||
        (filtersMeta[key].numberBetween && !value.from && !value.to) ||
        (value instanceof Array && value.length === 0)
      );
    }).length > 0
  );
}

export function generateFormFilterParams(data: any, filtersMeta: any): any {
  const params = {
    size: data.rows,
    page: data.first / data.rows,
    sort_by: data.sortField,
    sort_order: data.sortOrder === 1 ? 'asc' : 'desc',
  };

  if (params.sort_by === undefined) {
    delete params.sort_by;
    delete params.sort_order;
  }

  Object.keys(data.filters).forEach((filter: any) => {
    if (
      data.filters[filter] === null ||
      data.filters[filter] === undefined ||
      data.filters[filter] === '' ||
      (filtersMeta[filter].numberBetween &&
        !data.filters[filter].from &&
        !data.filters[filter].to) ||
      (data.filters[filter] instanceof Array &&
        data.filters[filter].length === 0)
    ) {
      return;
    }
    let param = '';
    if (filtersMeta[filter].numberBetween) {
      const [from, to] = Object.values(data.filters[filter]);

      const matchMode = from && to ? `btn:` : from ? `gt:` : `lt:`;

      param =
        from && to
          ? `${matchMode}${from},${to}`
          : `${matchMode}${from ? from : to}`;
    } else {
      param =
        data.filters[filter] instanceof Array
          ? `${filtersMeta[filter].matchMode}${data.filters[filter].join(',')}`
          : `${filtersMeta[filter].matchMode}${data.filters[filter]}`;
    }

    params[filtersMeta[filter].key] = param;
  });

  return params;
}

export const scrollElementIntoView = (
  element?: HTMLElement,
  behavior: 'smooth' | 'auto' = 'smooth',
  position: 'center' | 'top' = 'center',
): void => {
  const scrollTop = window.scrollY || element?.scrollTop;

  const finalOffset =
    element?.getBoundingClientRect().top +
      scrollTop -
      (position === 'center'
        ? window.innerHeight / 2 - element?.getBoundingClientRect().height / 2
        : 0) || 0;

  window.parent.scrollTo({
    top: finalOffset,
    behavior: behavior,
  });
};

export const recommendedPrices = new Map([
  [
    'RS',
    {
      '3': '1800 - 2000',
      '4': '1800 - 2000',
      '5': '3000 - 3500',
      '6': '1800 - 2000',
      '9': '2000 - 2500',
      '10': '2000 - 2500',
      '11': '2000 - 2500',
      '44': '900 - 1200',
      '45': '1100 - 1400',
      '46': '900 - 1200',
      '47': '900 - 1200',
      '48': '3000 - 3500',
      '49': '3000 - 3500',
      '50': '1200 - 1500',
      '51': '1200 - 1500',
      '52': '700 - 1000',
      '53': '1900 - 2100',
      '54': '1800 - 2000',
      '56': '1800 - 2000',
      '58': '800 - 1200',
      '59': '1100 - 1500',
    },
  ],
  [
    'HR',
    {
      '1': '20 - 24',
      '2': '20 - 24',
      '3': '20 - 24',
      '4': '30 - 34',
    },
  ],
]);
