import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuloImageEditorComponent } from './dulo-image-editor.component';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ResizerDirective } from './_directives/resizer.directive';
import { CheckCacheDirective } from './_directives/check-cache.directive';
import { KaduTabsModule } from '@kadung/kadung/kadu-tabs';
import { KaduButtonModule } from '@kadung/kadung/kadu-button';
import { KaduInputModule } from '@kadung/kadung/kadu-input';
import { KaduFileUploadModule } from '@kadung/kadung/kadu-file-upload';
// import { SquareModule } from '../square/square.module';
import { SquareDirective } from '../square/square.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { KaduScrollPanelModule } from '@kadung/kadung/kadu-scroll-panel';
import { KaduTooltipModule } from '@kadung/kadung/kadu-tooltip';
import { DuloImageEditorService } from './_services/dulo-image-editor.service';

@NgModule({
  declarations: [
    CheckCacheDirective,
    DuloImageEditorComponent,
    ResizerDirective,
    SquareDirective,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ColorPickerModule,
    KaduFileUploadModule,
    KaduButtonModule,
    KaduInputModule,
    KaduTabsModule,
    TranslocoModule,
    KaduScrollPanelModule,
    KaduTooltipModule,
    // SquareModule,
  ],
  providers: [DuloImageEditorService],
  exports: [DuloImageEditorComponent, CheckCacheDirective, ColorPickerModule],
})
export class DuloImageEditorModule {}
