import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Notification } from 'src/app/_models/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications$ = new BehaviorSubject<Notification[]>([]);
  priorityNotification$ = new BehaviorSubject<Notification>(null);

  addNotification(notification: Notification): void {
    if (!this.notifications$.value.find((n) => n.name === notification.name)) {
      this.notifications$.next([
        ...this.notifications$.value,
        {
          ...notification,
          order: this.notifications$.value.length,
        },
      ]);
    }
    this.sortByOrder();
    this.getPriorityNotification();
  }

  getPriorityNotification(): void {
    const preventOthersNotifications = this.notifications$.value.filter(
      (notification) => notification.preventOthers,
    );
    preventOthersNotifications.sort((a, b) => a.order - b.order);

    this.priorityNotification$.next(preventOthersNotifications[0]);
  }

  sortByOrder(): void {
    this.notifications$.value.sort((a, b) => a.order - b.order);
  }

  removeNotification(notification: Notification): void {
    this.notifications$.next(
      this.notifications$.value.filter((n) => n.name !== notification.name),
    );

    this.sortByOrder();
  }
}
