import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from '@angular/core';
import { KaduConfirmDialogComponent } from '@kadung/kadung/kadu-confirm-dialog/kadu-confirm-dialog.component';
import { FormErrorsComponent } from '@kadung/kadung/kadu-form';
import { TranslocoService } from '@ngneat/transloco';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from './_services/auth/auth.service';
import { PopupService } from './_services/popup/popup.service';
import { ThemeBuilderService } from './dashboard/theme-builder/_services/theme-builder.service';
import { LoaderService } from './_services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  title = 'BrendlyPlatform';
  @ViewChild('popupComponent', { static: true })
  popupComponent: KaduConfirmDialogComponent;

  authToken$ = this.authService.authToken$;
  builderActive$ = this.themeBuilderService.builderActive$;
  isLoading$ = this.loaderService.isLoading.asObservable();

  constructor(
    private gtmService: GoogleTagManagerService,
    private authService: AuthService,
    private themeBuilderService: ThemeBuilderService,
    private popupService: PopupService,
    private translateService: TranslocoService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.loaderService.hide();

    this.translateService.selectTranslate('errors.required').subscribe(() => {
      FormErrorsComponent.prototype.Errors = {
        required: this.translateService.translate('errors.required'),
      };
    });

    this.popupComponent.key = 'kadu-confirm-dialog';
    this.popupService.kaduConfirmDialog = this.popupComponent;
  }

  ngAfterViewInit(): void {
    this.gtmService.addGtmToDom();
  }
}
