<div class="p-3">
  <form [formGroup]="form" (ngSubmit)="submit()" class="offset-2 col-8">
    <div class="form-group">
      <label for="customer_segment" class="mb-2">Ti si?</label>
      <select class="form-control mb-2" formControlName="customer_segment">
        <option value="designer">Dizajner</option>
        <option value="influencer">Influenser</option>
        <option value="marketer">Marketar</option>
        <option value="false">Nijedan od ponudjenih odgovora</option>
      </select>
      <input
        *ngIf="form.get('customer_segment_free_entry')"
        type="text"
        placeholder="Ti si?"
        formControlName="customer_segment_free_entry"
        class="form-control w-100 mb-2"
        style="height: 36px"
      />
    </div>

    <div class="form-group">
      <label for="referer_origin" class="mb-2">
        Za Brendly si saznao preko?
      </label>
      <select class="form-control mb-2" formControlName="referer_origin">
        <option value="instagram">Instagrama</option>
        <option value="facebook">Fejsbuka</option>
        <option value="tiktok">Tiktok</option>
        <option value="google">Pretrage preko Googla</option>
        <option value="recommendation">Preporuke</option>
        <option value="false">Nijedan od ponudjenih odgovora</option>
      </select>
      <input
        *ngIf="form.get('referer_origin_free_entry')"
        type="text"
        placeholder="Za Brendly si saznao preko?"
        formControlName="referer_origin_free_entry"
        class="form-control w-100 mb-2"
        style="height: 36px"
      />
    </div>

    <div class="mt-4">
      <button
        type="submit"
        class="btn btn-primary w-100 h-px-50"
        [disabled]="!form.valid"
      >
        Potvrdi
      </button>
    </div>
  </form>
</div>
