<section
  class="d-flex justify-content-center align-items-center h-100 w-100 bg-secondary py-5"
>
  <div class="wrapper rounded-px-20 h-100">
    <kadu-form
      [controls]="formControls"
      elClass="py-5 h-100 d-flex flex-column justify-content-evenly align-items-center"
      (submitHandler)="login($event)"
    >
      <ng-template kaduTemplate let-formGroup>
        <ng-container [formGroup]="formGroup">
          <h4 class="m-0 fw-700">
            {{ "auth.login.signIntoBrendly" | transloco }}
          </h4>
          <div class="w-100 px-3 px-sm-5 d-flex flex-column">
            <div>
              <kadu-input
                #email
                formControlName="email"
                elClass="p-2 p3"
                placeholder="Email"
              ></kadu-input>
              <app-form-errors
                [formElement]="email"
                [Errors]="{ email: 'errors.email' | transloco }"
                [control]="formGroup.get('email')"
              ></app-form-errors>
            </div>

            <div>
              <kadu-input
                #password
                formControlName="password"
                type="password"
                elClass="p3 p-2"
                [placeholder]="'common.password' | transloco"
              ></kadu-input>
              <app-form-errors
                [formElement]="password"
                [control]="formGroup.get('password')"
                [Errors]="{ failLogin: 'errors.login' | transloco }"
              ></app-form-errors>
            </div>

            <p
              routerLink="/reset-password"
              class="d-flex justify-content-start justify-content-sm-end m-0 p3 fw-500 cursor-pointer text-clickable"
            >
              {{ "common.forgottenPassword" | transloco }}
            </p>
          </div>

          <div class="submit-button">
            <kadu-button
              kaduSubmitButton
              type="submit"
              class="d-flex justify-content-center w-100"
              elClass="fw-600 btn btn-medium btn-submit w-75"
            >
              {{ "common.signIn" | transloco }}
            </kadu-button>
            <p
              routerLink="/signup"
              class="mb-0 mt-2 p3 fw-500 text-clickable cursor-pointer"
            >
              {{ "auth.login.noAccount" | transloco }}
              {{ "auth.login.signup" | transloco }}
            </p>
          </div>
        </ng-container>
      </ng-template>
    </kadu-form>
  </div>
</section>
