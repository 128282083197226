import { Regex } from 'src/app/shared/util';

const defaultThemeOnFileServerUrl =
  'https://brendly-prod.s3.eu-central-1.amazonaws.com/themes/1/';

export const defaultSite: any = {
  style: {
    'font-family': 'Montserrat',
    'primary-color': '#f3364c',
    'secondary-color': '#ffffff',
    siteElements: {
      footerColor: {
        type: 'style',
        value: '#333333',
      },
      footerTextColor: {
        type: 'class-secondary-color',
        value: 'secondary-color',
      },
      buttonColor: {
        type: 'class-primary-color',
        value: 'primary-color',
      },
      buttonHoverColor: {
        type: 'style',
        value: '#333333',
      },
      buttonTextColor: {
        type: 'class-secondary-color',
        value: 'secondary-color',
      },
    },
  },
  screens: {
    '(max-width: 575.98px)': {
      pages: [
        {
          id: '1',
          name: 'Početna',
          homepage: true,
          active: false,
          clickable: true,
          type: 'section',
          style: {
            'grid-template-columns': '100%',
            'grid-template-rows': 'auto',
            'max-width': '100vw',
          },
          navigation: { hrefType: 'internal', href: '/' },
          allowElementsSorting: true,
          sections: [
            {
              id: '1-1',
              name: 'Baner',

              type: 'section',
              active: true,
              canHaveDifferentSettingsOnMultipleScreens: true,
              position: { column: 1, row: 1 },
              background: {
                enabled: true,
                image: `${defaultThemeOnFileServerUrl}banner.jpg`,
                imageOptions: {
                  repeat: 'no-repeat',
                  size: 'cover',
                  position: 'center center',
                },
                mask: {
                  type: 'two-colors',
                  direction: ['to right'],
                  colorOne: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0.7,
                  },
                  colorTwo: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0,
                  },
                },
              },
              style: {
                'background-image': `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url('${defaultThemeOnFileServerUrl}banner.jpg')`,
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-position': 'center center',
                height: '400px',
                'margin-bottom': '30px',
                'grid-template-rows': '1fr 1fr',
              },
              sections: [
                {
                  id: '1-1-1',
                  name: 'Glavni tekst',

                  type: 'textarea',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'justify-self': 'start',
                    'align-self': 'end',
                    'word-break': 'break-word',
                    'letter-spacing': '2px',
                    'font-weight': 'bold',
                    color: '#ffffff',
                  },
                  class: 'h4',
                  textStyle: {
                    fontWeight: 'bold',
                    color: {
                      type: 'class-secondary-color',
                      value: 'secondary-color',
                    },
                  },
                  subtype: 'h1',
                  data: { text: 'PREDSTAVI SE' },
                },
                {
                  id: '1-1-2',
                  name: 'Dugme',
                  type: 'button',

                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'max-width': '260px',
                    'min-height': '40px',
                    'justify-self': 'start',
                    'align-self': 'start',
                    top: '20px',
                    border: 'none',
                    'font-weight': 'bold',
                  },
                  textStyle: {
                    fontWeight: 'bold',
                  },
                  class:
                    'btn button-color button-text-color button-hover-color p2',
                  navigation: {
                    enabled: true,
                    editable: false,
                    hrefType: 'category',
                    href: 'Sve',
                  },
                  data: { text: 'POGLEDAJ PROIZVODE' },
                },
              ],
            },
            {
              id: '1-2',
              name: 'Kategorije',
              type: 'section',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                'margin-left': '20px',
                'margin-right': '20px',
                'margin-bottom': '10px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-2-1',
                  name: 'Naslov sekcije',

                  active: true,
                  style: {
                    'margin-bottom': '16px',
                  },
                  type: 'section',
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-2-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h4',
                      data: { text: 'IZABRANE KATEGORIJE' },
                    },
                  ],
                },

                {
                  id: '1-2-3',
                  name: '2 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: { 'grid-gap': '16px' },
                  sections: [
                    {
                      id: '1-2-3-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-3-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-2',
                  name: '3 kategorije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: { 'grid-gap': '16px' },
                  sections: [
                    {
                      id: '1-2-2-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,
                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 3 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-4',
                  name: '4 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: { 'grid-gap': '16px' },
                  sections: [
                    {
                      id: '1-2-4-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 3 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 4 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-5',
                  name: '6 kategorija',
                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: { 'grid-gap': '16px' },
                  sections: [
                    {
                      id: '1-2-5-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 3 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 4 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-5',
                      name: 'Kategorija 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 5 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-6',
                      name: 'Kategorija 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 6 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: '1-3',
              name: 'O nama',
              type: 'section',
              active: true,
              position: { column: 1, row: 3 },
              style: {
                'margin-left': '20px',
                'margin-right': '20px',
                'margin-bottom': '30px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-3-1',
                  name: 'Tekst',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'background-color': '#f8f8f8',
                    padding: '20px',
                    'margin-bottom': '30px',
                    height: '100%',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                  },
                  sections: [
                    {
                      id: '1-3-1-1',
                      name: 'Naslov',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'font-weight': 'bold',
                        'margin-bottom': '20px',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      class: 'h5',
                      data: { text: 'O nama' },
                    },
                    {
                      id: '1-3-1-2',
                      name: 'Opis',

                      type: 'textarea',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      class: 'p3',
                      data: {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                      },
                    },
                  ],
                },
                {
                  id: '1-3-2',
                  name: 'Fotografija',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  style: {},
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-3-2-1',
                      name: 'Fotografija',
                      type: 'image',

                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 612px',
                      style: {
                        'aspect-ratio': '392/400',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}about-us.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-4',
              name: 'Proizvodi',
              type: 'section',
              active: 'true',
              position: { column: 1, row: 4 },
              style: {
                'grid-template-columns': '100%',
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-4-1',
                  name: 'Naslov sekcije',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  sections: [
                    {
                      id: '1-4-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'IZABRANI PROIZVODI' },
                    },
                  ],
                },

                {
                  id: '1-4-4',
                  name: '3 proizvoda',

                  type: 'section',
                  isCarousel: true,
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-4-4-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-5',
                  name: '4 proizvoda',

                  type: 'section',
                  isCarousel: true,
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-4-5-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-2',
                  name: '5 proizvoda',
                  type: 'section',
                  isCarousel: true,
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'column-gap': '20px',
                    'row-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-4-2-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-6',
                  name: '6 proizvoda',

                  type: 'section',
                  isCarousel: true,
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-4-6-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-6',
                      name: 'Proizvod 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 6, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-6-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-3',
                  name: 'Pogledaj sve',

                  active: 'true',
                  type: 'section',
                  position: { column: 1, row: 6 },
                  style: {},
                  sections: [
                    {
                      id: '1-4-3-1',
                      name: 'Dugme',

                      type: 'button',
                      active: true,
                      position: { column: 1, row: 1 },
                      class:
                        'btn button-color button-text-color button-hover-color',
                      style: {
                        height: '50px',
                        width: '200px',
                        'justify-self': 'center',
                      },
                      textStyle: {},
                      navigation: {
                        enabled: true,
                        editable: false,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      data: { text: 'Pogledaj sve' },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-5',
              name: 'Kupci',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 5 },
              style: {
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-5-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  active: true,
                  sections: [
                    {
                      id: '1-5-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠI KUPCI' },
                    },
                  ],
                },

                {
                  id: '1-5-3',
                  name: '3 kupca',

                  isCarousel: true,
                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-5-3-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-4',
                  name: '4 kupca',

                  isCarousel: true,
                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-5-4-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-5',
                  name: '5 kupaca',

                  isCarousel: true,
                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-5-5-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-2',
                  name: '6 kupaca',

                  isCarousel: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-5-2-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-6',
                      name: 'Kupac 6',

                      type: 'image',
                      active: true,
                      position: { column: 6, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-6.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-6',
              name: 'Instagram',

              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: '1-6-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  active: true,
                  style: {
                    'margin-bottom': '30px',
                  },
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-6-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'text-align': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        textAlign: 'center',
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠ INSTAGRAM' },
                    },
                  ],
                },
                {
                  id: '1-6-2',
                  name: 'Fotografije',
                  isCarousel: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'column-gap': '20px',
                    'grid-template-columns': '100%',
                  },
                  sections: [
                    {
                      id: '1-6-2-1',
                      name: 'Fotografija 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-1.jpg` },
                    },
                    {
                      id: '1-6-2-2',
                      name: 'Fotografija 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-2.jpg` },
                    },
                    {
                      id: '1-6-2-3',
                      name: 'Fotografija 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-3.jpg` },
                    },
                    {
                      id: '1-6-2-4',
                      name: 'Fotografija 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-4.jpg` },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '2',
          name: 'Prodavnica',
          active: true,
          homepageFallback: true,
          navigation: { hrefType: 'internal', href: '/shop' },
        },
      ],
      elements: [
        {
          id: 'e-1',
          type: 'header',
          style: {
            'padding-left': '0.75rem',
            'padding-right': '0.75rem',
            height: '60px',
            'background-color': '#fff',
          },
          sections: [
            {
              id: 'e-1-1',
              type: 'section',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                width: '100%',
                height: '100%',
                'grid-template-columns': '1fr 28px auto 35px auto',
              },
              sections: [
                {
                  id: 'e-1-1-1',
                  type: 'image',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    height: '40px',
                    width: '40px',
                    'border-radius': '50%',
                    _wrapper: { 'justify-content': 'start' },
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: '/',
                  },
                  data: {
                    srcFn: '{return this.shopService.shop.value.logoUrl;}',
                  },
                },
                {
                  id: 'e-1-1-2',
                  type: 'connected-shops',
                  active:
                    '{return this.shopService.shop.value.connectedShops?.length > 0;}',
                  position: { column: 2, row: 1 },
                  style: {
                    width: '30px',
                    'font-size': '20px',
                  },
                },
                {
                  id: 'e-1-1-3',
                  type: 'search',
                  active: true,
                  position: { column: 3, row: 1 },
                  style: {
                    height: '47px',
                    border: 'none',
                    _icon: {
                      height: '30px',
                      width: '30px',
                      'font-size': '20px',
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                    },
                    _button: { height: '47px', border: 'none' },
                  },
                },
                {
                  id: 'e-1-1-4',
                  type: 'cart-toggler',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    height: '30px',
                    width: '30px',
                    'font-size': '20px',
                  },
                },
                {
                  id: 'e-1-1-5',
                  type: 'nav',
                  active: true,
                  position: { column: 5, row: 1 },
                  style: {
                    _iconWrapper: {
                      display: 'flex',
                      'justify-content': 'flex-end',
                      'align-items': 'center',
                      width: '100%',
                      height: '100%',
                    },
                    _icon: {
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                      height: '20px',
                      width: '20px',
                      'font-size': '20px',
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          id: 'e-2',
          type: 'footer',
          style: {
            'margin-top': '1.5rem',
            'padding-top': '1.5rem',
          },
          class: 'footer-color footer-text-color',
          sections: [
            {
              id: 'e-2-1',
              type: 'text',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '0.5rem',
              },
              class: 'p2',
              data: { text: 'Saznaj kada stignu novi proizvodi' },
            },
            {
              id: 'e-2-2',
              type: 'input',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                _main: {
                  'justify-self': 'center',
                  'background-color': 'white',
                  display: 'inline-block',
                  'margin-bottom': '1.5rem',
                },
                _input: {
                  border: 'none',
                  height: 'inherit',
                  padding: '0.5rem',
                  'max-width': 'calc(100vw - 150px)',
                },
                _button: {
                  width: '118px',
                  margin: '0.25rem',
                  _element: {},
                },
              },
              class: {
                _button: 'p2 button-color button-text-color button-hover-color',
                _input: 'p2 outline-none-focus_visible',
              },
              data: {
                placeholder: 'Unesi svoju email adresu',
                ctaLabel: 'Prijavi se',
                regexType: Regex.EMAIL_REGEX,
                regexMessage: 'Morate uneti e-mail adresu',
              },
              action: 'subscribe',
            },
            {
              id: 'e-2-3',
              type: 'text',
              active:
                '{ return `${this.shopService.shop.value.description !== null} && ${this.shopService.shop.value.description !== ""}`;}',
              position: { column: 1, row: 3 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '2rem',
                'text-align': 'center',
                'max-width': '70%',
              },
              class: 'h6',
              data: {
                textFn:
                  '{ return `${this.shopService.shop.value.description}`;}',
              },
            },
            {
              id: 'e-2-4',
              type: 'section',
              active: true,
              position: { column: 1, row: 4 },
              style: {
                display: 'flex',
                'justify-content': 'center',
                width: '160px',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-4-1',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.instagram !== null} && ${this.shopService.shop.value.instagram !== ""}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.instagram}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-instagram' },
                },
                {
                  id: 'e-2-4-2',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.facebook !== null} && ${this.shopService.shop.value.facebook !== ""}`;}',
                  position: { column: 2, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.facebook}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-facebook' },
                },
                {
                  id: 'e-2-4-3',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.youtube !== null} && ${this.shopService.shop.value.youtube !== ""}`;}',
                  position: { column: 3, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.youtube}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-youtube' },
                },
                {
                  id: 'e-2-4-4',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.twitter !== null} && ${this.shopService.shop.value.twitter !== ""}`;}',
                  position: { column: 4, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.twitter}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-twitter' },
                },
              ],
            },
            {
              id: 'e-2-5',
              type: 'section',
              active: true,
              position: { column: 1, row: 5 },
              style: {
                'margin-left': '15%',
                'margin-right': '15%',
                'text-align': 'center',
                'grid-template-rows': 'repeat(2, auto)',
              },
              sections: [
                {
                  id: 'e-2-5-1',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showEmail !== null} && ${this.shopService.shop.value.showEmail !== false}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '1.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="mailto:${this.shopService.shop.value.email}"><i class="fa fa-envelope me-2"></i>${this.shopService.shop.value.email}</a>`;}',
                  },
                },
                {
                  id: 'e-2-5-2',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showPhoneNumber !== null} && ${this.shopService.shop.value.showPhoneNumber !== false}`;}',
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-bottom': '1.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="tel:${this.shopService.shop.value.phoneNumber}"><i class="fa fa-phone me-2"></i>${this.shopService.shop.value.phoneNumber}</a>`;}',
                  },
                },
              ],
            },
            {
              id: 'e-2-6',
              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': '15%',
                'margin-right': '15%',
                'margin-bottom': '1.5rem',
                'text-align': 'center',
                'grid-template-rows': 'repeat(4, auto)',
              },
              sections: [
                {
                  id: 'e-2-6-1',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: 'reclamation',
                  },
                  data: { text: 'Prijavi reklamaciju' },
                },
                {
                  id: 'e-2-6-2',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/uslovi_kupovine.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Uslovi prodaje' },
                },
                {
                  id: 'e-2-6-3',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 3 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/povracaj_i_zamena.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Povraćaj i zamena' },
                },
                {
                  id: 'e-2-6-4',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 4 },
                  style: {
                    'margin-bottom': '0',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/porucivanje_i_dostava.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Poručivanje i dostava' },
                },
              ],
            },
            {
              id: 'e-2-7',
              type: 'text',
              active: true,
              position: { column: 1, row: 7 },
              style: {
                'margin-bottom': '0.5rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              class: 'p3',
              data: {
                textFn:
                  '{ return `Copyright @ ${new Date().getFullYear()} Brendly`;}',
              },
            },
          ],
        },
      ],
    },
    '(min-width: 768px) and (max-width: 991.98px)': {
      pages: [
        {
          id: '1',
          name: 'Početna',
          homepage: true,
          active: false,
          clickable: true,
          type: 'section',
          style: {
            'grid-template-columns': '100%',
            'grid-template-rows': 'auto',
            'max-width': '100vw',
          },
          navigation: { hrefType: 'internal', href: '/' },
          allowElementsSorting: true,
          sections: [
            {
              id: '1-1',
              name: 'Baner',
              type: 'section',
              active: true,

              canHaveDifferentSettingsOnMultipleScreens: true,
              position: { column: 1, row: 1 },
              background: {
                enabled: true,
                image: `${defaultThemeOnFileServerUrl}banner.jpg`,
                imageOptions: {
                  repeat: 'no-repeat',
                  size: 'cover',
                  position: 'center center',
                },
                mask: {
                  type: 'two-colors',
                  direction: ['to right'],
                  colorOne: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0.7,
                  },
                  colorTwo: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0,
                  },
                },
              },
              style: {
                'background-image': `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url('${defaultThemeOnFileServerUrl}banner.jpg')`,
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-position': 'center center',
                height: '630px',
                'margin-bottom': '30px',
                'grid-template-rows': '1fr 1fr',
              },
              sections: [
                {
                  id: '1-1-1',
                  name: 'Glavni tekst',

                  type: 'textarea',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'justify-self': 'start',
                    'align-self': 'end',
                    'word-break': 'break-word',
                    'letter-spacing': '2px',
                    'font-weight': 'bold',
                    color: '#ffffff',
                  },
                  class: 'h4',
                  textStyle: {
                    fontWeight: 'bold',
                    color: {
                      type: 'class-secondary-color',
                      value: 'secondary-color',
                    },
                  },
                  subtype: 'h1',
                  data: { text: 'PREDSTAVI SE' },
                },
                {
                  id: '1-1-2',
                  name: 'Dugme',

                  type: 'button',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'max-width': '260px',
                    'min-height': '40px',
                    'justify-self': 'start',
                    'align-self': 'start',
                    top: '20px',
                    border: 'none',
                    'font-weight': 'bold',
                  },
                  textStyle: {
                    fontWeight: 'bold',
                  },
                  class:
                    'btn button-color button-text-color button-hover-color p2',
                  navigation: {
                    enabled: true,
                    editable: false,
                    hrefType: 'category',
                    href: 'Sve',
                  },
                  data: { text: 'POGLEDAJ PROIZVODE' },
                },
              ],
            },
            {
              id: '1-2',
              name: 'Kategorije',
              type: 'section',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                'margin-bottom': '10px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-2-1',
                  name: 'Naslov sekcije',

                  active: true,
                  style: {
                    'margin-bottom': '16px',
                  },
                  type: 'section',
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-2-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h4',
                      data: { text: 'IZABRANE KATEGORIJE' },
                    },
                  ],
                },

                {
                  id: '1-2-3',
                  name: '2 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-3-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-3-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-2',
                  name: '3 kategorije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': '400fr 304fr 392fr',
                    'grid-gap': '16px',
                  },
                  sections: [
                    {
                      id: '1-2-2-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      grid: {
                        column: '400fr',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '304fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          resolution: '600px x 789px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '392fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-4',
                  name: '4 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-4-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-5',
                  name: '6 kategorija',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '400fr 304fr 392fr',
                  },
                  sections: [
                    {
                      id: '1-2-5-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-5',
                      name: 'Kategorija 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-6',
                      name: 'Kategorija 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: '1-3',
              name: 'O nama',

              type: 'section',
              active: true,
              position: { column: 1, row: 3 },
              style: {
                'grid-template-columns': '720fr 392fr',
                'column-gap': '16px',
                'margin-bottom': '30px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-3-1',
                  name: 'Tekst',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'background-color': '#f8f8f8',
                    padding: '20px',
                    'margin-bottom': '30px',
                    height: '100%',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                  },
                  grid: {
                    column: '720fr',
                  },
                  sections: [
                    {
                      id: '1-3-1-1',
                      name: 'Naslov',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'font-weight': 'bold',
                        'margin-bottom': '20px',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      class: 'h5',
                      data: { text: 'O nama' },
                    },
                    {
                      id: '1-3-1-2',
                      name: 'Opis',

                      type: 'textarea',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {
                        flex: '1',
                        display: 'flex',
                        'align-items': 'center',
                      },
                      class: 'p3',
                      data: {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                      },
                    },
                  ],
                },
                {
                  id: '1-3-2',
                  name: 'Fotografija',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  style: {},
                  position: { column: 2, row: 1 },
                  grid: {
                    column: '392fr',
                  },
                  sections: [
                    {
                      id: '1-3-2-1',
                      name: 'Fotografija',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 612px',
                      style: {
                        'aspect-ratio': '392/400',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}about-us.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-4',
              name: 'Proizvodi',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 4 },
              style: {
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-4-1',
                  name: 'Naslov sekcije',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  sections: [
                    {
                      id: '1-4-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'IZABRANI PROIZVODI' },
                    },
                  ],
                },

                {
                  id: '1-4-4',
                  name: '3 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-4-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-5',
                  name: '4 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-5-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-2',
                  name: '5 proizvoda',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-2-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-6',
                  name: '6 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-6-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-6',
                      name: 'Proizvod 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 6, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-6-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-3',
                  name: 'Pogledaj sve',
                  active: 'true',
                  type: 'section',
                  position: { column: 1, row: 6 },
                  style: {},
                  sections: [
                    {
                      id: '1-4-3-1',
                      name: 'Dugme',
                      type: 'button',
                      active: true,
                      position: { column: 1, row: 1 },
                      class:
                        'btn button-color button-text-color button-hover-color',
                      style: {
                        height: '50px',
                        width: '200px',
                        'justify-self': 'center',
                      },
                      textStyle: {},
                      navigation: {
                        enabled: true,
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      data: { text: 'Pogledaj sve' },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-5',
              name: 'Kupci',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 5 },
              style: {
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-5-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  active: true,
                  sections: [
                    {
                      id: '1-5-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠI KUPCI' },
                    },
                  ],
                },

                {
                  id: '1-5-3',
                  name: '3 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-3-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-4',
                  name: '4 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-4-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-5',
                  name: '5 kupaca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-5-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-2',
                  name: '6 kupaca',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-2-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-6',
                      name: 'Kupac 6',

                      type: 'image',
                      active: true,
                      position: { column: 6, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-6.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-6',
              name: 'Instagram',

              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {},
              sections: [
                {
                  id: '1-6-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  active: true,
                  style: {
                    'margin-bottom': '30px',
                  },
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-6-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'text-align': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        textAlign: 'center',
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠ INSTAGRAM' },
                    },
                  ],
                },
                {
                  id: '1-6-2',
                  name: 'Fotografije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'column-gap': '20px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-6-2-1',
                      name: 'Fotografija 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-1.jpg` },
                    },
                    {
                      id: '1-6-2-2',
                      name: 'Fotografija 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-2.jpg` },
                    },
                    {
                      id: '1-6-2-3',
                      name: 'Fotografija 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-3.jpg` },
                    },
                    {
                      id: '1-6-2-4',
                      name: 'Fotografija 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-4.jpg` },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '2',
          name: 'Prodavnica',
          active: true,
          homepageFallback: true,
          navigation: { hrefType: 'internal', href: '/shop' },
        },
      ],
      elements: [
        {
          id: 'e-1',
          type: 'header',
          style: { height: '80px', 'background-color': '#fff' },
          sections: [
            {
              id: 'e-1-1',
              type: 'section',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'padding-left': '0.5rem',
                'padding-right': '0.5rem',
                width: '100%',
                height: '100%',
                'grid-template-columns': '90px 1fr',
              },
              sections: [
                {
                  id: 'e-1-1-1',
                  type: 'image',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    height: '46px',
                    width: '46px',
                    'border-radius': '50%',
                    _wrapper: { 'justify-content': 'start' },
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: '/',
                  },
                  data: {
                    srcFn: '{return this.shopService.shop.value.logoUrl;}',
                  },
                },
                {
                  id: 'e-1-1-2',
                  type: 'nav',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {},
                },
                {
                  id: 'e-1-1-3',
                  type: 'connected-shops',
                  active:
                    '{return this.shopService.shop.value.connectedShops?.length > 0;}',
                  position: { column: 3, row: 1 },
                  style: {
                    width: '30px',
                    'font-size': '20px',
                  },
                },
                {
                  id: 'e-1-1-4',
                  type: 'search',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    width: '265px',
                    height: '42px',
                    'margin-right': '40px',
                    'justify-self': 'end',
                    'align-self': 'center',
                    border: 'none',
                    _icon: {
                      height: '30px',
                      width: '30px',
                      'font-size': '20px',
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                    },
                    _button: { height: '47px', border: 'none' },
                  },
                },
                {
                  id: 'e-1-1-5',
                  type: 'cart-toggler',
                  active: true,
                  position: { column: 5, row: 1 },
                  style: {
                    height: '42px',
                    width: '42px',
                    'font-size': '20px',
                  },
                },
              ],
            },
          ],
        },
        {
          id: 'e-2',
          type: 'footer',
          style: {
            'margin-top': '1.5rem',
            'padding-top': '1.5rem',
          },
          class: 'footer-color footer-text-color',
          sections: [
            {
              id: 'e-2-1',
              type: 'text',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '0.5rem',
              },
              class: 'p2',
              data: { text: 'Saznaj kada stignu novi proizvodi' },
            },
            {
              id: 'e-2-2',
              type: 'input',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                _main: {
                  'justify-self': 'center',
                  'background-color': 'white',
                  display: 'inline-block',
                  'margin-bottom': '1.5rem',
                },
                _input: {
                  border: 'none',
                  height: 'inherit',
                  padding: '0.5rem',
                  'max-width': 'calc(100vw - 150px)',
                },
                _button: {
                  width: '118px',
                  margin: '0.25rem',
                  _element: {},
                },
              },
              class: {
                _button: 'p2 button-color button-text-color button-hover-color',
                _input: 'p2 outline-none-focus_visible',
              },
              data: {
                placeholder: 'Unesi svoju email adresu',
                ctaLabel: 'Prijavi se',
                regexType: Regex.EMAIL_REGEX,
                regexMessage: 'Morate uneti e-mail adresu',
              },
              action: 'subscribe',
            },
            {
              id: 'e-2-3',
              type: 'text',
              active:
                '{ return `${this.shopService.shop.value.description !== null} && ${this.shopService.shop.value.description !== ""}`;}',
              position: { column: 1, row: 3 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '2rem',
                'text-align': 'center',
                'max-width': '70%',
              },
              class: 'h6',
              data: {
                textFn:
                  '{ return `${this.shopService.shop.value.description}`;}',
              },
            },
            {
              id: 'e-2-4',
              type: 'section',
              active: true,
              position: { column: 1, row: 4 },
              style: {
                display: 'flex',
                'justify-content': 'center',
                width: '160px',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-4-1',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.instagram !== null} && ${this.shopService.shop.value.instagram !== ""}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.instagram}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-instagram' },
                },
                {
                  id: 'e-2-4-2',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.facebook !== null} && ${this.shopService.shop.value.facebook !== ""}`;}',
                  position: { column: 2, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.facebook}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-facebook' },
                },
                {
                  id: 'e-2-4-3',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.youtube !== null} && ${this.shopService.shop.value.youtube !== ""}`;}',
                  position: { column: 3, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.youtube}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-youtube' },
                },
                {
                  id: 'e-2-4-4',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.twitter !== null} && ${this.shopService.shop.value.twitter !== ""}`;}',
                  position: { column: 4, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.twitter}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-twitter' },
                },
              ],
            },
            {
              id: 'e-2-5',
              type: 'section',
              active: true,
              position: { column: 1, row: 5 },
              style: {
                display: 'flex',
                'justify-content': 'space-evenly',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-5-1',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showEmail !== null} && ${this.shopService.shop.value.showEmail !== false}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="mailto:${this.shopService.shop.value.email}"><i class="fa fa-envelope me-2"></i>${this.shopService.shop.value.email}</a>`;}',
                  },
                },
                {
                  id: 'e-2-5-2',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showPhoneNumber !== null} && ${this.shopService.shop.value.showPhoneNumber !== false}`;}',
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="tel:${this.shopService.shop.value.phoneNumber}"><i class="fa fa-phone me-2"></i>${this.shopService.shop.value.phoneNumber}</a>`;}',
                  },
                },
              ],
            },
            {
              id: 'e-2-6',
              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': '15%',
                'margin-right': '15%',
                'text-align': 'center',
                'grid-template-column': 'repeat(4, 1fr)',
                'margin-bottom': '1.5rem',
              },
              sections: [
                {
                  id: 'e-2-6-1',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: 'reclamation',
                  },
                  data: { text: 'Prijavi reklamaciju' },
                },
                {
                  id: 'e-2-6-2',
                  type: 'text',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/uslovi_kupovine.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Uslovi prodaje' },
                },
                {
                  id: 'e-2-6-3',
                  type: 'text',
                  active: true,
                  position: { column: 3, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/povracaj_i_zamena.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Povraćaj i zamena' },
                },
                {
                  id: 'e-2-6-4',
                  type: 'text',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    'margin-bottom': '0',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/porucivanje_i_dostava.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Poručivanje i dostava' },
                },
              ],
            },
            {
              id: 'e-2-7',
              type: 'text',
              active: true,
              position: { column: 1, row: 7 },
              style: {
                'margin-bottom': '0.5rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              class: 'p3',
              data: {
                textFn:
                  '{ return `Copyright @ ${new Date().getFullYear()} Brendly`;}',
              },
            },
          ],
        },
      ],
    },
    '(min-width: 992px) and (max-width: 1199.98px)': {
      pages: [
        {
          id: '1',
          name: 'Početna',
          homepage: true,
          active: false,
          clickable: true,
          type: 'section',
          style: {
            'grid-template-columns': '100%',
            'grid-template-rows': 'auto',
            'max-width': '100vw',
          },
          navigation: { hrefType: 'internal', href: '/' },
          allowElementsSorting: true,
          sections: [
            {
              id: '1-1',
              name: 'Baner',
              type: 'section',
              active: true,

              canHaveDifferentSettingsOnMultipleScreens: true,
              position: { column: 1, row: 1 },
              background: {
                enabled: true,
                image: `${defaultThemeOnFileServerUrl}banner.jpg`,
                imageOptions: {
                  repeat: 'no-repeat',
                  size: 'cover',
                  position: 'center center',
                },
                mask: {
                  type: 'two-colors',
                  direction: ['to right'],
                  colorOne: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0.7,
                  },
                  colorTwo: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0,
                  },
                },
              },
              style: {
                'background-image': `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url('${defaultThemeOnFileServerUrl}banner.jpg')`,
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-position': 'center center',
                height: '400px',
                'margin-bottom': '30px',
                'grid-template-rows': '1fr 1fr',
                _wrapper: {
                  'max-width': '1080px',
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  height: '100%',
                },
              },
              sections: [
                {
                  id: '1-1-1',
                  name: 'Glavni tekst',
                  type: 'textarea',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'justify-self': 'start',
                    'align-self': 'end',
                    'word-break': 'break-word',
                    'letter-spacing': '2px',
                    'font-weight': 'bold',
                    color: '#ffffff',
                  },
                  class: 'h4',
                  textStyle: {
                    fontWeight: 'bold',
                    color: {
                      type: 'class-secondary-color',
                      value: 'secondary-color',
                    },
                  },
                  subtype: 'h1',
                  data: { text: 'PREDSTAVI SE' },
                },
                {
                  id: '1-1-2',
                  name: 'Dugme',
                  type: 'button',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'max-width': '260px',
                    'min-height': '40px',
                    'justify-self': 'start',
                    'align-self': 'start',
                    top: '20px',
                    border: 'none',
                    'font-weight': 'bold',
                  },
                  textStyle: {
                    fontWeight: 'bold',
                  },
                  class:
                    'btn button-color button-text-color button-hover-color p2',
                  navigation: {
                    enabled: true,
                    editable: false,
                    hrefType: 'category',
                    href: 'Sve',
                  },
                  data: { text: 'POGLEDAJ PROIZVODE' },
                },
              ],
            },
            {
              id: '1-2',
              name: 'Kategorije',
              type: 'section',
              active: true,

              position: { column: 1, row: 2 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1080px',
                'margin-bottom': '10px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-2-1',
                  name: 'Naslov sekcije',

                  active: true,
                  style: {
                    'margin-bottom': '16px',
                  },
                  type: 'section',
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-2-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h4',
                      data: { text: 'IZABRANE KATEGORIJE' },
                    },
                  ],
                },

                {
                  id: '1-2-3',
                  name: '2 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-3-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-3-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-2',
                  name: '3 kategorije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': '400fr 304fr 392fr',
                    'grid-gap': '16px',
                  },
                  sections: [
                    {
                      id: '1-2-2-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '400fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '304fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          position: { column: 1, row: 1 },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '392fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-4',
                  name: '4 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-4-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-5',
                  name: '6 kategorija',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '400fr 304fr 392fr',
                  },
                  sections: [
                    {
                      id: '1-2-5-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-5',
                      name: 'Kategorija 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-6',
                      name: 'Kategorija 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: '1-3',
              name: 'O nama',

              type: 'section',
              active: true,
              position: { column: 1, row: 3 },
              style: {
                'max-width': '1080px',
                'margin-left': 'auto',
                'margin-right': 'auto',
                'margin-bottom': '30px',
                'grid-template-columns': '720fr 392fr',
                'column-gap': '18px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-3-1',
                  name: 'Tekst',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'background-color': '#f8f8f8',
                    padding: '20px',
                    'margin-bottom': '30px',
                    height: '100%',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                  },
                  grid: {
                    column: '720fr',
                  },
                  sections: [
                    {
                      id: '1-3-1-1',
                      name: 'Naslov',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'font-weight': 'bold',
                        'margin-bottom': '20px',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      class: 'h5',
                      data: { text: 'O nama' },
                    },
                    {
                      id: '1-3-1-2',
                      name: 'Opis',

                      type: 'textarea',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {
                        flex: '1',
                        display: 'flex',
                        'align-items': 'center',
                      },
                      class: 'p3',
                      data: {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                      },
                    },
                  ],
                },
                {
                  id: '1-3-2',
                  name: 'Fotografija',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  style: {},
                  position: { column: 2, row: 1 },
                  grid: {
                    column: '392fr',
                  },
                  sections: [
                    {
                      id: '1-3-2-1',
                      name: 'Fotografija',
                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 612px',
                      style: {
                        'aspect-ratio': '392/400',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}about-us.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-4',
              name: 'Proizvodi',
              type: 'section',
              active: true,
              position: { column: 1, row: 4 },
              style: {
                'max-width': '1080px',
                'margin-left': 'auto',
                'margin-right': 'auto',
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-4-1',
                  name: 'Naslov sekcije',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  sections: [
                    {
                      id: '1-4-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'IZABRANI PROIZVODI' },
                    },
                  ],
                },

                {
                  id: '1-4-4',
                  name: '3 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-4-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-5',
                  name: '4 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-5-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-2',
                  name: '5 proizvoda',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-2-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,
                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-6',
                  name: '6 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-6-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-6',
                      name: 'Proizvod 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 6, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-6-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-3',
                  name: 'Pogledaj sve',
                  active: 'true',
                  type: 'section',
                  position: { column: 1, row: 6 },
                  style: {},
                  sections: [
                    {
                      id: '1-4-3-1',
                      name: 'Dugme',
                      type: 'button',
                      active: true,
                      position: { column: 1, row: 1 },
                      class:
                        'btn button-color button-text-color button-hover-color',
                      style: {
                        height: '50px',
                        width: '200px',
                        'justify-self': 'center',
                      },
                      textStyle: {},
                      navigation: {
                        enabled: true,
                        editable: false,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      data: { text: 'Pogledaj sve' },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-5',
              name: 'Kupci',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 5 },
              style: {
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-5-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  active: true,
                  sections: [
                    {
                      id: '1-5-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠI KUPCI' },
                    },
                  ],
                },

                {
                  id: '1-5-3',
                  name: '3 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-3-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-4',
                  name: '4 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-4-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-5',
                  name: '5 kupaca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-5-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-2',
                  name: '6 kupaca',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-2-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-6',
                      name: 'Kupac 6',

                      type: 'image',
                      active: true,
                      position: { column: 6, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-6.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-6',
              name: 'Instagram',

              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'max-width': '1080px',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: '1-6-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  active: true,
                  style: {
                    'margin-bottom': '30px',
                  },
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-6-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'text-align': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        textAlign: 'center',
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠ INSTAGRAM' },
                    },
                  ],
                },
                {
                  id: '1-6-2',
                  name: 'Fotografije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'column-gap': '20px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-6-2-1',
                      name: 'Fotografija 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-1.jpg` },
                    },
                    {
                      id: '1-6-2-2',
                      name: 'Fotografija 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-2.jpg` },
                    },
                    {
                      id: '1-6-2-3',
                      name: 'Fotografija 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-3.jpg` },
                    },
                    {
                      id: '1-6-2-4',
                      name: 'Fotografija 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-4.jpg` },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '2',
          name: 'Prodavnica',
          active: true,
          homepageFallback: true,
          navigation: { hrefType: 'internal', href: '/shop' },
        },
      ],
      elements: [
        {
          id: 'e-1',
          type: 'header',
          style: { height: '80px', 'background-color': '#fff' },
          sections: [
            {
              id: 'e-1-1',
              type: 'section',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'padding-left': '16px',
                'padding-right': '16px',
                width: '100%',
                height: '100%',
                'grid-template-columns': '90px 1fr',
              },
              sections: [
                {
                  id: 'e-1-1-1',
                  type: 'image',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    height: '46px',
                    width: '46px',
                    'border-radius': '50%',
                    _wrapper: { 'justify-content': 'start' },
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: '/',
                  },
                  data: {
                    srcFn: '{return this.shopService.shop.value.logoUrl;}',
                  },
                },
                {
                  id: 'e-1-1-2',
                  type: 'nav',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {},
                },
                {
                  id: 'e-1-1-3',
                  type: 'connected-shops',
                  active:
                    '{return this.shopService.shop.value.connectedShops?.length > 0;}',
                  position: { column: 3, row: 1 },
                  style: {
                    width: '30px',
                    'font-size': '20px',
                  },
                },
                {
                  id: 'e-1-1-4',
                  type: 'search',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    width: '265px',
                    height: '42px',
                    'margin-right': '40px',
                    'justify-self': 'end',
                    'align-self': 'center',
                    border: 'none',
                    _icon: {
                      height: '30px',
                      width: '30px',
                      'font-size': '20px',
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                    },
                    _button: { height: '47px', border: 'none' },
                  },
                },
                {
                  id: 'e-1-1-5',
                  type: 'cart-toggler',
                  active: true,
                  position: { column: 5, row: 1 },
                  style: {
                    height: '42px',
                    width: '42px',
                    'font-size': '20px',
                  },
                },
              ],
            },
          ],
        },
        {
          id: 'e-2',
          type: 'footer',
          style: {
            'padding-top': '1.5rem',
            'margin-top': '1.5rem',
          },
          class: 'footer-color footer-text-color',
          sections: [
            {
              id: 'e-2-1',
              type: 'text',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '0.5rem',
              },
              class: 'p2',
              data: { text: 'Saznaj kada stignu novi proizvodi' },
            },
            {
              id: 'e-2-2',
              type: 'input',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                _main: {
                  'justify-self': 'center',
                  'background-color': 'white',
                  display: 'inline-block',
                  'margin-bottom': '1.5rem',
                },
                _input: {
                  border: 'none',
                  height: 'inherit',
                  padding: '0.5rem',
                  'max-width': 'calc(100vw - 150px)',
                },
                _button: {
                  width: '118px',
                  margin: '0.25rem',
                  _element: {},
                },
              },
              class: {
                _button: 'p2 button-color button-text-color button-hover-color',
                _input: 'p2 outline-none-focus_visible',
              },
              data: {
                placeholder: 'Unesi svoju email adresu',
                ctaLabel: 'Prijavi se',
                regexType: Regex.EMAIL_REGEX,
                regexMessage: 'Morate uneti e-mail adresu',
              },
              action: 'subscribe',
            },
            {
              id: 'e-2-3',
              type: 'text',
              active:
                '{ return `${this.shopService.shop.value.description !== null} && ${this.shopService.shop.value.description !== ""}`;}',
              position: { column: 1, row: 3 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '2rem',
                'text-align': 'center',
                'max-width': '70%',
              },
              class: 'h6',
              data: {
                textFn:
                  '{ return `${this.shopService.shop.value.description}`;}',
              },
            },
            {
              id: 'e-2-4',
              type: 'section',
              active: true,
              position: { column: 1, row: 4 },
              style: {
                display: 'flex',
                'justify-content': 'center',
                width: '160px',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-4-1',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.instagram !== null} && ${this.shopService.shop.value.instagram !== ""}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.instagram}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-instagram' },
                },
                {
                  id: 'e-2-4-2',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.facebook !== null} && ${this.shopService.shop.value.facebook !== ""}`;}',
                  position: { column: 2, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.facebook}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-facebook' },
                },
                {
                  id: 'e-2-4-3',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.youtube !== null} && ${this.shopService.shop.value.youtube !== ""}`;}',
                  position: { column: 3, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.youtube}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-youtube' },
                },
                {
                  id: 'e-2-4-4',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.twitter !== null} && ${this.shopService.shop.value.twitter !== ""}`;}',
                  position: { column: 4, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.twitter}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-twitter' },
                },
              ],
            },
            {
              id: 'e-2-5',
              type: 'section',
              active: true,
              position: { column: 1, row: 5 },
              style: {
                display: 'flex',
                'justify-content': 'space-evenly',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-5-1',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showEmail !== null} && ${this.shopService.shop.value.showEmail !== false}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="mailto:${this.shopService.shop.value.email}"><i class="fa fa-envelope me-2"></i>${this.shopService.shop.value.email}</a>`;}',
                  },
                },
                {
                  id: 'e-2-5-2',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showPhoneNumber !== null} && ${this.shopService.shop.value.showPhoneNumber !== false}`;}',
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="tel:${this.shopService.shop.value.phoneNumber}"><i class="fa fa-phone me-2"></i>${this.shopService.shop.value.phoneNumber}</a>`;}',
                  },
                },
              ],
            },
            {
              id: 'e-2-6',
              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': '15%',
                'margin-right': '15%',
                'text-align': 'center',
                'grid-template-column': 'repeat(4, 1fr)',
                'margin-bottom': '1.5rem',
              },
              sections: [
                {
                  id: 'e-2-6-1',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: 'reclamation',
                  },
                  data: { text: 'Prijavi reklamaciju' },
                },
                {
                  id: 'e-2-6-2',
                  type: 'text',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/uslovi_kupovine.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Uslovi prodaje' },
                },
                {
                  id: 'e-2-6-3',
                  type: 'text',
                  active: true,
                  position: { column: 3, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/povracaj_i_zamena.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Povraćaj i zamena' },
                },
                {
                  id: 'e-2-6-4',
                  type: 'text',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    'margin-bottom': '0',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/porucivanje_i_dostava.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Poručivanje i dostava' },
                },
              ],
            },
            {
              id: 'e-2-7',
              type: 'text',
              active: true,
              position: { column: 1, row: 7 },
              style: {
                'margin-bottom': '0.5rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              class: 'p3',
              data: {
                textFn:
                  '{ return `Copyright @ ${new Date().getFullYear()} Brendly`;}',
              },
            },
          ],
        },
      ],
    },
    '(min-width: 1200px) and (max-width: 1449.98px)': {
      pages: [
        {
          id: '1',
          name: 'Početna',
          homepage: true,
          active: false,
          clickable: true,
          type: 'section',
          style: {
            'grid-template-columns': '100%',
            'grid-template-rows': 'auto',
            'max-width': '100vw',
          },
          navigation: { hrefType: 'internal', href: '/' },
          allowElementsSorting: true,
          sections: [
            {
              id: '1-1',
              name: 'Baner',
              type: 'section',
              active: true,

              canHaveDifferentSettingsOnMultipleScreens: true,
              position: { column: 1, row: 1 },
              background: {
                enabled: true,
                image: `${defaultThemeOnFileServerUrl}banner.jpg`,
                imageOptions: {
                  repeat: 'no-repeat',
                  size: 'cover',
                  position: 'center center',
                },
                mask: {
                  type: 'two-colors',
                  direction: ['to right'],
                  colorOne: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0.7,
                  },
                  colorTwo: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0,
                  },
                },
              },
              style: {
                'background-image': `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url('${defaultThemeOnFileServerUrl}banner.jpg')`,
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-position': 'center center',
                height: '400px',
                'margin-bottom': '30px',
                'grid-template-rows': '1fr 1fr',
                _wrapper: {
                  'max-width': '1140px',
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  height: '100%',
                },
              },
              sections: [
                {
                  id: '1-1-1',
                  name: 'Glavni tekst',
                  type: 'textarea',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'justify-self': 'start',
                    'align-self': 'end',
                    'word-break': 'break-word',
                    'letter-spacing': '2px',
                    'font-weight': 'bold',
                    color: '#ffffff',
                  },
                  class: 'h4',
                  textStyle: {
                    fontWeight: 'bold',
                    color: {
                      type: 'class-secondary-color',
                      value: 'secondary-color',
                    },
                  },
                  subtype: 'h1',
                  data: { text: 'PREDSTAVI SE' },
                },
                {
                  id: '1-1-2',
                  name: 'Dugme',
                  type: 'button',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'max-width': '260px',
                    'min-height': '40px',
                    'justify-self': 'start',
                    'align-self': 'start',
                    top: '20px',
                    border: 'none',
                    'font-weight': 'bold',
                  },
                  textStyle: {
                    fontWeight: 'bold',
                  },
                  class:
                    'btn button-color button-text-color button-hover-color p2',
                  navigation: {
                    enabled: true,
                    editable: false,
                    hrefType: 'category',
                    href: 'Sve',
                  },
                  data: { text: 'POGLEDAJ PROIZVODE' },
                },
              ],
            },
            {
              id: '1-2',
              name: 'Kategorije',
              type: 'section',
              active: true,

              position: { column: 1, row: 2 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1140px',
                'margin-bottom': '10px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-2-1',
                  name: 'Naslov sekcije',

                  active: true,
                  style: {
                    'margin-bottom': '16px',
                  },
                  type: 'section',
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-2-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h4',
                      data: { text: 'IZABRANE KATEGORIJE' },
                    },
                  ],
                },

                {
                  id: '1-2-3',
                  name: '2 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-3-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-3-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-2',
                  name: '3 kategorije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': '400fr 304fr 392fr',
                    'grid-gap': '16px',
                  },
                  sections: [
                    {
                      id: '1-2-2-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '400fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '304fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          position: { column: 1, row: 1 },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '392fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-4',
                  name: '4 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-4-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-5',
                  name: '6 kategorija',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '400fr 304fr 392fr',
                  },
                  sections: [
                    {
                      id: '1-2-5-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-5',
                      name: 'Kategorija 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-6',
                      name: 'Kategorija 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: '1-3',
              name: 'O nama',

              type: 'section',
              active: true,
              position: { column: 1, row: 3 },
              style: {
                'max-width': '1140px',
                'margin-left': 'auto',
                'margin-right': 'auto',
                'margin-bottom': '30px',
                'grid-template-columns': '720fr 392fr',
                'column-gap': '18px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-3-1',
                  name: 'Tekst',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'background-color': '#f8f8f8',
                    padding: '20px',
                    'margin-bottom': '30px',
                    height: '100%',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                  },
                  grid: {
                    column: '720fr',
                  },
                  sections: [
                    {
                      id: '1-3-1-1',
                      name: 'Naslov',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'font-weight': 'bold',
                        'margin-bottom': '20px',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      class: 'h5',
                      data: { text: 'O nama' },
                    },
                    {
                      id: '1-3-1-2',
                      name: 'Opis',

                      type: 'textarea',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {
                        flex: '1',
                        display: 'flex',
                        'align-items': 'center',
                      },
                      class: 'p3',
                      data: {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                      },
                    },
                  ],
                },
                {
                  id: '1-3-2',
                  name: 'Fotografija',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  style: {},
                  position: { column: 2, row: 1 },
                  grid: {
                    column: '392fr',
                  },
                  sections: [
                    {
                      id: '1-3-2-1',
                      name: 'Fotografija',
                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 612px',
                      style: {
                        'aspect-ratio': '392/400',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}about-us.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-4',
              name: 'Proizvodi',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 4 },
              style: {
                'max-width': '1140px',
                'margin-left': 'auto',
                'margin-right': 'auto',
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-4-1',
                  name: 'Naslov sekcije',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  sections: [
                    {
                      id: '1-4-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'IZABRANI PROIZVODI' },
                    },
                  ],
                },

                {
                  id: '1-4-4',
                  name: '3 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-4-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-5',
                  name: '4 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-5-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-2',
                  name: '5 proizvoda',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-2-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-6',
                  name: '6 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-6-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-6',
                      name: 'Proizvod 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 6, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-6-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-3',
                  name: 'Pogledaj sve',
                  active: 'true',
                  type: 'section',
                  position: { column: 1, row: 6 },
                  style: {},
                  sections: [
                    {
                      id: '1-4-3-1',
                      name: 'Dugme',
                      type: 'button',
                      active: true,
                      position: { column: 1, row: 1 },
                      class:
                        'btn button-color button-text-color button-hover-color',
                      style: {
                        height: '50px',
                        width: '200px',
                        'justify-self': 'center',
                      },
                      textStyle: {},
                      navigation: {
                        enabled: true,
                        editable: false,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      data: { text: 'Pogledaj sve' },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-5',
              name: 'Kupci',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 5 },
              style: {
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-5-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  active: true,
                  sections: [
                    {
                      id: '1-5-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠI KUPCI' },
                    },
                  ],
                },

                {
                  id: '1-5-3',
                  name: '3 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-3-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-4',
                  name: '4 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-4-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-5',
                  name: '5 kupaca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-5-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-2',
                  name: '6 kupaca',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-2-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-6',
                      name: 'Kupac 6',

                      type: 'image',
                      active: true,
                      position: { column: 6, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-6.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-6',
              name: 'Instagram',

              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'max-width': '1140px',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: '1-6-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  active: true,
                  style: {
                    'margin-bottom': '30px',
                  },
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-6-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'text-align': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        textAlign: 'center',
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠ INSTAGRAM' },
                    },
                  ],
                },
                {
                  id: '1-6-2',
                  name: 'Fotografije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'column-gap': '20px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-6-2-1',
                      name: 'Fotografija 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-1.jpg` },
                    },
                    {
                      id: '1-6-2-2',
                      name: 'Fotografija 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-2.jpg` },
                    },
                    {
                      id: '1-6-2-3',
                      name: 'Fotografija 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-3.jpg` },
                    },
                    {
                      id: '1-6-2-4',
                      name: 'Fotografija 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-4.jpg` },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '2',
          name: 'Prodavnica',
          active: true,
          homepageFallback: true,
          navigation: { hrefType: 'internal', href: '/shop' },
        },
      ],
      elements: [
        {
          id: 'e-1',
          type: 'header',
          style: { height: '80px', 'background-color': '#fff' },
          sections: [
            {
              id: 'e-1-1',
              type: 'section',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'padding-left': '32px',
                'padding-right': '32px',
                width: '100%',
                height: '100%',
                'grid-template-columns': '90px 1fr',
              },
              sections: [
                {
                  id: 'e-1-1-1',
                  type: 'image',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    height: '46px',
                    width: '46px',
                    'border-radius': '50%',
                    _wrapper: { 'justify-content': 'start' },
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: '/',
                  },
                  data: {
                    srcFn: '{return this.shopService.shop.value.logoUrl;}',
                  },
                },
                {
                  id: 'e-1-1-2',
                  type: 'nav',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {},
                },
                {
                  id: 'e-1-1-3',
                  type: 'connected-shops',
                  active:
                    '{return this.shopService.shop.value.connectedShops?.length > 0;}',
                  position: { column: 3, row: 1 },
                  style: {
                    width: '30px',
                    'font-size': '20px',
                  },
                },
                {
                  id: 'e-1-1-4',
                  type: 'search',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    width: '265px',
                    height: '42px',
                    'margin-right': '40px',
                    'justify-self': 'end',
                    'align-self': 'center',
                    border: 'none',
                    _icon: {
                      height: '30px',
                      width: '30px',
                      'font-size': '20px',
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                    },
                    _button: { height: '47px', border: 'none' },
                  },
                },
                {
                  id: 'e-1-1-5',
                  type: 'cart-toggler',
                  active: true,
                  position: { column: 5, row: 1 },
                  style: {
                    height: '42px',
                    width: '42px',
                    'font-size': '20px',
                  },
                },
              ],
            },
          ],
        },
        {
          id: 'e-2',
          type: 'footer',
          style: {
            'padding-top': '1.5rem',
            'margin-top': '1.5rem',
          },
          class: 'footer-color footer-text-color',
          sections: [
            {
              id: 'e-2-1',
              type: 'text',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '0.5rem',
              },
              class: 'p2',
              data: { text: 'Saznaj kada stignu novi proizvodi' },
            },
            {
              id: 'e-2-2',
              type: 'input',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                _main: {
                  'justify-self': 'center',
                  'background-color': 'white',
                  display: 'inline-block',
                  'margin-bottom': '1.5rem',
                },
                _input: {
                  border: 'none',
                  height: 'inherit',
                  padding: '0.5rem',
                  'max-width': 'calc(100vw - 150px)',
                },
                _button: {
                  width: '118px',
                  margin: '0.25rem',
                  _element: {},
                },
              },
              class: {
                _button: 'p2 button-color button-text-color button-hover-color',
                _input: 'p2 outline-none-focus_visible',
              },
              data: {
                placeholder: 'Unesi svoju email adresu',
                ctaLabel: 'Prijavi se',
                regexType: Regex.EMAIL_REGEX,
                regexMessage: 'Morate uneti e-mail adresu',
              },
              action: 'subscribe',
            },
            {
              id: 'e-2-3',
              type: 'text',
              active:
                '{ return `${this.shopService.shop.value.description !== null} && ${this.shopService.shop.value.description !== ""}`;}',
              position: { column: 1, row: 3 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '2rem',
                'text-align': 'center',
                'max-width': '70%',
              },
              class: 'h6',
              data: {
                textFn:
                  '{ return `${this.shopService.shop.value.description}`;}',
              },
            },
            {
              id: 'e-2-4',
              type: 'section',
              active: true,
              position: { column: 1, row: 4 },
              style: {
                display: 'flex',
                'justify-content': 'center',
                width: '160px',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-4-1',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.instagram !== null} && ${this.shopService.shop.value.instagram !== ""}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.instagram}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-instagram' },
                },
                {
                  id: 'e-2-4-2',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.facebook !== null} && ${this.shopService.shop.value.facebook !== ""}`;}',
                  position: { column: 2, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.facebook}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-facebook' },
                },
                {
                  id: 'e-2-4-3',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.youtube !== null} && ${this.shopService.shop.value.youtube !== ""}`;}',
                  position: { column: 3, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.youtube}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-youtube' },
                },
                {
                  id: 'e-2-4-4',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.twitter !== null} && ${this.shopService.shop.value.twitter !== ""}`;}',
                  position: { column: 4, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.twitter}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-twitter' },
                },
              ],
            },
            {
              id: 'e-2-5',
              type: 'section',
              active: true,
              position: { column: 1, row: 5 },
              style: {
                display: 'flex',
                'justify-content': 'space-evenly',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-5-1',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showEmail !== null} && ${this.shopService.shop.value.showEmail !== false}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="mailto:${this.shopService.shop.value.email}"><i class="fa fa-envelope me-2"></i>${this.shopService.shop.value.email}</a>`;}',
                  },
                },
                {
                  id: 'e-2-5-2',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showPhoneNumber !== null} && ${this.shopService.shop.value.showPhoneNumber !== false}`;}',
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="tel:${this.shopService.shop.value.phoneNumber}"><i class="fa fa-phone me-2"></i>${this.shopService.shop.value.phoneNumber}</a>`;}',
                  },
                },
              ],
            },
            {
              id: 'e-2-6',
              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': '15%',
                'margin-right': '15%',
                'text-align': 'center',
                'grid-template-column': 'repeat(4, 1fr)',
                'margin-bottom': '1.5rem',
              },
              sections: [
                {
                  id: 'e-2-6-1',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: 'reclamation',
                  },
                  data: { text: 'Prijavi reklamaciju' },
                },
                {
                  id: 'e-2-6-2',
                  type: 'text',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/uslovi_kupovine.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Uslovi prodaje' },
                },
                {
                  id: 'e-2-6-3',
                  type: 'text',
                  active: true,
                  position: { column: 3, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/povracaj_i_zamena.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Povraćaj i zamena' },
                },
                {
                  id: 'e-2-6-4',
                  type: 'text',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    'margin-bottom': '0',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/porucivanje_i_dostava.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Poručivanje i dostava' },
                },
              ],
            },
            {
              id: 'e-2-7',
              type: 'text',
              active: true,
              position: { column: 1, row: 7 },
              style: {
                'margin-bottom': '0.5rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              class: 'p3',
              data: {
                textFn:
                  '{ return `Copyright @ ${new Date().getFullYear()} Brendly`;}',
              },
            },
          ],
        },
      ],
    },
    '(min-width: 1450px)': {
      pages: [
        {
          id: '1',
          name: 'Početna',
          homepage: true,
          active: false,
          clickable: true,
          type: 'section',
          style: {
            'grid-template-columns': '100%',
            'grid-template-rows': 'auto',
            'max-width': '100vw',
          },
          navigation: { hrefType: 'internal', href: '/' },
          allowElementsSorting: true,
          sections: [
            {
              id: '1-1',
              name: 'Baner',
              type: 'section',
              active: true,

              canHaveDifferentSettingsOnMultipleScreens: true,
              position: { column: 1, row: 1 },
              background: {
                enabled: true,
                image: `${defaultThemeOnFileServerUrl}banner.jpg`,
                imageOptions: {
                  repeat: 'no-repeat',
                  size: 'cover',
                  position: 'center center',
                },
                mask: {
                  type: 'two-colors',
                  direction: ['to right'],
                  colorOne: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0.7,
                  },
                  colorTwo: {
                    type: 'style',
                    value: '#000000',
                    opacity: 0,
                  },
                },
              },
              style: {
                'background-image': `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url('${defaultThemeOnFileServerUrl}banner.jpg')`,
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-position': 'center center',
                height: '400px',
                'margin-bottom': '30px',
                'grid-template-rows': '1fr 1fr',
                _wrapper: {
                  'max-width': '1140px',
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                  height: '100%',
                },
              },
              sections: [
                {
                  id: '1-1-1',
                  name: 'Glavni tekst',
                  type: 'textarea',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'justify-self': 'start',
                    'align-self': 'end',
                    'word-break': 'break-word',
                    'letter-spacing': '2px',
                    'font-weight': 'bold',
                    color: '#ffffff',
                  },
                  class: 'h4',
                  textStyle: {
                    fontWeight: 'bold',
                    color: {
                      type: 'class-secondary-color',
                      value: 'secondary-color',
                    },
                  },
                  subtype: 'h1',
                  data: { text: 'PREDSTAVI SE' },
                },
                {
                  id: '1-1-2',
                  name: 'Dugme',
                  type: 'button',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-left': '20px',
                    'margin-right': '20px',
                    'max-width': '260px',
                    'min-height': '40px',
                    'justify-self': 'start',
                    'align-self': 'start',
                    top: '20px',
                    border: 'none',
                    'font-weight': 'bold',
                  },
                  textStyle: {
                    fontWeight: 'bold',
                  },
                  class:
                    'btn button-color button-text-color button-hover-color p2',
                  navigation: {
                    enabled: true,
                    editable: false,
                    hrefType: 'category',
                    href: 'Sve',
                  },
                  data: { text: 'POGLEDAJ PROIZVODE' },
                },
              ],
            },
            {
              id: '1-2',
              name: 'Kategorije',
              type: 'section',
              active: true,

              position: { column: 1, row: 2 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1140px',
                'margin-bottom': '10px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-2-1',
                  name: 'Naslov sekcije',

                  active: true,
                  style: {
                    'margin-bottom': '16px',
                  },
                  type: 'section',
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-2-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h4',
                      data: { text: 'IZABRANE KATEGORIJE' },
                    },
                  ],
                },

                {
                  id: '1-2-3',
                  name: '2 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-3-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-3-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-3-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-3-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-2',
                  name: '3 kategorije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': '400fr 304fr 392fr',
                    'grid-gap': '16px',
                  },
                  sections: [
                    {
                      id: '1-2-2-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '400fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '304fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          position: { column: 1, row: 1 },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-2-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      grid: {
                        column: '392fr',
                      },
                      sections: [
                        {
                          id: '1-2-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-4',
                  name: '4 kategorije',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '1fr 1fr',
                  },
                  sections: [
                    {
                      id: '1-2-4-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,
                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-4-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-4-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-4-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-2-5',
                  name: '6 kategorija',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '16px',
                    'grid-template-columns': '400fr 304fr 392fr',
                  },
                  sections: [
                    {
                      id: '1-2-5-1',
                      name: 'Kategorija 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-2',
                      name: 'Kategorija 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-3',
                      name: 'Kategorija 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-4',
                      name: 'Kategorija 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 600px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '400/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-1.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-5',
                      name: 'Kategorija 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 789px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '304/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-2.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                    {
                      id: '1-2-5-6',
                      name: 'Kategorija 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 2 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      sections: [
                        {
                          id: '1-2-5-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          resolution: '600px x 612px',
                          style: {
                            'margin-bottom': '10px',
                            'aspect-ratio': '392/400',
                          },
                          data: {
                            src: `${defaultThemeOnFileServerUrl}category-3.jpg`,
                          },
                        },
                        {
                          id: '1-2-5-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'text-align': 'center',
                            'letter-spacing': '2px',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Kategorija' },
                          subtype: 'h3',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: '1-3',
              name: 'O nama',
              type: 'section',
              active: true,
              position: { column: 1, row: 3 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1140px',
                'margin-bottom': '30px',
                'grid-template-columns': '720fr 392fr',
                'column-gap': '18px',
              },
              allowElementsSorting: true,
              sections: [
                {
                  id: '1-3-1',
                  name: 'Tekst',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  grid: {
                    column: '720fr',
                  },
                  style: {
                    'background-color': '#f8f8f8',
                    padding: '20px',
                    'margin-bottom': '30px',
                    height: '100%',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                  },
                  sections: [
                    {
                      id: '1-3-1-1',
                      name: 'Naslov',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'font-weight': 'bold',
                        'margin-bottom': '20px',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      class: 'h5',
                      data: { text: 'O nama' },
                    },
                    {
                      id: '1-3-1-2',
                      name: 'Opis',

                      type: 'textarea',
                      active: true,
                      position: { column: 1, row: 2 },
                      style: {
                        flex: '1',
                        display: 'flex',
                        'align-items': 'center',
                      },
                      class: 'p3',
                      data: {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                      },
                    },
                  ],
                },
                {
                  id: '1-3-2',
                  name: 'Fotografija',
                  nonToggleable: true,
                  type: 'section',
                  active: true,
                  style: {},
                  position: { column: 2, row: 1 },
                  grid: {
                    column: '392fr',
                  },
                  sections: [
                    {
                      id: '1-3-2-1',
                      name: 'Fotografija',
                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 612px',
                      style: {
                        'aspect-ratio': '392/400',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}about-us.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-4',
              name: 'Proizvodi',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 4 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1140px',
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-4-1',
                  name: 'Naslov sekcije',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  sections: [
                    {
                      id: '1-4-1-1',
                      name: 'Tekst',
                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'IZABRANI PROIZVODI' },
                    },
                  ],
                },

                {
                  id: '1-4-4',
                  name: '3 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-4-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-4-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-4-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-4-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-4-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-5',
                  name: '4 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-5-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-5-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-5-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-5-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-5-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-2',
                  name: '5 proizvoda',
                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-2-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-2-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-2-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-2-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-2-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-6',
                  name: '6 proizvoda',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-gap': '20px',
                    'margin-bottom': '30px',
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-4-6-1',
                      name: 'Proizvod 1',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-1-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-1.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-1-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-1-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-2',
                      name: 'Proizvod 2',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 2, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-2-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-2.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-2-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-2-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-3',
                      name: 'Proizvod 3',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 3, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-3-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-3.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-3-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-3-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-4',
                      name: 'Proizvod 4',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 4, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-4-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-4.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-4-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-4-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-5',
                      name: 'Proizvod 5',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 5, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-5-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-5-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-5-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                    {
                      id: '1-4-6-6',
                      name: 'Proizvod 6',
                      nonToggleable: true,

                      type: 'section',
                      active: true,
                      position: { column: 6, row: 1 },
                      style: {},
                      navigation: {
                        editable: true,
                        hrefType: 'product',
                      },
                      sections: [
                        {
                          id: '1-4-6-6-1',
                          name: 'Fotografija',

                          type: 'image',
                          active: true,
                          position: { column: 1, row: 1 },
                          style: {
                            'margin-bottom': '16px',
                            'aspect-ratio': '1/1',
                          },
                          resolution: '600px x 600px',
                          data: {
                            src: `${defaultThemeOnFileServerUrl}product-5.jpg`,
                          },
                        },
                        {
                          id: '1-4-6-6-2',
                          name: 'Naziv',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 2 },
                          style: {
                            'margin-bottom': '12px',
                            'font-weight': '600',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p2',
                          data: { text: 'Proizvod' },
                          subtype: 'h3',
                        },
                        {
                          id: '1-4-6-6-3',
                          name: 'Cena',

                          type: 'text',
                          active: true,
                          position: { column: 1, row: 3 },
                          style: {
                            'font-size': '10px',
                            'font-weight': '300',
                            'letter-spacing': '2px',
                            'text-align': 'center',
                          },
                          textStyle: {
                            textAlign: 'center',
                          },
                          class: 'p3',
                          data: { text: 'OD 1700 RSD' },
                        },
                      ],
                    },
                  ],
                },
                {
                  id: '1-4-3',
                  name: 'Pogledaj sve',
                  active: 'true',
                  type: 'section',
                  position: { column: 1, row: 6 },
                  style: {},
                  sections: [
                    {
                      id: '1-4-3-1',
                      name: 'Dugme',
                      type: 'button',
                      active: true,
                      position: { column: 1, row: 1 },
                      class:
                        'btn button-color button-text-color button-hover-color',
                      style: {
                        height: '50px',
                        width: '200px',
                        'justify-self': 'center',
                      },
                      textStyle: {},
                      navigation: {
                        enabled: true,
                        editable: false,
                        hrefType: 'category',
                        href: 'Sve',
                      },
                      data: { text: 'Pogledaj sve' },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-5',
              name: 'Kupci',

              type: 'section',
              active: 'true',
              position: { column: 1, row: 5 },
              style: {
                'margin-bottom': '30px',
              },
              sections: [
                {
                  id: '1-5-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  position: { column: 1, row: 1 },
                  style: { 'margin-bottom': '30px' },
                  active: true,
                  sections: [
                    {
                      id: '1-5-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'justify-self': 'center',
                        'letter-spacing': '2px',
                        'font-weight': 'bold',
                      },
                      textStyle: {
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠI KUPCI' },
                    },
                  ],
                },

                {
                  id: '1-5-3',
                  name: '3 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 3 },
                  style: {
                    'grid-template-columns': 'repeat(3, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-3-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-3-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-4',
                  name: '4 kupca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 4 },
                  style: {
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-4-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-4-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-5',
                  name: '5 kupaca',

                  type: 'section',
                  active: false,
                  position: { column: 1, row: 5 },
                  style: {
                    'grid-template-columns': 'repeat(5, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-5-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-5-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                  ],
                },
                {
                  id: '1-5-2',
                  name: '6 kupaca',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'grid-template-columns': 'repeat(6, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-5-2-1',
                      name: 'Kupac 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-1.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-2',
                      name: 'Kupac 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-2.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-3',
                      name: 'Kupac 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-3.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-4',
                      name: 'Kupac 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-4.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-5',
                      name: 'Kupac 5',

                      type: 'image',
                      active: true,
                      position: { column: 5, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-5.jpg`,
                      },
                    },
                    {
                      id: '1-5-2-6',
                      name: 'Kupac 6',

                      type: 'image',
                      active: true,
                      position: { column: 6, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: {
                        src: `${defaultThemeOnFileServerUrl}customer-6.jpg`,
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '1-6',
              name: 'Instagram',

              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1140px',
              },
              sections: [
                {
                  id: '1-6-1',
                  name: 'Naslov sekcije',

                  type: 'section',
                  active: true,
                  style: {
                    'margin-bottom': '30px',
                  },
                  position: { column: 1, row: 1 },
                  sections: [
                    {
                      id: '1-6-1-1',
                      name: 'Tekst',

                      type: 'text',
                      active: true,
                      position: { column: 1, row: 1 },
                      style: {
                        'text-align': 'center',
                        'font-weight': 'bold',
                        'letter-spacing': '2px',
                      },
                      textStyle: {
                        textAlign: 'center',
                        fontWeight: 'bold',
                      },
                      subtype: 'h2',
                      class: 'h5',
                      data: { text: 'NAŠ INSTAGRAM' },
                    },
                  ],
                },
                {
                  id: '1-6-2',
                  name: 'Fotografije',

                  type: 'section',
                  active: true,
                  position: { column: 1, row: 2 },
                  style: {
                    'column-gap': '20px',
                    'grid-template-columns': 'repeat(4, 1fr)',
                  },
                  sections: [
                    {
                      id: '1-6-2-1',
                      name: 'Fotografija 1',

                      type: 'image',
                      active: true,
                      position: { column: 1, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-1.jpg` },
                    },
                    {
                      id: '1-6-2-2',
                      name: 'Fotografija 2',

                      type: 'image',
                      active: true,
                      position: { column: 2, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-2.jpg` },
                    },
                    {
                      id: '1-6-2-3',
                      name: 'Fotografija 3',

                      type: 'image',
                      active: true,
                      position: { column: 3, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-3.jpg` },
                    },
                    {
                      id: '1-6-2-4',
                      name: 'Fotografija 4',

                      type: 'image',
                      active: true,
                      position: { column: 4, row: 1 },
                      resolution: '600px x 600px',
                      style: {
                        'aspect-ratio': '1/1',
                      },
                      navigation: {
                        editable: true,
                        hrefType: 'external',
                      },
                      data: { src: `${defaultThemeOnFileServerUrl}ig-4.jpg` },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: '2',
          name: 'Prodavnica',
          active: true,
          homepageFallback: true,
          navigation: { hrefType: 'internal', href: '/shop' },
        },
      ],
      elements: [
        {
          id: 'e-1',
          type: 'header',
          style: { height: '80px', 'background-color': '#fff' },
          sections: [
            {
              id: 'e-1-1',
              type: 'section',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'padding-left': '32px',
                'padding-right': '32px',
                'margin-left': 'auto',
                'margin-right': 'auto',
                'max-width': '1450px',
                height: '100%',
                'grid-template-columns': '90px 1fr',
              },
              sections: [
                {
                  id: 'e-1-1-1',
                  type: 'image',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    height: '46px',
                    width: '46px',
                    'border-radius': '50%',
                    _wrapper: { 'justify-content': 'start' },
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: '/',
                  },
                  data: {
                    srcFn: '{return this.shopService.shop.value.logoUrl;}',
                  },
                },
                {
                  id: 'e-1-1-2',
                  type: 'nav',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {},
                },
                {
                  id: 'e-1-1-3',
                  type: 'connected-shops',
                  active:
                    '{return this.shopService.shop.value.connectedShops?.length > 0;}',
                  position: { column: 3, row: 1 },
                  style: {
                    width: '30px',
                    'font-size': '20px',
                  },
                },
                {
                  id: 'e-1-1-4',
                  type: 'search',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    width: '265px',
                    height: '42px',
                    'margin-right': '40px',
                    'justify-self': 'end',
                    'align-self': 'center',
                    border: 'none',
                    _icon: {
                      height: '30px',
                      width: '30px',
                      'font-size': '20px',
                      display: 'flex',
                      'justify-content': 'center',
                      'align-items': 'center',
                    },
                    _button: { height: '47px', border: 'none' },
                  },
                },
                {
                  id: 'e-1-1-5',
                  type: 'cart-toggler',
                  active: true,
                  position: { column: 5, row: 1 },
                  style: {
                    height: '42px',
                    width: '42px',
                    'font-size': '20px',
                  },
                },
              ],
            },
          ],
        },
        {
          id: 'e-2',
          type: 'footer',
          style: {
            'padding-top': '1.5rem',
            'margin-top': '1.5rem',
          },
          class: 'footer-color footer-text-color',
          sections: [
            {
              id: 'e-2-1',
              type: 'text',
              active: true,
              position: { column: 1, row: 1 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '0.5rem',
              },
              class: 'p2',
              data: { text: 'Saznaj kada stignu novi proizvodi' },
            },
            {
              id: 'e-2-2',
              type: 'input',
              active: true,
              position: { column: 1, row: 2 },
              style: {
                _main: {
                  'justify-self': 'center',
                  'background-color': 'white',
                  display: 'inline-block',
                  'margin-bottom': '1.5rem',
                },
                _input: {
                  border: 'none',
                  height: 'inherit',
                  padding: '0.5rem',
                  'max-width': 'calc(100vw - 150px)',
                },
                _button: {
                  width: '118px',
                  margin: '0.25rem',
                  _element: {},
                },
              },
              class: {
                _button: 'p2 button-color button-text-color button-hover-color',
                _input: 'p2 outline-none-focus_visible',
              },
              data: {
                placeholder: 'Unesi svoju email adresu',
                ctaLabel: 'Prijavi se',
                regexType: Regex.EMAIL_REGEX,
                regexMessage: 'Morate uneti e-mail adresu',
              },
              action: 'subscribe',
            },
            {
              id: 'e-2-3',
              type: 'text',
              active:
                '{ return `${this.shopService.shop.value.description !== null} && ${this.shopService.shop.value.description !== ""}`;}',
              position: { column: 1, row: 3 },
              style: {
                'justify-self': 'center',
                'margin-bottom': '2rem',
                'text-align': 'center',
                'max-width': '70%',
              },
              class: 'h6',
              data: {
                textFn:
                  '{ return `${this.shopService.shop.value.description}`;}',
              },
            },
            {
              id: 'e-2-4',
              type: 'section',
              active: true,
              position: { column: 1, row: 4 },
              style: {
                display: 'flex',
                'justify-content': 'center',
                width: '160px',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-4-1',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.instagram !== null} && ${this.shopService.shop.value.instagram !== ""}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.instagram}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-instagram' },
                },
                {
                  id: 'e-2-4-2',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.facebook !== null} && ${this.shopService.shop.value.facebook !== ""}`;}',
                  position: { column: 2, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.facebook}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-facebook' },
                },
                {
                  id: 'e-2-4-3',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.youtube !== null} && ${this.shopService.shop.value.youtube !== ""}`;}',
                  position: { column: 3, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.youtube}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-youtube' },
                },
                {
                  id: 'e-2-4-4',
                  type: 'icon',
                  active:
                    '{ return `${this.shopService.shop.value.twitter !== null} && ${this.shopService.shop.value.twitter !== ""}`;}',
                  position: { column: 4, row: 1 },
                  style: {
                    'font-size': '15px',
                    'margin-left': '1rem',
                    'margin-right': '1rem',
                    cursor: 'pointer',
                  },
                  navigation: {
                    enabled: true,
                    hrefType: 'external',
                    hrefFn:
                      '{ return `${this.shopService.shop.value.twitter}`; }',
                    hrefTarget: '_blank',
                  },
                  data: { icon: 'fab fa-twitter' },
                },
              ],
            },
            {
              id: 'e-2-5',
              type: 'section',
              active: true,
              position: { column: 1, row: 5 },
              style: {
                display: 'flex',
                'justify-content': 'space-evenly',
                'margin-bottom': '2rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              sections: [
                {
                  id: 'e-2-5-1',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showEmail !== null} && ${this.shopService.shop.value.showEmail !== false}`;}',
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="mailto:${this.shopService.shop.value.email}"><i class="fa fa-envelope me-2"></i>${this.shopService.shop.value.email}</a>`;}',
                  },
                },
                {
                  id: 'e-2-5-2',
                  type: 'text',
                  active:
                    '{ return `${this.shopService.shop.value.showPhoneNumber !== null} && ${this.shopService.shop.value.showPhoneNumber !== false}`;}',
                  position: { column: 1, row: 2 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2',
                  data: {
                    textFn:
                      '{ return `<a href="tel:${this.shopService.shop.value.phoneNumber}"><i class="fa fa-phone me-2"></i>${this.shopService.shop.value.phoneNumber}</a>`;}',
                  },
                },
              ],
            },
            {
              id: 'e-2-6',
              type: 'section',
              active: true,
              position: { column: 1, row: 6 },
              style: {
                'margin-left': '15%',
                'margin-right': '15%',
                'text-align': 'center',
                'grid-template-column': 'repeat(4, 1fr)',
                'margin-bottom': '1.5rem',
              },
              sections: [
                {
                  id: 'e-2-6-1',
                  type: 'text',
                  active: true,
                  position: { column: 1, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    hrefType: 'internal',
                    href: 'reclamation',
                  },
                  data: { text: 'Prijavi reklamaciju' },
                },
                {
                  id: 'e-2-6-2',
                  type: 'text',
                  active: true,
                  position: { column: 2, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/uslovi_kupovine.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Uslovi prodaje' },
                },
                {
                  id: 'e-2-6-3',
                  type: 'text',
                  active: true,
                  position: { column: 3, row: 1 },
                  style: {
                    'margin-bottom': '0.5rem',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/povracaj_i_zamena.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Povraćaj i zamena' },
                },
                {
                  id: 'e-2-6-4',
                  type: 'text',
                  active: true,
                  position: { column: 4, row: 1 },
                  style: {
                    'margin-bottom': '0',
                    'justify-self': 'center',
                  },
                  class: 'p2 underline-hover',
                  navigation: {
                    enabled: true,
                    href: 'https://brendly-prod.s3.eu-central-1.amazonaws.com/docs/porucivanje_i_dostava.pdf',
                    hrefTarget: '_blank',
                    hrefType: 'external',
                  },
                  data: { text: 'Poručivanje i dostava' },
                },
              ],
            },
            {
              id: 'e-2-7',
              type: 'text',
              active: true,
              position: { column: 1, row: 7 },
              style: {
                'margin-bottom': '0.5rem',
                'margin-left': 'auto',
                'margin-right': 'auto',
              },
              class: 'p3',
              data: {
                textFn:
                  '{ return `Copyright @ ${new Date().getFullYear()} Brendly`;}',
              },
            },
          ],
        },
      ],
    },
  },
};

// export const defaultSite: any = {
//   style: {

//   }
// }
