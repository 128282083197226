import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CompressedImagePipe } from './_pipes/compressed-image/compressed-image.pipe';
import { SizePipe } from './_pipes/size/size.pipe';
import { DuloImageEditorModule } from './dulo-image-editor/dulo-image-editor.module';
import { ProductsChooserComponent } from './products-chooser/products-chooser.component';

import { KaduButtonModule } from '@kadung/kadung/kadu-button';
import { KaduCheckboxModule } from '@kadung/kadung/kadu-checkbox';
import { KaduPaginatorModule } from '@kadung/kadung/kadu-paginator';
import { DataLoadComponent } from './data-load/data-load.component';
import { KaduSkeletonModule } from '@kadung/kadung/kadu-skeleton';
import { KaduTooltipModule } from '@kadung/kadung/kadu-tooltip';
import { RecommendationPopupComponent } from './recommendation-popup/recommendation-popup.component';
import { JoinPipe } from './_pipes/join/join.pipe';
import { EmptyObjectPipe } from './_pipes/empty-object/empty-object.pipe';
import { SplitPipe } from './_pipes/split/split.pipe';
import { FormatDecimalNumberPipe } from './_pipes/format-decimal-number/format-decimal-number.pipe';

@NgModule({
  declarations: [
    SizePipe,
    ProductsChooserComponent,
    CompressedImagePipe,
    DataLoadComponent,
    RecommendationPopupComponent,
    JoinPipe,
    EmptyObjectPipe,
    SplitPipe,
    FormatDecimalNumberPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DuloImageEditorModule,
    KaduTooltipModule,
    KaduCheckboxModule,
    KaduPaginatorModule,
    KaduButtonModule,
    TranslocoModule,
    KaduSkeletonModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DuloImageEditorModule,
    DynamicDialogModule,
    SizePipe,
    ProductsChooserComponent,
    CompressedImagePipe,
    DataLoadComponent,
    JoinPipe,
    EmptyObjectPipe,
    SplitPipe,
    FormatDecimalNumberPipe,
  ],
  providers: [CompressedImagePipe],
})
export class SharedModule {}
