import { Injectable } from '@angular/core';
import { AuthService } from '../_services/auth/auth.service';
import {
  Router,
  ActivatedRouteSnapshot,
  UrlTree,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlreadyLoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['dashboard']);
      return false;
    }

    return true;
  }
}
