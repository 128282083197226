<main class="d-flex flex-column gap-3">
  <header class="fw-600">
    {{ title }}
  </header>
  <kadu-checkbox
    class="d-flex align-items-center gap-1 ms-2 p3"
    (changeHandler)="addToLocalStorage($event.checked)"
  >
    {{ "recommendationPopup.dontShowAgain" | transloco }}
  </kadu-checkbox>
  <kadu-button (clickHandler)="close()" elClass="btn btn-submit btn-small w-25">
    {{ "common.close" | transloco }}
  </kadu-button>
</main>
