<ng-container *transloco="let t">
  <div class="w-100">
    <label class="p3 text-start w-100 mb-2">{{ t("common.products") }}</label>
    <ng-content select="[filters]"></ng-content>

    <div class="d-flex gap-2">
      <span class="p3 text-start">
        {{ t("dashboard.productsChooser.numberOfSelectedProducts") }}:
        {{ selectedProducts.size }}
      </span>
      <span class="p3"
        >{{ t("dashboard.productsChooser.totalAmountOfProducts") }}:
        {{ productsTotalCount }}</span
      >
    </div>

    <div class="w-100 mt-3" *ngIf="productsTotalCount !== 0">
      <p class="mt-2 mb-1 p3 text-start">
        {{ t("dashboard.productsChooser.chooseProducts") }}
      </p>
      <div class="d-flex gap-2 align-items-center">
        <ng-container>
          <div class="d-flex align-items-baseline gap-1">
            <kadu-checkbox
              inputId="allSelected"
              (changeHandler)="selectAllOnPage($event.checked)"
              [Checked]="allSelectedOnPage$ | async"
            >
              <span class="p3">{{
                t("dashboard.productsChooser.chooseAllProducts")
              }}</span></kadu-checkbox
            >
          </div>
        </ng-container>

        <span
          *ngIf="
            (allSelectedOnPage$ | async) &&
            productsTotalCount !== selectedProducts.size
          "
          class="btn btn-xs btn-submit p2"
          (click)="selectAll()"
        >
          {{
            t("dashboard.productsChooser.chooseNumberProducts", {
              productsTotalCount: productsTotalCount
            })
          }}
        </span>
        <span
          class="p2"
          *ngIf="
            (allSelectedOnPage$ | async) &&
            productsTotalCount === selectedProducts.size
          "
        >
          {{ t("dashboard.productsChooser.allProductsAreChoosen") }}
        </span>
      </div>
    </div>
  </div>

  <data-load
    [loadingTemplate]="loadingTemplate"
    [template]="template"
    [request]="productsRequest$ | async"
  >
    <ng-template #loadingTemplate>
      <div class="products d-flex gap-2 flex-wrap mt-2">
        <div *ngFor="let _ of [].constructor(5)">
          <kadu-skeleton height="92px" width="200px"></kadu-skeleton>
        </div>
      </div>
    </ng-template>
    <ng-template #template>
      <div
        *ngIf="productsSource$ | async as products"
        class="products d-flex gap-1 flex-wrap mt-1"
      >
        <ng-container *ngIf="products.length === 0">
          <h6 class="mb-0 p-0 mt-3 p3">
            {{ t("common.noAvailableProducts") }}
          </h6>
        </ng-container>
        <div
          *ngFor="let product of products; trackBy: trackProducts"
          [ngClass]="{ border: product.selected }"
          class="product p-2 rounded"
        >
          <div
            class="d-flex gap-2"
            (click)="selectProduct(product, !product.selected)"
          >
            <ng-container *ngFor="let colorImage of product.product_images">
              <div [hidden]="!colorImage.selected">
                <img
                  [src]="colorImage.product_front_image_url | compressedImage"
                  [alt]="product.name"
                />
              </div>
            </ng-container>
            <div
              class="product-info w-100 d-flex flex-column align-items-start justify-content-evenly"
            >
              <p
                class="m-0 p3"
                #productName
                [kaduTooltip]="product.name"
                [tooltipDisabled]="!isElipsisActive(productName)"
              >
                {{ product.name }}
              </p>
              <p
                class="m-0 p4"
                #productType
                [kaduTooltip]="product.product_type_name"
                [tooltipDisabled]="!isElipsisActive(productType)"
              >
                {{ product.product_type_name }}
              </p>
              <div
                class="w-100 d-flex align-items-baseline justify-content-between"
              >
                <p
                  *ngIf="currentCountry === 'Srbija'"
                  class="m-0 p4"
                  #productPrice
                  [kaduTooltip]="product.price"
                  [tooltipDisabled]="!isElipsisActive(productPrice)"
                >
                  {{ product.price }} RSD
                </p>

                <p
                  *ngIf="currentCountry === 'Hrvatska'"
                  class="m-0 p4 text-start"
                  #productPrice
                  [kaduTooltip]="product.price"
                  [tooltipDisabled]="!isElipsisActive(productPrice)"
                >
                  {{ product.price.toFixed(2) }} EUR / <br />
                  {{ (product.price * 7.5345).toFixed(2) }} KN
                </p>

                <kadu-checkbox inputId="selected" [Checked]="product.selected">
                </kadu-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <kadu-paginator
            *ngIf="productsPageInfo?.totalResults > currentFilters$.value.rows"
            [rows]="currentFilters$.value.rows"
            [totalRecords]="productsPageInfo?.totalResults"
            [first]="first$ | async"
            (changeHandler)="changePage($event)"
          ></kadu-paginator>
        </div>
      </div>
    </ng-template>
  </data-load>

  <div
    class="d-flex py-0 py-md-2 gap-2 justify-content-between align-items-center flex-column flex-sm-row"
  >
    <ng-container *ngIf="!action">
      <ng-content select="[button]"></ng-content>
    </ng-container>

    <ng-container *ngIf="action || mode === 'product'">
      <ng-content select="[buttons]"></ng-content>
    </ng-container>
  </div>
  <div class="d-flex gap-1 flex-wrap mt-2">
    <span *ngFor="let product of selectedProducts | keyvalue" class="mr-2 my-1">
      <div style="background-color: #f5f6f8" class="rounded p-1 p4">
        {{ product.value.name }} ({{ product.value.product_type_name }})
        <span
          class="float-right cursor-pointer"
          (click)="selectProduct(product.value, false)"
        >
          <i class="bi bi-x"></i>
        </span>
      </div>
    </span>
  </div>
</ng-container>
