import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Notification } from 'src/app/_models/notification';
import { Verification } from 'src/app/_models/verification';
import { ShopService } from 'src/app/dashboard/_services/shop/shop.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { NotificationService } from '../notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  verificationData$ = new BehaviorSubject<Verification>(null);
  userNotVerified;
  subs = new SubSink();

  constructor(
    private http: HttpClient,
    private shopService: ShopService,
    private notificationService: NotificationService,
    private translateService: TranslocoService,
    private authService: AuthService,
  ) {
    this.translateService
      .selectTranslate('notifications.userNotVerified.cantTakeOrders')
      .subscribe(() => {
        this.userNotVerified = {
          position: 'top',
          html: `<p class="p-1 text-white m-0">${this.translateService.translate(
            'notifications.userNotVerified.cantTakeOrders',
          )} <span name="userNotVerified" href="dashboard/default" class="fw-600 text-white cursor-pointer">${this.translateService.translate(
            'dashboard.default.title',
          )}</span> ${this.translateService.translate(
            'notifications.userNotVerified.page',
          )}</p>`,
          name: 'userNotVerified',
        };
        if (
          !this.verificationData$.value?.verified &&
          this.authService.isUserLoggedIn() &&
          this.shopService.shops$.getValue()?.length > 0
        ) {
          this.notificationService.addNotification(this.userNotVerified);
        } else {
          this.notificationService.removeNotification(this.userNotVerified);
        }
      });
  }

  getVerificationData(): Observable<any> {
    if (this.shopService.shops$.getValue().length > 0) {
      return this.http
        .get(
          `${environment.apiUrl}e_commerces/${
            this.shopService.selectedShop$.getValue().id
          }/verification_data`,
        )
        .pipe(
          tap((verification: Verification) => {
            this.verificationData$.next(verification);

            if (!verification.verified) {
              this.notificationService.addNotification(this.userNotVerified);
            } else {
              this.notificationService.removeNotification(this.userNotVerified);
            }
          }),
        );
    } else {
      this.notificationService.removeNotification(this.userNotVerified);
      return of();
    }
  }
}
