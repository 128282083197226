<ng-container *transloco="let t">
  <app-header *ngIf="!(builderActive$ | async)" #header></app-header>

  <div
    class="flex-fill"
    [class.overflow-auto]="!(authToken$ | async)"
    [class.logged-in]="authToken$ | async"
  >
    <router-outlet></router-outlet>
  </div>

  <kadu-loader [isLoading$]="isLoading$"></kadu-loader>
  <span class="canvas-image-generator-wrapper">
    <canvas id="canvas-image-generator"> </canvas>
  </span>
</ng-container>

<kadu-confirm-dialog #popupComponent></kadu-confirm-dialog>
