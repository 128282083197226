import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KaduFormRequest } from '@kadung/kadung/kadu-form';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { emailREG, passwordsMatchValidator } from 'src/app/shared/util';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent extends KaduFormRequest implements OnInit {
  instructionsSent = new BehaviorSubject<boolean>(false);
  passwordReset = new BehaviorSubject<boolean>(false);
  resetEmail$ = new BehaviorSubject<string>(null);

  resetPasswordControls = {
    password: {
      state: '',
      validators: [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
        ),
      ],
    },
    passwordAgain: { state: '', validators: [Validators.required] },
  };

  resetPasswordValidators = {
    validators: [passwordsMatchValidator],
  };

  emailFormControls = {
    email: {
      state: '',
      validators: [Validators.required, Validators.pattern(emailREG)],
    },
  };

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.passwordReset.next(
      this.route.snapshot.queryParams.id &&
        this.route.snapshot.queryParams.token,
    );
  }

  resetPassword(event: any): void {
    this.getRequest({ password: event.password }, 'reset').subscribe(
      () => this.router.navigate(['/login']),
      (error) => this.handleError(error),
    );
  }

  emailInstructions(email: string, mode: string): void {
    if (mode === 'send') {
      this.resetEmail$.next(email);
    }

    this.getRequest({ email }, 'forgot').subscribe(
      () => this.instructionsSent.next(true),
      (error) => {
        if (error.status === 404) {
          this.formComponent.formGroup
            .get('email')
            .setErrors({ emailNotFound: true });
        }
      },
    );
  }

  protected _getRequest(
    params: any,
    mode: 'forgot' | 'reset',
  ): Observable<any> {
    if (mode === 'forgot') {
      return this.authService.forgotPassword(
        params['email'] || this.resetEmail$.value,
      );
    }
    return this.authService.resetPassword({
      id: this.route.snapshot.queryParams.id,
      token: this.route.snapshot.queryParams.token,
      new_password: params['password'],
    });
  }

  handleError(error: { error: { exceptionType: string } }): void {
    switch (error.error.exceptionType) {
      case 'RESET_PASSWORD_INVALID_PASSWORD': {
        this.formComponent.formGroup
          .get('passwordAgain')
          .setErrors({ invalidPassword: true });
        break;
      }

      case 'RESET_PASSWORD_INVALID_TOKEN': {
        this.formComponent.formGroup.setErrors({ invalidToken: true });
        break;
      }
    }
  }
}
