import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appCheckCache]',
})
export class CheckCacheDirective implements AfterViewInit {
  @Input('appCheckCache') productId: number;

  constructor(private elRef: ElementRef, private sanitizer: DomSanitizer) {}

  async ngAfterViewInit(): Promise<any> {
    const url = this.elRef.nativeElement.src.split('?')[0];
    const cache = await caches.open(this.productId + '');
    const response = await cache.match(url, { ignoreSearch: true });
    if (response) {
      const blob = await response.blob();
      this.elRef.nativeElement.src = URL.createObjectURL(blob);
    }
  }

  async checkCache(image_url: string, productId: number): Promise<any> {
    const url = image_url.split('?')[0];
    const cache = await caches.open(productId + '');
    const response = await cache.match(url, { ignoreSearch: true });
    if (response) {
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
    return image_url;
  }
}
