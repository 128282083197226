import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-recommendation-popup',
  templateUrl: './recommendation-popup.component.html',
  styleUrls: ['./recommendation-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationPopupComponent {
  localStorageKey: 'editorFlag' | 'themesFlag' | 'myBrendlyFlag' =
    this.config.data.localStorageKey;
  title = this.config.data.title;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {}

  addToLocalStorage(checked: boolean): void {
    const flags = localStorage.getItem('popupFlags')
      ? JSON.parse(localStorage.getItem('popupFlags'))
      : {};

    localStorage.setItem(
      'popupFlags',
      JSON.stringify({
        ...flags,
        [this.localStorageKey]: checked,
      }),
    );
  }

  close(): void {
    this.ref.close();
  }
}
