import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject, Observable, noop } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'data-load',
  templateUrl: './data-load.component.html',
  styleUrls: ['./data-load.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLoadComponent implements OnDestroy {
  loading$ = new BehaviorSubject<boolean>(false);
  @Input() loadingTemplate!: TemplateRef<any>;
  @Input() template!: TemplateRef<any>;
  @Input() request!: Observable<any>;
  values!: any;
  subs = new SubSink();

  setLoading(value: boolean): void {
    this.loading$.next(value);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    const loader = setTimeout(() => {
      this.setLoading(true);
    }, 150);

    this.subs.add(
      simpleChanges['request'].currentValue.subscribe(
        (values) => {
          this.values = values;
        },
        noop,
        () => {
          clearTimeout(loader);
          this.setLoading(false);
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
