import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoginResponse } from 'src/app/_models/loginResponse';
import { UserService } from 'src/app/dashboard/_services/user/user.service';
import { environment } from 'src/environments/environment';
import {
  SKIP_ERROR_HANDLING_INTERCEPTORS,
  SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
  SKIP_JWT_RENEW_INTERCEPTOR,
} from '../../_interceptors/skip-interceptors';
import { Token } from '../../_models/token';
import { UserLogin } from '../../_models/userLogin';
import { AppService } from '../app/app.service';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private jwtHelper: JwtHelperService = new JwtHelperService();
  authToken$ = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private appService: AppService,
    private router: Router,
  ) {
    if (this.getAccessToken()) {
      this.authToken$.next(this.getAccessToken());
    }
  }

  logIn(authData: UserLogin): Observable<any> {
    const userService = this.injector.get(UserService);
    return this.http
      .post(`${environment.apiUrl}auth/login`, authData, {
        headers: new HttpHeaders()
          .set(
            SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
            SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
          )
          .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR)
          .set(
            SKIP_ERROR_HANDLING_INTERCEPTORS,
            SKIP_ERROR_HANDLING_INTERCEPTORS,
          )
          .set(SKIP_LOADING_INTERCEPTOR, SKIP_LOADING_INTERCEPTOR),
      })
      .pipe(
        tap((loginResponse: LoginResponse) => {
          this.saveAccessTokenToLocalStorage(loginResponse.accessToken);
          this.saveRefreshTokenToLocalStorage(loginResponse.refreshToken);
        }),
        switchMap((loginResponse) => {
          return userService.getDefaultPreferences().pipe(
            tap((res) => {
              this.appService.country = this.appService.findCountryByName(
                res.country,
              );
              this.appService.language = this.appService.findLanguageByCode(
                res.language,
              );
            }),
          );
        }),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  saveAccessTokenToLocalStorage(token: string): void {
    if (token) {
      localStorage.setItem('access_token', token);
      this.authToken$.next(token);
    }
  }

  saveRefreshTokenToLocalStorage(token: string): void {
    if (token) localStorage.setItem('refresh_token', token);
  }

  getUserId(): string {
    return this.jwtHelper.decodeToken(this.getAccessToken()).user_id;
  }

  getUserRoles(): string[] {
    return this.jwtHelper.decodeToken(this.getAccessToken()).roles;
  }

  getUserCountry(): string {
    return this.jwtHelper.decodeToken(this.getAccessToken()).country;
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  isUserLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.getAccessToken());
  }

  renewRefreshToken(token: Token): Observable<Token> {
    return this.http.put<Token>(
      `${environment.apiUrl}auth/refresh_token`,
      token,
      {
        headers: new HttpHeaders()
          .set('refresh', 'refresh')
          .set(
            SKIP_ERROR_HANDLING_INTERCEPTORS,
            SKIP_ERROR_HANDLING_INTERCEPTORS,
          ),
      },
    );
  }

  logout(): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}auth/invalidate_refresh_token`,
      {
        accessToken: this.getAccessToken(),
        userId: this.getUserId(),
        refreshToken: this.getRefreshToken(),
      },
      {
        headers: new HttpHeaders().set(
          SKIP_ERROR_HANDLING_INTERCEPTORS,
          SKIP_ERROR_HANDLING_INTERCEPTORS,
        ),
      },
    );
  }

  forgotPassword(email: string): Observable<any> {
    const url = `${environment.apiUrl}auth/user/forgot_password/${email}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders()
          .set(
            SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
            SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
          )
          .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR)
          .set(SKIP_LOADING_INTERCEPTOR, SKIP_LOADING_INTERCEPTOR)
          .set(
            SKIP_ERROR_HANDLING_INTERCEPTORS,
            SKIP_ERROR_HANDLING_INTERCEPTORS,
          ),
      })
      .pipe(catchError((err) => throwError(err)));
  }

  resetPassword(resetPassword: {
    id: string;
    token: string;
    new_password: string;
  }): Observable<any> {
    const url = `${environment.apiUrl}auth/user/${resetPassword.id}/reset_password`;
    return this.http
      .put(
        url,
        { new_password: resetPassword.new_password },
        {
          params: new HttpParams().append('token', resetPassword.token),
          headers: new HttpHeaders()
            .set(
              SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
              SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
            )
            .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR)
            .set(SKIP_LOADING_INTERCEPTOR, SKIP_LOADING_INTERCEPTOR),
        },
      )
      .pipe(catchError((err) => throwError(err)));
  }

  changePassword(password: string): Observable<any> {
    const url = `${
      environment.apiUrl
    }auth/user/${this.getUserId()}/change_password`;
    return this.http.put(url, { password: password });
  }
}
