import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'size'
})
export class SizePipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    if(value < 1024) return value + 'B';
    if(value >= 1024 && value < 1024 * 1024) return Math.round(value * 100 / 1024) / 100 + 'KB';
    if(value >= 1024 * 1024 && value < 1024 * 1024 * 1024) return Math.round(value * 100 / (1024 * 1024)) / 100 + 'MB';
    return Math.round(value * 100 / (1024 * 1024 * 1024)) / 100 + 'GB';
  }

}
