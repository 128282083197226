import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(
    value: any[],
    sep = ',',
    property = '',
    isValueObject = true,
  ): string {
    if (isValueObject) {
      value = value.map((item) => item[property]);
    }
    return value.join(sep);
  }
}
