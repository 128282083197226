import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KaduFormComponent } from '@kadung/kadung/kadu-form/kadu-form.component';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from 'primeng/dynamicdialog';
import { Country } from 'src/app/_models/country';
import { LoginResponse } from 'src/app/_models/loginResponse';
import { UserLogin } from 'src/app/_models/userLogin';
import { UserSignup } from 'src/app/_models/userSignup';
import { AppService } from 'src/app/_services/app/app.service';
import { PopupService } from 'src/app/_services/popup/popup.service';
import { UserService } from 'src/app/dashboard/_services/user/user.service';
import { emailREG, passwordsMatchValidator } from 'src/app/shared/util';
import { SubSink } from 'subsink';
import { AuthService } from '../../../_services/auth/auth.service';
import { KaduFormRequest } from '@kadung/kadung/kadu-form';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class SignupComponent
  extends KaduFormRequest
  implements OnInit, OnDestroy
{
  countries = this.appService.countries.filter((c) => c.signupAvailable);
  selectedCountry = this.appService.country$.value;
  formControls = {
    email: {
      state: '',
      validators: [Validators.required, Validators.pattern(emailREG)],
    },
    password: {
      state: '',
      validators: [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
        ),
      ],
    },
    passwordAgain: { state: '', validators: [Validators.required] },
    firstName: { state: '', validators: [Validators.required] },
    lastName: { state: '', validators: [Validators.required] },
    phoneNumber: { state: '', validators: [Validators.required] },
    termsAndConditions: { state: false, validators: [Validators.requiredTrue] },
    defaultCountry: { state: this.selectedCountry },
    defaultLanguage: { state: this.selectedCountry.alpha2Code },
  };

  options = {
    validators: [passwordsMatchValidator],
  };

  subs = new SubSink();
  constructor(
    private authService: AuthService,
    private router: Router,
    public dialogService: DialogService,
    public translateService: TranslocoService,
    private userService: UserService,
    private appService: AppService,
    private popupService: PopupService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['dashboard']);
    }
  }

  selectCountry(country: Country): void {
    this.appService.country = country;

    this.formComponent.formGroup
      .get('defaultLanguage')
      .setValue(country.alpha2Code);
  }

  onSignUp(event: any): void {
    const userForRegistration = new UserSignup({
      email: event.email,
      password: event.password,
      firstName: event.firstName,
      lastName: event.lastName,
      phoneNumber: event.phoneNumber,
      defaultCountry: event.defaultCountry.name,
      defaultLanguage: event.defaultLanguage,
      country: event.defaultCountry.name,
    });
    this.getRequest(userForRegistration).subscribe(
      () => {
        this.popupService
          .fire({
            titleText: this.translateService.translate('auth.signup.signedUp'),
            type: 'success',
            confirmButtonColor: '#f3364c',
          })
          .then(() => {
            const user = new UserLogin({
              email: userForRegistration.email,
              password: userForRegistration.password,
            });
            this.authService
              .logIn(user)
              .subscribe((loginResponse: LoginResponse) => {
                this.authService.saveAccessTokenToLocalStorage(
                  loginResponse.accessToken,
                );
                this.authService.saveRefreshTokenToLocalStorage(
                  loginResponse.refreshToken,
                );
                this.router.navigate(['dashboard']);
              });
          });
      },
      (error) => {
        this.handleError(error);
      },
    );
  }

  protected _getRequest(userForRegistration: UserSignup): Observable<any> {
    return this.userService.signUp(userForRegistration);
  }

  handleError(error: { error: { exceptionType: string } }): void {
    switch (error.error.exceptionType) {
      case 'EMAIL_EXISTS': {
        this.formComponent.formGroup
          .get('email')
          .setErrors({ emailExists: true });

        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
