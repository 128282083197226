import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SKIP_INTERCEPTORS,
  SKIP_TIMEZONE_INTERCEPTOR,
} from './skip-interceptors';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimezoneInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }
    if (
      req.headers.get(SKIP_TIMEZONE_INTERCEPTOR) === SKIP_TIMEZONE_INTERCEPTOR
    ) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        headers: req.headers.append('Timezone', moment.tz.guess()),
      }),
    );
  }
}
