import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip } from 'rxjs/operators';
import { BootstrapBreakpoints } from 'src/app/shared/util';
import { SubSink } from 'subsink';
import { ThemeService } from '../../_services/theme/theme.service';
import { UpdateElementMode } from '../_models/enums';
import {
  NonSectionElement,
  SectionElement,
  ThemeElement,
} from '../_models/section';
import { defaultSite } from '../default-site';

@Injectable({
  providedIn: 'root',
})
export class ThemeBuilderService implements OnDestroy {
  site = defaultSite;

  relatedScreens = [
    {
      value: BootstrapBreakpoints.Large,
      screens: [
        BootstrapBreakpoints.ExtraLarge,
        BootstrapBreakpoints.ExtraExtraLarge,
      ],
    },
  ];

  screens = [
    {
      label: 'Telefon',
      value: BootstrapBreakpoints.ExtraSmall,
      icon: 'fa fa-mobile-alt',
      maxWidth: '360px',
    },
    {
      label: 'Tablet',
      value: BootstrapBreakpoints.Medium,
      icon: 'fa fa-tablet-alt',
      maxWidth: '700px',
    },
    {
      label: 'Monitor',
      value: BootstrapBreakpoints.Large,
      icon: 'fa fa-desktop',
      maxWidth: 'calc(100% - 40px)',
    },
  ];

  currentScreen$: BehaviorSubject<any>;
  currentPageScreen$: BehaviorSubject<SectionElement>;
  globalStyle$: BehaviorSubject<any>;

  currentScreenPages$;

  elementChanged$: BehaviorSubject<ThemeElement>;
  styleChanged$: Subject<ThemeElement>;
  hoveredElement$: Subject<ThemeElement>;
  positionChanged$: Subject<ThemeElement>;
  scrollTo$: Subject<HTMLElement>;
  selectedElement$: BehaviorSubject<ThemeElement>;
  selectedDistinctElement$: Observable<ThemeElement>;
  inDraft$: BehaviorSubject<boolean>;
  builderActive$ = new BehaviorSubject<boolean>(false);
  parent$: BehaviorSubject<SectionElement>;
  elements$: Observable<ThemeElement[]>;
  previousParents$: BehaviorSubject<SectionElement[]>;

  subs = new SubSink();
  globalStyleSubscription: Subscription;
  themeId: string;
  globalStyleSetInSetter = false;

  updateDraftDefinitionSubscription: Subscription;

  set Site(value) {
    this.site = value;
    this.globalStyleSetInSetter = true;
    this.updateGlobalStyle(this.site.style);
  }

  constructor(private themeService: ThemeService) {}

  initGlobalStyle(): void {
    this.globalStyle$ = new BehaviorSubject<any>(null);

    if (!this.globalStyleSubscription) {
      this.globalStyleSubscription = this.globalStyle$
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((style) => {
          if (style === null) return;
          if (!this.globalStyleSetInSetter) {
            this.updateDraftDefinition();
          } else {
            this.globalStyleSetInSetter = false;
          }
        });
    }
  }

  init(themeInDraft): void {
    this.currentScreen$ = new BehaviorSubject<any>(
      sessionStorage.getItem('currentScreen')
        ? this.screens.find(
            (s) =>
              s.value ===
              JSON.parse(sessionStorage.getItem('currentScreen')).value,
          )
        : this.screens[0],
    );
    this.currentPageScreen$ = new BehaviorSubject<any>(null);

    this.currentScreenPages$ = this.currentScreen$.pipe(
      map((currentScreen) => this.site.screens[currentScreen.value].pages),
    );

    this.elementChanged$ = new BehaviorSubject<ThemeElement>(null);
    this.hoveredElement$ = new Subject<ThemeElement>();
    this.positionChanged$ = new Subject<ThemeElement>();
    this.styleChanged$ = new Subject<ThemeElement>();
    this.scrollTo$ = new Subject<HTMLElement>();
    this.selectedElement$ = new BehaviorSubject<ThemeElement>(null);
    this.selectedDistinctElement$ = this.selectedElement$.pipe(
      distinctUntilChanged(),
    );
    this.inDraft$ = new BehaviorSubject<boolean>(themeInDraft);
    this.parent$ = new BehaviorSubject<SectionElement>(null);
    this.elements$ = this.parent$.pipe(map((parent) => parent.sections));
    this.previousParents$ = new BehaviorSubject<SectionElement[]>([]);

    this.selectScreen(this.currentScreen$.value);

    this.subs.add(
      this.elementChanged$
        .pipe(skip(1), debounceTime(300))
        .subscribe((value) => {
          this.updateDraftDefinition();
        }),
    );
  }

  // this works only on homepage
  selectScreen(screen, selectRootElement = false): void {
    this.currentScreen$.next(screen);
    sessionStorage.setItem('currentScreen', JSON.stringify(screen));

    const newScreenPageRoot = this.site.screens[
      this.currentScreen$.value.value
    ].pages.filter((p) => p.homepage)[0];
    this.currentPageScreen$.next(newScreenPageRoot);
    if (this.selectedElement$.value && !selectRootElement) {
      const selectedElementFromNewScreen = this.findElementById(
        this.selectedElement$.value,
        newScreenPageRoot,
      );
      const chain = this.getChainToTheTop(selectedElementFromNewScreen);
      if (selectedElementFromNewScreen.type === 'section') {
        this.parent$.next(selectedElementFromNewScreen);
        this.previousParents$.next(chain);
        this.selectElement(selectedElementFromNewScreen, true);
      } else {
        const parent = chain.pop();
        this.parent$.next(parent);
        this.previousParents$.next(chain);
        this.selectElement(selectedElementFromNewScreen, true);
      }
    } else {
      this.parent$.next(this.currentPageScreen$.value);
      this.previousParents$.next([]);
      this.selectElement(this.currentPageScreen$.value);
    }
  }

  updateElement(
    element: ThemeElement,
    settings,
    property: string,
    propertiesForAllScreens: { [key: string]: UpdateElementMode },
  ): void {
    element[property] = settings;
    if (element.canHaveDifferentSettingsOnMultipleScreens) {
      this.updateElementOnRelatedScreens(element, settings, property);
    } else {
      this.updateElementOnAllScreens(element, propertiesForAllScreens);
    }
    this.elementChanged$.next(element);
  }

  updateElementPosition(element: ThemeElement, settings): void {
    element.position = settings;
    this.updateElementOnRelatedScreens(element, settings, 'position');
  }

  updateElementByObject(
    element,
    settings,
    properties: { [key: string]: UpdateElementMode },
    forceUpdateOnRelatedScreensOnly = false,
  ): void {
    Object.keys(properties).forEach((property) => {
      if (properties[property] === UpdateElementMode.EXTEND) {
        element[property] = {
          ...element[property],
          ...settings[property],
        };
      } else {
        element[property] = settings[property];
      }
    });
    if (
      element.canHaveDifferentSettingsOnMultipleScreens ||
      forceUpdateOnRelatedScreensOnly
    ) {
      this.updateElementByObjectOnRelatedScreens(element, settings, properties);
    } else {
      this.updateElementOnAllScreens(element, properties);
    }
    this.elementChanged$.next(element);
  }

  updateElementActiveProperty(element, active, parent): void {
    element.active = active;
    // TODO: add property to decide if element should update on related or all screens
    // if(element.canHaveDifferentSettingsOnMultipleScreens) {
    //   this.updateElementActivePropertyOnRelatedScreens(element);
    // } else {
    // this.updateElementActivePropertyOnAllScreens(element);
    // }
    this.updateElementActivePropertyOnAllScreens(element, active);
    this.elementChanged$.next(parent);
  }

  // when selecting sections parent should be the same as the element, in other cases parent is normal parent
  selectElement(element, editingTrue = undefined): void {
    element.editing = editingTrue || !element.editing;
    if (element.editing) {
      element.hovered = false;
    }
    this.selectedElement$.next(element);
  }

  hoverElement(element): void {
    if (element) {
      element.hovered = true;
    }
    this.hoveredElement$.next(element);
  }

  goBack(): void {
    const previousParents = this.previousParents$.value;
    const previousParent = previousParents.pop();
    this.parent$.next(previousParent);
    this.previousParents$.next(previousParents);
    this.selectElement(previousParent, true);
  }

  getScreensWhereElementIsEqual(element, props: string[]): any[] {
    const screens = [];
    this.screens.forEach((s) => {
      if (s === this.currentScreen$.value) {
        screens.push(s);
        return;
      }
      if (!element) return;
      const el = this.findElementById(
        element,
        this.site.screens[s.value].pages.filter(
          (p) => p.id === this.currentPageScreen$.value.id,
        )[0],
      );
      let equal = true;
      props.forEach((prop) => {
        if (JSON.stringify(element[prop]) !== JSON.stringify(el[prop])) {
          equal = false;
        }
      });
      if (equal) {
        screens.push(s);
      }
    });
    return screens;
  }

  getParent(element: NonSectionElement): SectionElement {
    return this.getParentRecursive(this.currentPageScreen$.value, element);
  }

  getParentRecursive(root: SectionElement, element): SectionElement {
    if (root.sections === undefined) {
      return null;
    }
    if (root.sections.includes(element)) {
      return root;
    }
    for (const section of root.sections as SectionElement[]) {
      const parent = this.getParentRecursive(section, element);
      if (parent) {
        return parent;
      }
    }
    return null;
  }

  getChainToTheTop(element): SectionElement[] {
    const sections: SectionElement[] = [];
    this.getChainToTheTopRecursive(
      this.currentPageScreen$.value,
      element,
      sections,
    );
    return sections;
  }

  getChainToTheTopRecursive(
    root,
    element,
    sections: SectionElement[],
  ): boolean {
    if (root === element) {
      return true;
    }
    if (root.sections === undefined) {
      return false;
    }
    for (const section of root.sections) {
      if (this.getChainToTheTopRecursive(section, element, sections)) {
        sections.unshift(root);
        return true;
      }
    }
    return false;
  }

  updateElementOnAllScreens(
    element,
    properties: { [key: string]: UpdateElementMode },
  ): void {
    Object.keys(this.site.screens).forEach((screen) => {
      if (screen === this.currentScreen$.value.value) return;
      const pages = this.site.screens[screen].pages.filter(
        (p) => this.currentPageScreen$.value.id === p.id,
      );
      if (pages.length === 0) return;
      const pageElement: any = this.findElementById(element, pages[0]);
      if (!pageElement) return;
      Object.keys(properties).forEach((property) => {
        if (properties[property] === UpdateElementMode.EXTEND) {
          pageElement[property] = {
            ...pageElement[property],
            ...element[property],
          };
        } else {
          pageElement[property] = element[property];
        }
      });
    });
    this.elementChanged$.next(element);
  }

  updateElementOnRelatedScreens(element, settings, property): void {
    const relatedScreens = this.relatedScreens.filter(
      (rs) => rs.value === this.currentScreen$.value.value,
    );
    if (relatedScreens.length > 0) {
      const screens = relatedScreens[0].screens;
      screens.forEach((screen) => {
        const pages = this.site.screens[screen].pages.filter(
          (p) => this.currentPageScreen$.value.id === p.id,
        );
        if (pages.length === 0) return;
        const pageElement: any = this.findElementById(element, pages[0]);
        if (!pageElement) return;
        pageElement[property] = settings;
      });
    }
  }

  updateElementByObjectOnRelatedScreens(
    element,
    settings,
    properties: { [key: string]: UpdateElementMode },
  ): void {
    const relatedScreens = this.relatedScreens.filter(
      (rs) => rs.value === this.currentScreen$.value.value,
    );
    if (relatedScreens.length > 0) {
      const screens = relatedScreens[0].screens;
      screens.forEach((screen) => {
        const pages = this.site.screens[screen].pages.filter(
          (p) => this.currentPageScreen$.value.id === p.id,
        );
        if (pages.length === 0) return;
        const pageElement: any = this.findElementById(element, pages[0]);
        if (!pageElement) return;
        Object.keys(properties).forEach((property) => {
          if (properties[property] === UpdateElementMode.EXTEND) {
            pageElement[property] = {
              ...pageElement[property],
              ...settings[property],
            };
          } else {
            pageElement[property] = settings[property];
          }
        });
      });
    }
  }

  updateElementActivePropertyOnRelatedScreens(element, active): void {
    const relatedScreens = this.relatedScreens.filter(
      (rs) => rs.value === this.currentScreen$.value.value,
    );
    if (relatedScreens.length > 0) {
      const screens = relatedScreens[0].screens;
      screens.forEach((screen) => {
        const pages = this.site.screens[screen].pages.filter(
          (p) => this.currentPageScreen$.value.id === p.id,
        );
        if (pages.length === 0) return;
        const pageElement: any = this.findElementById(element, pages[0]);
        if (!pageElement) return;
        pageElement.active = active;
      });
    }
  }

  updateElementActivePropertyOnAllScreens(element, active): void {
    Object.keys(this.site.screens).forEach((screen) => {
      if (screen === this.currentScreen$.value.value) return;
      const pages = this.site.screens[screen].pages.filter(
        (p) => this.currentPageScreen$.value.id === p.id,
      );
      if (pages.length === 0) return;
      const pageElement: any = this.findElementById(element, pages[0]);
      if (!pageElement) return;
      pageElement.active = active;
    });
  }

  findElementById(element, root): any {
    if (root.id === element.id) {
      return root;
    }
    if (root.sections === undefined) {
      return null;
    }
    for (const section of root.sections) {
      const el = this.findElementById(element, section);
      if (el) {
        return el;
      }
    }
    return null;
  }

  updateDraftDefinition(): void {
    setTimeout(() => {
      if (
        this.updateDraftDefinitionSubscription &&
        !this.updateDraftDefinitionSubscription.closed
      ) {
        this.updateDraftDefinitionSubscription.unsubscribe();
      }
      this.updateDraftDefinitionSubscription = this.themeService
        .updateDraftDefinition(this.themeId, JSON.stringify(this.site))
        .subscribe(({ inDraft }) => {
          this.inDraft$.next(inDraft);
        });
    });
  }

  togglePage(page): void {
    page.active = !page.active;

    Object.keys(this.site.screens).forEach((screen) => {
      if (screen === this.currentScreen$.value.value) return;
      const pages = this.site.screens[screen].pages.filter(
        (p) => page.name === p.name,
      );
      if (pages.length === 0) return;
      pages[0].active = !pages[0].active;
    });

    this.updateDraftDefinition();
  }

  updateGlobalStyle(style): void {
    this.site.style = style;
    this.updateColorsOnEachNode();
    this.globalStyle$.next(style);
  }

  updateColorsOnEachNodeRecursive(root): void {
    if (!root) return;

    if (
      root.background &&
      root.background.color &&
      root.background.color.type !== 'style'
    ) {
      root.style['background-color'] =
        this.site.style[root.background.color.value];
    }

    if (this.styleChanged$) {
      this.styleChanged$.next(root);
    }

    if (root.sections) {
      for (const element of root.sections) {
        this.updateColorsOnEachNodeRecursive(element);
      }
    }
  }

  updateColorsOnEachNode(): void {
    Object.keys(this.site.screens).forEach((screen) => {
      this.site.screens[screen].pages.forEach((page) => {
        this.updateColorsOnEachNodeRecursive(page);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.globalStyleSubscription) {
      this.globalStyleSubscription.unsubscribe();
      this.globalStyleSubscription = null;
    }
    this.subs.unsubscribe();
    sessionStorage.removeItem('currentScreen');
  }
}
