import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../_services/app/app.service';
import {
  SKIP_INTERCEPTORS,
  SKIP_JWT_RENEW_INTERCEPTOR,
} from './skip-interceptors';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private appService: AppService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }
    if (
      req.headers.get(SKIP_JWT_RENEW_INTERCEPTOR) === SKIP_JWT_RENEW_INTERCEPTOR
    ) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        headers: req.headers.append(
          'Language-code',
          this.appService.language$.value.languageCode,
        ),
      }),
    );
  }
}
