import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category } from '../../_models/category';
import { environment } from '../../../../environments/environment';
import { ShopService } from './..//shop/shop.service';
import { tap } from 'rxjs/operators';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoriesChanged = false;
  shopCategories$ = new BehaviorSubject<Category[]>(null);

  categorySelectedProducts$ = new BehaviorSubject<Map<number, any>>(null);

  addProductsToCategory(
    categoryId: string,
    productsIds: number[],
  ): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}categories/${categoryId}/products`,
      productsIds,
    );
  }

  getCategorySelectedProducts(categoryId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}categories/${categoryId}/product_list`,
    );
  }

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${environment.apiUrl}e_commerces/${
        this.shopService.selectedShop$.getValue().id
      }/categories`,
      {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      },
    );
  }

  getShopCategories(httpHeaders = []): Observable<Category[]> {
    if (this.categoriesChanged || this.shopCategories$.value === null) {
      let headers = new HttpHeaders();
      httpHeaders.forEach((hh) => {
        headers = headers.set(hh, hh);
      });
      return this.http
        .get<Category[]>(
          `${environment.apiUrl}e_commerces/${
            this.shopService.selectedShop$.getValue().id
          }/categories_with_active_products`,
          {
            headers: headers,
          },
        )
        .pipe(
          tap((categories) => {
            this.categoriesChanged = false;
            this.shopCategories$.next(categories);
          }),
        );
    }
    return this.shopCategories$;
  }

  updateCategory(category: Category): Observable<any> {
    category.eCommerceId = this.shopService.selectedShop$.getValue().id;
    return this.http
      .put(`${environment.apiUrl}categories/${category.id}`, category, {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      })
      .pipe(tap((_) => (this.categoriesChanged = true)));
  }

  createCategory(category: Category): Observable<any> {
    category.eCommerceId = this.shopService.selectedShop$.getValue().id;
    return this.http
      .post(`${environment.apiUrl}categories`, category, {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      })
      .pipe(tap((_) => (this.categoriesChanged = true)));
  }

  deleteCategory(id: number): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}categories/${id}`)
      .pipe(tap((_) => (this.categoriesChanged = true)));
  }

  constructor(private http: HttpClient, private shopService: ShopService) {}
}
