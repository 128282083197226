import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaginatedResult } from '@kadung/kadung/kadu-table';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AppService } from 'src/app/_services/app/app.service';
import { ProductResponse } from 'src/app/dashboard/_models/product-response';
import { CategoryService } from 'src/app/dashboard/_services/category/category.service';
import { ProductService } from 'src/app/dashboard/_services/product/product.service';
import { SubSink } from 'subsink';
import { generateFormFilterParams, isEllipsisActive } from '../util';

@Component({
  selector: 'app-products-chooser',
  templateUrl: './products-chooser.component.html',
  styleUrls: ['./products-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsChooserComponent implements OnInit {
  @Input() action: any;
  @Input() filtersForm: FormGroup;
  @Input() filters: { control: string; matchMode: string }[];
  @Input() categorySelectedProducts: [];
  @Input() bulkSelectedProducts: [];
  @Input() mode: 'category' | 'action';

  isElipsisActive = isEllipsisActive;
  productsSource$ = new BehaviorSubject<any>([]);
  productsRequest$ = new BehaviorSubject<Observable<any>>(of([]));
  productsTotalCount;
  allSelectedOnPage$ = new BehaviorSubject<boolean>(false);
  selectedProducts = new Map<number, any>();
  searchSubject = new Subject<any>();
  subs = new SubSink();

  productsPageInfo: {
    currentResults;
    totalPages: number;
    totalResults: number;
  };
  currentCountry = this.appService.currentCountry;

  currentFilters$ = new BehaviorSubject<any>({
    first: 0,
    rows: 16,
    sortOrder: -1,
    sortField: 'created',
    filters: {},
  });

  currentPage$ = new BehaviorSubject<number>(0);
  first$ = this.currentPage$
    .asObservable()
    .pipe(map((page) => page * this.currentFilters$.value.rows));

  convertTreeToList(categories): [] {
    if (!categories || categories.length === 0) {
      return [];
    }
    let array = categories;
    categories.forEach((c) => {
      if (c.childCategories) {
        array = array.concat(
          this.convertTreeToList(
            c.childCategories.map((cc) => {
              return {
                ...cc,
                prefix: c.slug + ' -> ',
              };
            }),
          ),
        );
      }
    });
    return array;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private productService: ProductService,
    private categoryService: CategoryService,
    private appService: AppService,
  ) {}

  addProductsToServiceSubject(): void {
    if (this.mode === 'category') {
      this.categoryService.categorySelectedProducts$.next(
        this.selectedProducts,
      );
    } else {
      this.productService.selectedProducts$.next(this.selectedProducts);
    }
  }

  ngOnChanges(): void {
    this.subs.add(
      this.filtersForm?.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(600))
        .subscribe((values) => {
          this.searchProducts();
        }),
    );

    if (this.categorySelectedProducts) {
      this.categorySelectedProducts.forEach(
        (categoryProduct: {
          id: number;
          name: string;
          product_type_name: string;
        }) => {
          this.selectedProducts.set(categoryProduct.id, categoryProduct);
        },
      );
      this.addProductsToServiceSubject();
    }

    if (this.bulkSelectedProducts) {
      this.bulkSelectedProducts.forEach(
        (bulkProduct: {
          id: number;
          name: string;
          product_type_name: string;
        }) => {
          this.selectedProducts.set(bulkProduct.id, bulkProduct);
        },
      );
      this.addProductsToServiceSubject();
    }
  }

  ngOnInit(): void {
    if (this.action && this.action.product_id_list) {
      this.action.product_id_list.forEach((p) => {
        this.selectedProducts.set(p.id, p);
      });
      this.addProductsToServiceSubject();
    }

    this.getProducts();
  }

  searchProducts(): void {
    this.currentFilters$.next({
      ...this.currentFilters$.value,
      filters: this.filtersForm.value,
      first: 0,
    });

    this.currentPage$.next(0);
    this.getProducts();
  }

  changePage(event: any): void {
    this.currentFilters$.next({
      ...this.currentFilters$.value,
      first: event.first,
    });

    this.currentPage$.next(event.first / event.rows);
    this.getProducts();
  }

  getProducts(): void {
    this.productsRequest$.next(
      this.productService
        .getProducts(
          generateFormFilterParams(this.currentFilters$.value, this.filters),
        )
        .pipe(
          tap((response) => {
            this.productsPageInfo = {
              currentResults: response.current_results,
              totalPages: response.total_pages,
              totalResults: response.total_results,
            };

            let allSelected = true;
            this.productsSource$.next(
              response.current_results.map((p) => {
                p.id = +p.id;
                if (!this.selectedProducts.has(p.id)) {
                  allSelected = false;
                }
                return {
                  ...p,
                  selected: this.selectedProducts.has(p.id),
                };
              }),
            );
            this.productsTotalCount = response.total_results;
            if (this.productsTotalCount !== 0) {
              this.allSelectedOnPage$.next(allSelected);
            } else {
              this.allSelectedOnPage$.next(false);
            }
          }),
        ),
    );
  }

  trackProducts(index: number, product: ProductResponse): number {
    return product.id;
  }

  selectProduct(product, event): void | any {
    if (product.selected === undefined) {
      this.selectedProducts.delete(product.id);
    } else {
      product.selected = event;
      if (product.selected) {
        this.selectedProducts.set(product.id, product);
      } else {
        this.selectedProducts.delete(product.id);
        this.allSelectedOnPage$.next(false);
      }
    }
    let allSelected = true;
    this.productsSource$.next(
      this.productsSource$.value.map((p) => {
        if (!this.selectedProducts.has(p.id)) {
          allSelected = false;
        }
        if (p.id === product.id) {
          p.selected = event;
        }
        return p;
      }),
    );
    this.allSelectedOnPage$.next(allSelected);
    this.addProductsToServiceSubject();
  }

  selectAll(): void {
    this.selectAllOnPage(true);
    this.productService
      .getNames({
        ...generateFormFilterParams(this.currentFilters$.value, this.filters),
        size: this.productsTotalCount,
      })
      .subscribe((res) => {
        res.current_results.forEach((action: any) => {
          this.selectedProducts.set(action.id, action);
        });
        this.addProductsToServiceSubject();

        this.cdr.markForCheck();
      });
  }

  selectAllOnPage(allSelected): void | any {
    this.productsSource$.next(
      this.productsSource$.value.map((p) => {
        const value = {
          ...p,
          selected: allSelected,
        };
        if (allSelected) {
          this.selectedProducts.set(value.id, value);
        } else {
          this.selectedProducts.delete(value.id);
        }
        return value;
      }),
    );
    this.allSelectedOnPage$.next(allSelected);
    this.addProductsToServiceSubject();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
