import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appResizer]'
})
export class ResizerDirective {

  @HostListener('window:resize')
  onResize() {
    this.renderer.setStyle(this.el.nativeElement, 'height', this.el.nativeElement.clientWidth + 'px');
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }
}
