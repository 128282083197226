export const environment = {
  environment: 'stage',
  production: true,
  apiUrl: '',
  appUrl: '',
  imageGeneratorProtocol: 'https://',
  imageGeneratorHost: 'image-processor-brendly-stage.herokuapp.com',
  imageGeneratorUrl: '',
  s3Url: 'https://brendi-stage.s3.eu-central-1.amazonaws.com/',
  googleTagManagerId: 'GTM-WP7MNHQ',
  googleDeveloperApiKey: 'AIzaSyB8d3Pf4Y0Hq4tRVC_YhyDKkscVF-7bAXo',
  sentryDSNKey:
    'https://473475cb06fb4a9a89e175bd8f60f8e0@o463359.ingest.sentry.io/5468325',
  countries: [
    {
      id: '0',
      name: 'Srbija',
      alpha2Code: 'RS',
      flagImageUrl: 'fi fi-rs',
      apiUrl: 'https://api.stage.brendly.rs/',
      appUrl: 'https://app.stage.brendly.rs',
      shopUrlSuffix: '.shop.stage.brendly.rs',
      signupAvailable: true,
      availableForUsersFromCountries: [
        {
          userFrom: 'Srbija',
          isUserLegalEntity: true,
          isUserPersonalEntity: true,
          userIdWhitelist: [],
        },
        {
          userFrom: 'Hrvatska',
          isUserLegalEntity: true,
          isUserPersonalEntity: true,
          userIdWhitelist: [],
        },
      ],
    },
    {
      id: '1',
      name: 'Hrvatska',
      alpha2Code: 'HR',
      flagImageUrl: 'fi fi-hr',
      apiUrl: 'https://api.stage.brendly.hr/',
      appUrl: 'https://app.stage.brendly.hr',
      shopUrlSuffix: '.shop.stage.brendly.hr',
      signupAvailable: true,
      availableForUsersFromCountries: [
        {
          userFrom: 'Srbija',
          isUserLegalEntity: true,
          isUserPersonalEntity: true,
          userIdWhitelist: [],
        },
        {
          userFrom: 'Hrvatska',
          isUserLegalEntity: true,
          isUserPersonalEntity: true,
          userIdWhitelist: [],
        },
      ],
    },
  ],
  languages: [
    {
      id: '0',
      name: 'Srpski',
      alpha2Code: 'RS',
      languageCode: 'SR',
      flagImageUrl: 'fi fi-rs',
    },
    {
      id: '1',
      name: 'Hrvatski',
      alpha2Code: 'HR',
      languageCode: 'HR',
      flagImageUrl: 'fi fi-hr',
    },
  ],
  productTypeCategories: new Map([
    [
      'RS',
      {
        men: ['3', '23', '5', '16', '17', '28'],
        women: ['4', '5', '16', '17', '21', '22', '28'],
        kids: ['18', '19', '20'],
        totebags: ['12', '13', '14', '15', '30', '31'],
        wallArt: ['9', '10', '11'],
        cupsAndTermos: ['24', '32', '33', '34', '35'],
        kitchen: ['36', '37', '38', '39', '40'],
      },
    ],
    [
      'HR',
      {
        women: ['2'],
        men: ['1'],
        kids: ['3'],
      },
    ],
  ]),
};
