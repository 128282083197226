import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductType } from '../../_models/product-type';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductResponse } from '../../_models/product-response';
import { map, tap } from 'rxjs/operators';
import { ShopService } from '../shop/shop.service';
import { Color } from '../../_models/color';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';
import { PaginatedResult } from '@kadung/kadung/kadu-table';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';
import { ProductTypeWithPrices } from '../../_models/product-type-with-prices';
import { ItemBasePriceValue } from '../../_models/item-base-price-value';
import { ProductPublishedResponse } from '../../_models/product-published-response';
import { ProductPublishedList } from '../../_models/product-published-list';
import { ProductTypeItemStockStateResponse } from '../../_models/product-type-item-stock-state-response';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  selectedProducts$ = new BehaviorSubject<Map<number, any>>(null);
  shopProductTypes$ = new BehaviorSubject<ProductType[]>(null);

  constructor(private http: HttpClient, private shopService: ShopService) {}

  bulkDeleteProducts(): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}e_commerces/${
        this.shopService.selectedShop$.getValue().id
      }/bulk_delete_products`,
      [...this.selectedProducts$.value.keys()],
    );
  }

  bulkPublishedUpdate(publishedWithProductId: any): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}e_commerces/${
        this.shopService.selectedShop$.getValue().id
      }/bulk_published_update`,
      { publishedWithProductId },
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
        ),
      },
    );
  }

  bulkPriceUpdate(
    productTypesWithPrices: Map<number, Map<number, ItemBasePriceValue>>,
  ): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}e_commerces/${
        this.shopService.selectedShop$.getValue().id
      }/bulk_price_update`,
      { ...productTypesWithPrices },
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
        ),
      },
    );
  }

  getProductTypesWithPrice(): Observable<ProductTypeWithPrices> {
    return this.http.post<ProductTypeWithPrices>(
      `${environment.apiUrl}e_commerces/${
        this.shopService.selectedShop$.getValue().id
      }/product_types_with_prices`,
      [...this.selectedProducts$.value.keys()],
    );
  }

  getStockStateProductTypeItems(): Observable<
    ProductTypeItemStockStateResponse[]
  > {
    return this.http.get<ProductTypeItemStockStateResponse[]>(
      `${environment.apiUrl}product_type_items`,
      {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      },
    );
  }

  getProductTypes(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(`${environment.apiUrl}product_types`, {
      headers: new HttpHeaders().set(
        SKIP_LOADING_INTERCEPTOR,
        SKIP_LOADING_INTERCEPTOR,
      ),
    });
  }

  // todo - add interceptor for caching
  getShopProductTypes(httpHeaders = []): Observable<ProductType[]> {
    if (this.shopProductTypes$.value === null) {
      let headers = new HttpHeaders();
      httpHeaders.forEach((hh) => {
        headers = headers.set(hh, hh);
      });
      return this.http
        .get<ProductType[]>(
          `${environment.apiUrl}e_commerces/${
            this.shopService.selectedShop$.getValue().id
          }/product_types/`,
          {
            headers: headers,
          },
        )
        .pipe(tap((productTypes) => this.shopProductTypes$.next(productTypes)));
    }
    return this.shopProductTypes$;
  }

  getProductType(id: number): Observable<ProductType> {
    return this.http
      .get<ProductType>(`${environment.apiUrl}product_types/${id}`)
      .pipe(
        map((res) => {
          return {
            ...res,
            productTypeParts: res.productTypeParts.map((pp) => {
              return {
                ...pp,
                partColorImages: pp.partColorImages.map((pci) => {
                  return {
                    ...pci,
                    imageUrl: pci.imageUrl.substring(
                      0,
                      pci.imageUrl.indexOf('&cacheblock=true'),
                    ),
                  };
                }),
              };
            }),
          };
        }),
      );
  }

  deleteImageVariant(id: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}product_image_variants/${id}`,
    );
  }

  chooseImageVariant(id: number, productId: number): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}products/${productId}/product_image_variants/${id}`,
      {},
    );
  }

  addImageVariants(data): Observable<any> {
    const formData = new FormData();
    data.images.forEach((i, index) => {
      formData.append('images', new File([i], index + '.jpg'), index + '.jpg');
    });
    formData.set(
      'request',
      JSON.stringify({ color_ids: data.colorIds, product_id: data.productId }),
    );
    return this.http.post(
      `${environment.apiUrl}product_image_variants`,
      formData,
    );
  }

  getColors(productId: number): Observable<Color[]> {
    return this.http.get<Color[]>(
      `${environment.apiUrl}colors/product/${productId}`,
    );
  }

  getProductTypeParts(
    productId: number,
  ): Observable<{ id: number; name: string; order_number: number }[]> {
    return this.http.get<{ id: number; name: string; order_number: number }[]>(
      `${environment.apiUrl}productTypeParts/product/${productId}`,
    );
  }

  getProduct(id: number): Observable<ProductResponse> {
    return this.http.get<ProductResponse>(
      `${environment.apiUrl}e_commerces/${
        this.shopService.selectedShop$.getValue().id
      }/products/${id}/edit`,
    );
  }

  getProductItemListWithManufacturedProductList(
    productId: string,
    productItemIdList: number[],
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}products/${productId}/product_item_list_with_manufactured_product`,
      productItemIdList,
    );
  }

  getProductImages(productId: number): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}products/${productId}/mockups`,
    );
  }

  getProducts(params: any = {}): Observable<PaginatedResult<ProductResponse>> {
    return this.http
      .get<PaginatedResult<ProductResponse>>(
        `${environment.apiUrl}e_commerces/${
          this.shopService.selectedShop$.getValue().id
        }/products`,
        {
          params,
          headers: new HttpHeaders().set(
            SKIP_LOADING_INTERCEPTOR,
            SKIP_LOADING_INTERCEPTOR,
          ),
        },
      )
      .pipe(
        map((products) => {
          //TODO
          return {
            ...products,
            current_results: products.current_results.map((p: any) => {
              return {
                ...p,
                product_image: p.product_images.filter(
                  (pi) =>
                    pi.color.id == p.primary_color_id &&
                    pi.productTypePartId === p.primaryPartId,
                )[0].product_front_image_url,
                product_images: p.product_images.map((pi, index) => {
                  return {
                    ...pi,
                    selected:
                      pi.color.id == p.primary_color_id &&
                      pi.product_type_part_id === p.primary_part_id,
                  };
                }),
              };
            }),
          };
        }),
      );
  }

  publishProduct(productId: number): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}products/${productId}/publish`,
      {},
    );
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}products/${productId}/delete`,
      {},
    );
  }

  getNames(
    params: any = {},
  ): Observable<PaginatedResult<ProductPublishedResponse>> {
    params = {
      ...params,
      e_commerce_id: this.shopService.selectedShop$.getValue().id,
      page: 0,
    };

    return this.http.get<PaginatedResult<ProductPublishedResponse>>(
      `${environment.apiUrl}products/names`,
      {
        params,
      },
    );
  }

  saveMockupSettings(mockups: any[], productId): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}products/${productId}/product_image_variants/sort_chosen`,
      mockups,
    );
  }
}
