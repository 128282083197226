import { Injectable } from '@angular/core';
import { Observable, throwError, Subject, defer, BehaviorSubject } from 'rxjs';
import { HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import {
  catchError,
  concatMap,
  filter,
  take,
  switchMap,
  finalize,
  tap,
} from 'rxjs/operators';
import { AuthService } from '../_services/auth/auth.service';
import { Router } from '@angular/router';
import {
  SKIP_INTERCEPTORS,
  SKIP_JWT_RENEW_INTERCEPTOR,
} from './skip-interceptors';

@Injectable({
  providedIn: 'root',
})
export class JwtRenewInterceptorService {
  requestInProgress = false;
  tokenSubject = new Subject();

  constructor(private authService: AuthService, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }
    if (
      req.headers.get(SKIP_JWT_RENEW_INTERCEPTOR) === SKIP_JWT_RENEW_INTERCEPTOR
    ) {
      return next.handle(req);
    }
    if (this.requestInProgress && req.headers.get('refresh') !== 'refresh') {
      return this.tokenSubject.pipe(
        take(1),
        switchMap((token) => {
          return next.handle(req);
        }),
      );
    }
    if (
      this.authService.isUserLoggedIn() &&
      this.authService.isTokenExpired() &&
      !this.requestInProgress
    ) {
      this.requestInProgress = true;
      return this.authService
        .renewRefreshToken({
          accessToken: this.authService.getAccessToken(),
          userId: this.authService.getUserId(),
          refreshToken: this.authService.getRefreshToken(),
        })
        .pipe(
          concatMap((res) => {
            this.authService.saveRefreshTokenToLocalStorage(res.refreshToken);
            this.authService.saveAccessTokenToLocalStorage(res.accessToken);
            this.tokenSubject.next(res.accessToken);
            return next.handle(req).pipe(
              tap(() => {
                this.tokenSubject = new Subject();
              }),
            );
          }),
          catchError((err) => {
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('access_token');
            this.router.navigate(['/login']);

            return throwError(err);
          }),
          finalize(() => {
            this.requestInProgress = false;
          }),
        );
    }
    return next.handle(req);
  }
}
