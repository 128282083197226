import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShopService } from './../shop/shop.service';
import * as moment from 'moment';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  updateDraftDefinitionInProgress$ = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient, private shopService: ShopService) {}

  getAll(): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}e_commerces/${
          this.shopService.selectedShop$.getValue().id
        }/e_commerce_themes`,
        {
          headers: new HttpHeaders().set(
            SKIP_LOADING_INTERCEPTOR,
            SKIP_LOADING_INTERCEPTOR,
          ),
        },
      )
      .pipe(
        map((themes) => {
          return themes.map((t) => ({
            ...t,
            lastActivationTime: t.lastActivationTime
              ? moment.utc(t.lastActivationTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
              : null,
            lastDraftUpdateTime: t.lastDraftUpdateTime
              ? moment
                  .utc(t.lastDraftUpdateTime, 'YYYY-MM-DD-HH-mm-ss')
                  .toDate()
              : null,
            lastPublishTime: t.lastPublishTime
              ? moment.utc(t.lastPublishTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
              : null,
          }));
        }),
      );
  }

  getOne(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}e_commerce_themes/${id}`, {
      headers: new HttpHeaders().set(
        SKIP_DTO_TRANSFORM_INTERCEPTOR,
        SKIP_DTO_TRANSFORM_INTERCEPTOR,
      ),
    });
  }

  getOneDraftDefinition(id): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}e_commerce_themes/${id}/draft_definition`,
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
        ),
      },
    );
  }

  getOneDefinition(id): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}e_commerce_themes/${id}/definition`,
    );
  }

  getAllFromBrendlyLibrary(): Observable<any> {
    return this.http.get(`${environment.apiUrl}themes`);
  }

  activateOne(id): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerce_themes/${id}/activate`,
      {},
    );
  }

  editName(id, name): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerce_themes/${id}`,
      {
        name: name,
      },
      {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      },
    );
  }

  deleteOne(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}e_commerce_themes/${id}`);
  }

  duplicateOne(id, name): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}e_commerce_themes/${id}/duplicate`,
        {
          name: name,
        },
        {
          headers: new HttpHeaders().set(
            SKIP_LOADING_INTERCEPTOR,
            SKIP_LOADING_INTERCEPTOR,
          ),
        },
      )
      .pipe(
        map((t) => ({
          ...t,
          lastActivationTime: t.lastActivationTime
            ? moment.utc(t.lastActivationTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
          lastDraftUpdateTime: t.lastDraftUpdateTime
            ? moment.utc(t.lastDraftUpdateTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
          lastPublishTime: t.lastPublishTime
            ? moment.utc(t.lastPublishTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
        })),
      );
  }

  discardDraft(id): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerce_themes/${id}/discard`,
      {},
    );
  }

  publish(id): Observable<any> {
    return this.http
      .put<any>(`${environment.apiUrl}e_commerce_themes/${id}/publish`, {})
      .pipe(
        map((t) => ({
          ...t,
          lastActivationTime: t.lastActivationTime
            ? moment.utc(t.lastActivationTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
          lastDraftUpdateTime: t.lastDraftUpdateTime
            ? moment.utc(t.lastDraftUpdateTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
          lastPublishTime: t.lastPublishTime
            ? moment.utc(t.lastPublishTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
        })),
      );
  }

  updateDraftDefinition(id, draftDefinition): Observable<any> {
    this.updateDraftDefinitionInProgress$.next(id);
    return this.http
      .put<any>(
        `${environment.apiUrl}e_commerce_themes/${id}/draft_definition`,
        {
          draftDefinition,
        },
      )
      .pipe(tap(() => this.updateDraftDefinitionInProgress$.next(null)));
  }

  importOneFromBrendlyLibrary(id, name): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}e_commerce_themes`, {
        themeId: id,
        eCommerceId: +this.shopService.selectedShop$.getValue().id,
        name: name,
      })
      .pipe(
        map((t) => ({
          ...t,
          lastActivationTime: t.lastActivationTime
            ? moment.utc(t.lastActivationTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
          lastDraftUpdateTime: t.lastDraftUpdateTime
            ? moment.utc(t.lastDraftUpdateTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
          lastPublishTime: t.lastPublishTime
            ? moment.utc(t.lastPublishTime, 'YYYY-MM-DD-HH-mm-ss').toDate()
            : null,
        })),
      );
  }
}
