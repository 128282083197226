import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  Input,
  HostListener,
  RendererStyleFlags2,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

@Directive({
  selector: '[duloSquare]',
})
export class SquareDirective implements AfterViewInit {
  @Input('duloSquareProperty') property: 'height' | 'width' = 'height';
  @Input('duloSquareDeviation') deviation = 0;
  @Input('duloSquareResponsive') responsive = false;
  @Output('duloSquareResizeHandler') resizeHandler = new EventEmitter();
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.squarify();
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: Event): void {
    this.squarify();
  }

  squarify(): void {
    if (this.responsive) {
      if (
        this.elRef.nativeElement.clientWidth >
        this.elRef.nativeElement.clientHeight
      ) {
        this.renderer.setStyle(
          this.elRef.nativeElement,
          'width',
          `${this.elRef.nativeElement.clientHeight}px`,
        );
        this.renderer.setStyle(
          this.elRef.nativeElement,
          'height',
          `${this.elRef.nativeElement.clientHeight}px`,
        );
      } else {
        this.renderer.setStyle(
          this.elRef.nativeElement,
          'height',
          `${this.elRef.nativeElement.clientWidth}px`,
        );
        this.renderer.setStyle(
          this.elRef.nativeElement,
          'width',
          `${this.elRef.nativeElement.clientWidth}px`,
        );
      }
    } else {
      this.renderer.setStyle(
        this.elRef.nativeElement,
        this.property,
        `${this.elRef.nativeElement.clientWidth + +this.deviation}px`,
        RendererStyleFlags2.DashCase | RendererStyleFlags2.Important,
      );
    }
    this.resizeHandler.emit();
  }
}
