import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { KaduFormComponent, KaduFormRequest } from '@kadung/kadung/kadu-form';
import { UserLogin } from 'src/app/_models/userLogin';
import { ShopService } from 'src/app/dashboard/_services/shop/shop.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends KaduFormRequest implements OnInit {
  formControls = {
    email: { state: '', validators: Validators.required },
    password: { state: '', validators: Validators.required },
  };

  constructor(
    private router: Router,
    private shopService: ShopService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.cleanUp();
  }

  cleanUp(): void {
    this.shopService.selectShop(null);
    this.authService.authToken$.next(null);
  }

  login(value): void {
    const user = new UserLogin({
      email: value.email,
      password: value.password,
    });

    this.getRequest(user).subscribe(
      () => {
        this.router.navigate(['dashboard']);
      },
      (error) => {
        this.handleError(error);
      },
    );
  }

  protected _getRequest(user: UserLogin): Observable<any> {
    return this.authService.logIn(user);
  }

  handleError(error): void {
    switch (error.error.status) {
      case 403: {
        this.formComponent.formGroup.get('password').setErrors({
          failLogin: true,
        });

        break;
      }
    }
  }
}
