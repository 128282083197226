import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KaduOverlayComponent } from '@kadung/kadung/kadu-overlay';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Country } from 'src/app/_models/country';
import { AppService } from 'src/app/_services/app/app.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotificationService } from 'src/app/_services/notification/notification.service';
import { VerificationService } from 'src/app/_services/verification/verification.service';
import { Shop } from 'src/app/dashboard/_models/shop';
import { SettingsService } from 'src/app/dashboard/_services/settings/settings.service';
import { SubSink } from 'subsink';
import { ShopService } from '../../dashboard/_services/shop/shop.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/dashboard/_services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  subs = new SubSink();
  selectedShop$ = this.shopService.selectedShop$;
  shops$ = this.shopService.shops$;
  notifications$ = this.notificationService.notifications$;
  authToken$ = this.authService.authToken$;
  menuItems$ = new BehaviorSubject<MenuItem[]>(null);
  countries$ = new BehaviorSubject<any>([]);
  country$ = this.appService.country$;
  userBanState$ = this.userService.userBanState$;
  priorityNotification$ = this.notificationService.priorityNotification$;

  constructor(
    private userService: UserService,
    private shopService: ShopService,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private appService: AppService,
    private settingsService: SettingsService,
    private verificationService: VerificationService,
    private translateService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.authToken$
        .pipe(
          filter((authToken) => !!authToken),
          switchMap((authToken) =>
            combineLatest([
              authToken,
              this.appService.language$,
              this.settingsService.isLegalEntity$,
            ]),
          ),
        )
        .subscribe(([authToken, language, isLegalEntity]) => {
          if (!authToken) return;

          this.countries$.next(
            this.appService.getMarketsForUser({
              id: +this.authService.getUserId(),
              isLegalEntity: isLegalEntity,
              countryName: this.authService.getUserCountry(),
            }),
          );

          this.translateService
            .selectTranslate('header.menu.changeCountry')
            .subscribe(() => {
              this.menuItems$.next([
                {
                  label: this.translateService.translate(
                    'header.menu.changeCountry',
                  ),
                  icon: 'bi bi-globe',
                  items: this.appService
                    .getMarketsForUser({
                      id: +this.authService.getUserId(),
                      isLegalEntity: isLegalEntity,
                      countryName: this.authService.getUserCountry(),
                    })
                    .map((country) => {
                      return {
                        ...country,
                        styleClass:
                          this.appService.country$.value?.name === country.name
                            ? 'selected-item'
                            : '',
                        icon: country.flagImageUrl,
                        label: country.name,
                        command: (): void => {
                          this.appService.country = country;
                        },
                      } as MenuItem;
                    }),
                },
                {
                  label: this.translateService.translate(
                    'header.menu.changeLanguage',
                  ),
                  icon: 'bi bi-translate',
                  items: this.appService.languages.map((language) => {
                    return {
                      ...language,
                      icon: language.flagImageUrl,
                      styleClass:
                        this.appService.language$.value?.alpha2Code ===
                        language.alpha2Code
                          ? 'selected-item'
                          : '',
                      label: language.name,
                      command: (): void => {
                        this.appService.language = language;
                      },
                    } as MenuItem;
                  }),
                },
                {
                  label: this.translateService.translate(
                    'header.menu.settings',
                  ),
                  icon: 'bi bi-gear-fill',
                  command: (): void => {
                    this.router.navigate(['dashboard/user/settings']);
                  },
                },
                { separator: true },
                {
                  label: this.translateService.translate('header.menu.logout'),
                  icon: 'bi bi-box-arrow-right',
                  command: (): void => {
                    this.logout();
                  },
                },
              ]);
            });
        }),
    );
  }

  selectCountry(country: Country): void {
    this.appService.country = country;
  }

  publishShop(published: boolean): void {
    if (published) {
      window.open(this.selectedShop$.getValue().url, '_blank');
      return;
    }
    this.shopService.publishShop().subscribe(() => {
      this.shopService.selectShop({
        ...this.selectedShop$.value,
        published: true,
      });

      this.verificationService.getVerificationData().subscribe();
      window.open(this.selectedShop$.getValue().url, '_blank');
    });
  }

  selectShop(selectedValue: Shop, shopsOverlay?: KaduOverlayComponent): void {
    this.subs.add(
      this.shopService.getShopById(selectedValue.id).subscribe((shop) => {
        shopsOverlay?.close();
        this.shopService.selectShop(shop);
        this.router.navigate(['dashboard', shop.id, 'products']);
        this.verificationService.getVerificationData().subscribe();
      }),
    );
  }

  logout(): void {
    this.authService.logout().subscribe();

    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentFilters');

    this.router.navigate(['/login']);
  }

  toggleShops(shopsOverlay: KaduOverlayComponent): void {
    if (shopsOverlay.visible) {
      shopsOverlay.close();
    } else {
      shopsOverlay.open();
      setTimeout(() => {
        window.scrollTo(0, 1);
      });
    }
  }

  processLinks(e): void {
    const element: HTMLElement = e.target;
    if (element.nodeName === 'SPAN') {
      e.preventDefault();
      const link = element.getAttribute('href');
      this.router.navigate([link]);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
