export class UserSignup {
  public roles: string[];
  public email: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public phone_number: string;
  public address: string;
  public city: string;
  public country: string;
  public postal_code: string;
  public customer_segment: string;
  public referer_origin: string;
  public default_country: string;
  public default_language: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.roles = ['platform_user_role'];
  }
}
