import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth/auth.service';
import {
  SKIP_INTERCEPTORS,
  SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
} from './skip-interceptors';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthorizationInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }
    if (
      req.headers.get(SKIP_JWT_AUTHORIZATION_INTERCEPTOR) ===
      SKIP_JWT_AUTHORIZATION_INTERCEPTOR
    ) {
      return next.handle(req);
    }
    if (req.headers.get('Authorization')) {
      return next.handle(req);
    } else {
      const token = this.authService.getAccessToken();
      return next.handle(
        req.clone({
          headers: req.headers.append('Authorization', 'Bearer ' + token),
        }),
      );
    }
  }
}
