import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDecimalNumber',
})
export class FormatDecimalNumberPipe implements PipeTransform {
  transform(number: any, ...args: unknown[]): unknown {
    return +number % 1 !== 0 ? +number.toFixed(2) : number;
  }
}
