import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SKIP_DTO_TRANSFORM_INTERCEPTOR,
  SKIP_INTERCEPTORS,
  SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
  SKIP_JWT_RENEW_INTERCEPTOR,
} from './skip-interceptors';

@Injectable()
export class ClearInterceptor implements HttpInterceptor {
  skipInterceptors = [
    SKIP_INTERCEPTORS,
    SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
    SKIP_JWT_RENEW_INTERCEPTOR,
    SKIP_DTO_TRANSFORM_INTERCEPTOR,
  ];

  constructor() {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    this.skipInterceptors.forEach((interceptor) => {
      if (req.headers.get(interceptor) === interceptor) {
        req = req.clone({
          headers: req.headers.delete(interceptor),
        });
      }
    });

    return next.handle(req);
  }
}
