import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compressedImage',
})
export class CompressedImagePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) return null;
    if (value.includes('/v2/')) {
      const urlParts = value.split('.');
      const extensionWithParams = urlParts.pop();
      const extensionWithParamsParts = extensionWithParams.split('?');
      let params = null;
      if (extensionWithParamsParts.length > 0) {
        params = extensionWithParamsParts[1];
      }
      return (
        urlParts.join('.') + '_compressed.jpg' + (params ? '?' + params : '')
      );
    } else {
      return value;
    }
  }
}
