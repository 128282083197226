<div class="d-flex align-items-center" style="min-height: calc(100vh - 60px)">
  <main class="d-flex w-100 container-xxl">
    <section class="form w-50 d-flex justify-content-center align-items-center">
      <div class="wrapper mt-lg-0 mt-2">
        <kadu-form
          (submitHandler)="onSignUp($event)"
          elClass="d-flex justify-content-center flex-column align-items-center h-100 px-sm-5"
          [controls]="formControls"
          [options]="options"
        >
          <ng-template kaduTemplate let-formGroup>
            <ng-container [formGroup]="formGroup">
              <h4 class="fw-700 mb-3 mt-0">
                {{ "auth.signup.signUpIntoBrendly" | transloco }}
              </h4>

              <div class="d-flex flex-column w-100 mt-2">
                <div class="d-flex w-100 gap-1">
                  <div class="d-flex flex-column w-50">
                    <kadu-input
                      #firstName
                      formControlName="firstName"
                      elClass="p3 p-2"
                      [placeholder]="'auth.signup.form.name' | transloco"
                    ></kadu-input>
                    <app-form-errors
                      [formElement]="firstName"
                      [control]="formGroup.get('firstName')"
                    >
                    </app-form-errors>
                  </div>

                  <div class="d-flex flex-column w-50">
                    <kadu-input
                      #lastName
                      formControlName="lastName"
                      elClass="p3 p-2"
                      [placeholder]="'auth.signup.form.lastName' | transloco"
                    ></kadu-input>
                    <app-form-errors
                      [formElement]="lastName"
                      [control]="formGroup.get('lastName')"
                    >
                    </app-form-errors>
                  </div>
                </div>

                <div class="d-flex flex-column w-100">
                  <div class="d-flex w-100 gap-1">
                    <div class="d-flex flex-column w-50">
                      <kadu-input
                        #email
                        formControlName="email"
                        elClass="p3 p-2"
                        placeholder="Email"
                      ></kadu-input>
                      <app-form-errors
                        [formElement]="email"
                        [control]="formGroup.get('email')"
                        [Errors]="{
                          pattern: 'errors.email' | transloco,
                          emailExists: 'errors.emailExists' | transloco
                        }"
                      >
                      </app-form-errors>
                    </div>

                    <div class="d-flex flex-column w-50">
                      <kadu-select
                        #defaultCountry
                        formControlName="defaultCountry"
                        (changeHandler)="selectCountry($event.value)"
                        [SelectedValue]="selectedCountry"
                        optionLabel="name"
                        [emptyMessage]="
                          'auth.signup.noAvailableCountries' | transloco
                        "
                        [options]="countries"
                      ></kadu-select>
                      <app-form-errors
                        [formElement]="defaultCountry"
                        [control]="formGroup.get('country')"
                      >
                      </app-form-errors>
                    </div>
                  </div>
                </div>

                <div>
                  <kadu-input
                    #phoneNumber
                    formControlName="phoneNumber"
                    elClass="p3 p-2"
                    [placeholder]="'auth.signup.form.phoneNumber' | transloco"
                  ></kadu-input>
                  <app-form-errors
                    [formElement]="phoneNumber"
                    [control]="formGroup.get('phoneNumber')"
                  >
                  </app-form-errors>
                </div>

                <div>
                  <kadu-input
                    #password
                    type="password"
                    formControlName="password"
                    elClass="p3 p-2"
                    [placeholder]="'common.password' | transloco"
                  ></kadu-input>
                  <app-form-errors
                    [formElement]="password"
                    [Errors]="{
                      pattern: 'errors.passwordPattern' | transloco
                    }"
                    [control]="formGroup.get('password')"
                  >
                  </app-form-errors>
                </div>

                <div>
                  <kadu-input
                    #passwordAgain
                    type="password"
                    formControlName="passwordAgain"
                    elClass="p3 p-2"
                    [placeholder]="
                      'auth.signup.form.repeatPassword' | transloco
                    "
                  ></kadu-input>

                  <app-form-errors
                    [formElement]="passwordAgain"
                    [controls]="[formGroup.get('passwordAgain'), formGroup]"
                    [Errors]="{
                      passwordsDoNotMatch:
                        'errors.passwordsDoNotMatch' | transloco
                    }"
                  >
                  </app-form-errors>
                </div>

                <kadu-checkbox
                  inputId="termsAndConditions"
                  formControlName="termsAndConditions"
                  class="p3 w-100 text-left d-flex justify-content-center align-items-center gap-1 terms"
                  [ngClass]="{
                    'invalid-checkbox':
                      formGroup.get('termsAndConditions').errors &&
                      formGroup.get('termsAndConditions').dirty
                  }"
                >
                  <div class="cursor-pointer">
                    {{ "common.agreeWith" | transloco }}
                    <a
                      class="brendly-text-color fw-600"
                      [href]="
                        selectedCountry.name === 'Srbija'
                          ? 'https://brendly.rs/pomoc/uslovi-koriscenja/'
                          : 'https://brendly.hr/pomoc/uvjeti-koristenja/'
                      "
                      target="_blank"
                      >{{ "common.termsOfUse" | transloco }}
                    </a>
                    {{ "common.and" | transloco }}
                    <a
                      class="brendly-text-color fw-600"
                      href="https://brendly.{{
                        selectedCountry.alpha2Code | lowercase
                      }}/pomoc/politika-privatnosti/"
                      target="_blank"
                    >
                      {{ "common.privacyPolicy" | transloco }}
                    </a>
                  </div>
                </kadu-checkbox>
              </div>

              <div class="submit-button mt-2">
                <kadu-button
                  kaduSubmitButton
                  type="submit"
                  elClass="btn btn-medium btn-submit fw-600 w-100"
                  class="d-flex justify-content-center w-100"
                >
                  {{ "auth.signup.createAccount" | transloco }}
                </kadu-button>
                <p
                  routerLink="/login"
                  class="mb-0 mt-2 fw-500 p3 text-clickable cursor-pointer"
                >
                  {{ "auth.signup.existingAccount" | transloco }}
                  {{ "common.signIn" | transloco }}
                </p>
              </div>
            </ng-container>
          </ng-template>
        </kadu-form>
      </div>
    </section>

    <section class="w-50 d-none d-lg-flex flex-column">
      <div>
        <div
          class="h-50 d-flex align-items-start justify-content-between"
          style="margin-top: 2.3rem"
        >
          <div class="quote">
            <img
              class="profile-photo"
              src="../../../../assets/images/jelena.png"
            />
            <p class="mb-1 fw-600 p1 mt-2">Jelena Ačanski</p>
            <p class="mt-0 p3">
              {{ "auth.signup.firstQuote.personInfo" | transloco }}
            </p>
            <p class="lh-20 m-0">
              {{ "auth.signup.firstQuote.description" | transloco }}
            </p>
          </div>
          <img
            class="quote-mark d-xl-block d-none"
            src="../../../../assets/images/quote.svg"
          />
        </div>
        <div class="d-flex align-items-end justify-content-between">
          <img
            class="quote-mark d-none d-xl-block"
            src="../../../../assets/images/quote.svg"
          />
          <div class="quote">
            <img
              class="profile-photo"
              src="../../../../assets/images/radukin.png"
            />
            <p class="mb-1 fw-600 p1 mt-3">Aleksandar Radukin</p>
            <p class="mt-0 p3">
              {{ "auth.signup.secondQuote.personInfo" | transloco }}
            </p>
            <p class="lh-20 mt-0">
              {{ "auth.signup.secondQuote.description" | transloco }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
