<ng-container *kaduLet="priorityNotification$ | async as priorityNotification">
  <ng-container *ngIf="priorityNotification; else regularNotifications">
    <ng-container
      *ngTemplateOutlet="
        notificationTemplate;
        context: { $implicit: priorityNotification, position: 'top' }
      "
    ></ng-container>
  </ng-container>
  <ng-template #regularNotifications>
    <ng-container *ngFor="let notification of notifications$ | async">
      <ng-container
        *ngTemplateOutlet="
          notificationTemplate;
          context: { $implicit: notification, position: 'top' }
        "
      ></ng-container>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #notificationTemplate let-notification let-position="position">
  <div
    (click)="processLinks($event)"
    class="bg-brendly-red"
    *ngIf="notification.position === position"
    [innerHtml]="notification.html"
  ></div>
</ng-template>

<ng-template [ngIf]="authToken$ | async">
  <ng-container *ngIf="shops$ | async as shops">
    <ng-container *kaduLet="userBanState$ | async as userBanState">
      <ng-container #container *transloco="let t">
        <div
          *kaduLet="selectedShop$ | async as selectedShop"
          class="d-flex justify-content-between align-items-center px-2 py-1 bg-white w-100 header"
          style="min-height: 60px"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            style="width: 54%"
          >
            <div
              [ngStyle]="{
                visibility: shops.length > 0 ? 'visible' : 'hidden'
              }"
              class="store"
            >
              <div class="d-flex gap-1">
                <kadu-button
                  style="height: 34px"
                  (clickHandler)="toggleShops(shopsOverlay)"
                  class="d-lg-none"
                  elClass="btn btn-no-border btn-small btn-submit w-100 h-100 d-flex justify-content-between align-items-center px-2 py-0 text-ellipsis"
                >
                  <span [ngClass]="{ active: selectedShop }" class="label">
                    {{
                      selectedShop ? selectedShop.name : t("header.chooseStore")
                    }}
                  </span>
                  <span class="mt-1 ms-3">
                    <i class="pi pi-chevron-down"></i>
                  </span>
                </kadu-button>

                <kadu-button
                  [isDisabled]="userBanState.accountBanned"
                  [kaduTooltip]="'common.userBanTooltip' | transloco"
                  [tooltipDisabled]="!userBanState.accountBanned"
                  style="height: 34px"
                  elClass="btn btn-no-border btn-xs btn-submit px-2 py-0 h-100"
                  class="d-lg-none"
                  [ngStyle]="{
                    visibility: shops.length > 0 ? 'visible' : 'hidden'
                  }"
                  (clickHandler)="publishShop(selectedShop.published)"
                >
                  <i *ngIf="selectedShop?.published" class="bi bi-eye"></i>
                  <svg
                    *ngIf="!selectedShop?.published"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-rocket-takeoff"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"
                    />
                    <path
                      d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"
                    />
                    <path
                      d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"
                    />
                  </svg>
                </kadu-button>
              </div>

              <kadu-select
                [SelectedValue]="selectedShop"
                (changeHandler)="selectShop($event.value)"
                [elClass]="selectedShop ? 'active' : ''"
                [options]="shops"
                optionLabel="name"
                filterBy="name"
                class="d-lg-block d-none"
                [filter]="true"
                elClass="fw-400 my-store"
                [placeholder]="
                  selectedShop ? selectedShop.name : t('header.chooseStore')
                "
              >
                <kadu-anchor
                  ngclass
                  footerTemplate
                  [kaduTooltip]="'common.userBanTooltip' | transloco"
                  [tooltipDisabled]="!userBanState.accountBanned"
                  [path]="['dashboard', 'shop', 'new']"
                  class="border-top d-flex align-items-center"
                  elClass="btn btn-small btn-submit w-100 ps-2 pe-2 {{
                    userBanState.accountBanned ? 'disabled' : ''
                  }}"
                >
                  {{ t("common.addStore") }}
                </kadu-anchor>
              </kadu-select>
            </div>

            <img
              [routerLink]="['dashboard', selectedShop?.id, 'products']"
              src="../../../assets/images/logo-lg.png"
              alt="Brendly"
              class="d-none d-md-block logo cursor-pointer"
            />

            <img
              [routerLink]="['dashboard', selectedShop?.id, 'products']"
              src="../../../assets/images/logo-rewrite.png"
              alt="Brendly"
              class="d-md-none d-sm-block d-none logo cursor-pointer"
            />
          </div>

          <div class="d-flex align-items-center me-1">
            <kadu-select
              [emptyMessage]="'auth.signup.noAvailableCountries' | transloco"
              (changeHandler)="selectCountry($event.value)"
              [options]="countries$ | async"
              elClass="fw-400 my-store active"
              class="me-1"
              [SelectedValue]="country$ | async"
              [ItemTemplate]="itemTemplate"
              [SelectedItemTemplate]="selectedItemTemplate"
            >
              <ng-template let-country #itemTemplate>
                <div class="d-flex gap-2">
                  <i class="{{ country.flagImageUrl }}"></i>
                  <span>{{ country.name }} </span>
                </div>
              </ng-template>

              <ng-template let-selectedValue #selectedItemTemplate>
                <div class="d-flex gap-2 align-items-center">
                  <span>{{ "common.market" | transloco }}</span>
                  <i
                    style="height: 21px; width: 21px"
                    class="{{ selectedValue.flagImageUrl }}"
                  ></i>
                </div>
              </ng-template>
            </kadu-select>

            <kadu-button
              [isDisabled]="userBanState.accountBanned"
              [kaduTooltip]="'common.userBanTooltip' | transloco"
              [tooltipDisabled]="!userBanState.accountBanned"
              *ngIf="shops.length > 0"
              [ngStyle]="{
                visibility: shops.length > 0 ? 'visible' : 'hidden',
                minWidth: selectedShop?.published ? '' : '50%',
                marginRight: selectedShop?.published ? '4px' : ''
              }"
              (clickHandler)="publishShop(selectedShop.published)"
              class="d-none d-lg-flex w-100"
              elClass="lh-2 fw-700 btn btn-xs btn-submit"
            >
              {{
                selectedShop?.published
                  ? t("header.checkoutStore")
                  : (t("header.publishStore") | uppercase)
              }}
            </kadu-button>

            <i
              [ngClass]="{ 'me-4': shops.length > 0 }"
              (click)="menu.toggle($event)"
              class="bi bi-person-fill user me-4 me-lg-0"
            ></i>

            <kadu-slide-menu
              [backLabel]="t('common.back')"
              appendTo="body"
              #menu
              [popup]="true"
              [items]="menuItems$ | async"
            ></kadu-slide-menu>
          </div>
        </div>

        <kadu-overlay
          [closeOnOutterClick]="false"
          [visible]="false"
          [closable]="false"
          [template]="shopsTemplate"
          direction="vertical"
          alignTo="top"
          class="position-relative"
          [vars]="{
            top: '50px',
            overflow: 'unset'
          }"
          #shopsOverlay
        >
        </kadu-overlay>

        <ng-template #shopsTemplate>
          <div class="h-100">
            <ng-container *transloco="let t">
              <kadu-listbox
                #shopsList
                (changeHandler)="selectShop($event.value, shopsOverlay)"
                class="w-100 h-100"
                [options]="shops"
                optionLabel="name"
                [SelectedValue]="selectedShop$.getValue()"
                [filter]="true"
              >
                <kadu-anchor
                  footerTemplate
                  [kaduTooltip]="'common.userBanTooltip' | transloco"
                  [tooltipDisabled]="!userBanState.accountBanned"
                  (clickHandler)="shopsOverlay.close()"
                  [path]="['dashboard', 'shop', 'new']"
                  footerTemplate
                  class="w-100 mt-1 border-top add-shop d-flex"
                  elClass="btn btn-medium btn-submit w-100 ps-2 pe-2 mt-2 {{
                    userBanState.accountBanned ? 'disabled' : ''
                  }}"
                >
                  {{ t("common.addStore") }}
                </kadu-anchor>
              </kadu-listbox>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template [ngIf]="!(authToken$ | async)">
  <div class="image-wrapper" style="min-height: 60px">
    <img
      src="../../../assets/images/logo-lg.png"
      alt="Brendly"
      class="logo"
      height="52"
      width="52"
    />
  </div>
</ng-template>

<ng-container *kaduLet="priorityNotification$ | async as priorityNotification">
  <ng-container *ngIf="priorityNotification; else regularNotifications">
    <ng-container
      *ngTemplateOutlet="
        notificationTemplate;
        context: { $implicit: priorityNotification, position: 'bottom' }
      "
    ></ng-container>
  </ng-container>
  <ng-template #regularNotifications>
    <ng-container *ngFor="let notification of notifications$ | async">
      <ng-container
        *ngTemplateOutlet="
          notificationTemplate;
          context: { $implicit: notification, position: 'bottom' }
        "
      ></ng-container>
    </ng-container>
  </ng-template>
</ng-container>
