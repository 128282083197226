import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { Country } from 'src/app/_models/country';
import { Language } from 'src/app/_models/language';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  countryChange$ = new Subject<Country>();
  country$ = new BehaviorSubject<Country>(
    this.findCountryByName(localStorage.getItem('currentCountry')),
  );
  language$ = new BehaviorSubject<Language>(
    this.findLanguageByCode(localStorage.getItem('currentLanguage')),
  );

  subs = new SubSink();
  selectedLang: string;
  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();

  constructor(
    private translateService: TranslocoService,
    private deviceService: DeviceDetectorService,
  ) {
    this.subs.add(
      this.country$.subscribe((country) => {
        if (country) {
          localStorage.setItem('currentCountry', country.name);
          this.setApiUrl(country.apiUrl);
          this.countryChange$.next(country);
        }
      }),
      this.language$.subscribe((language) => {
        if (language) {
          localStorage.setItem('currentLanguage', language.alpha2Code);
          this.translateService.setActiveLang(
            language.languageCode.toLowerCase(),
          );
        }
      }),
    );
  }

  get currentCountry(): string {
    return localStorage.getItem('currentCountry');
  }

  get currentLanguage(): string {
    return localStorage.getItem('currentLanguage');
  }

  get countries(): Country[] {
    return environment.countries;
  }

  getMarketsForUser(user: {
    id: number;
    isLegalEntity: boolean;
    countryName: string;
  }): Country[] {
    return environment.countries.filter((country) => {
      const market = country.availableForUsersFromCountries.find(
        (c) => c.userFrom === user.countryName,
      );

      if (market) {
        return (
          market.userIdWhitelist?.includes(user.id) ||
          (user.isLegalEntity
            ? market.isUserLegalEntity
            : market.isUserPersonalEntity)
        );
      }

      return false;
    });
  }

  get languages(): Language[] {
    return environment.languages;
  }

  set country(country: Country) {
    this.country$.next(country);
  }

  set language(language: Language) {
    this.language$.next(language);
  }

  setApiUrl(apiUrl: string): void {
    environment.apiUrl = apiUrl;
    localStorage.setItem('apiUrl', apiUrl);
  }

  findCountryByName(wantedCountry: string): Country {
    return this.countries.find((country) => country.name === wantedCountry);
  }

  findLanguageByCode(wantedLanguage: string): Language {
    return this.languages.find(
      (language) => language.alpha2Code === wantedLanguage,
    );
  }

  findCountryCodeByName(countryName: string): string {
    return this.countries.filter((country) => country.name === countryName)[0]
      .alpha2Code;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
