import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DuloImageEditorService {
  visibleBoxPaintOccupation$ = new Subject<any>();

  emitVisibleBoxPaintOccupation(info): void {
    this.visibleBoxPaintOccupation$.next(info);
  }
}
