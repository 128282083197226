<div class="d-flex flex-lg-row flex-column justify-content-between h-100">
  <div
    style="min-width: 135px; flex-basis: 135px"
    class="d-flex flex-column align-items-center justify-content-start p-2"
  >
    <div
      *ngIf="(selected$ | async) && imageSelected"
      style="flex: 1 1 100%"
      class="align-items-end"
    >
      <ng-content select="[currentImagePrintSize]"></ng-content>

      <p class="fw-bold mt-2 p3 mb-0 text-wrap text-center">
        {{ "dashboard.editor.printQuality" | transloco }}:
      </p>
      <ng-container *ngIf="currentImageDPI$ | async as currentImageDPI">
        <p
          class="fw-bold text-wrap text-center p3"
          [ngClass]="{
            'text-success': currentImageDPI > 135,
            'text-warning': currentImageDPI <= 135 && currentImageDPI > 85,
            'text-danger': currentImageDPI <= 85
          }"
        >
          {{ currentImageDPIState }}: {{ currentImageDPI }} DPI
        </p>
      </ng-container>
      <!-- <button (click)="togglePerformance()">
        {{ performanceCheckStarted ? "Zaustavi merenje" : "Zapocni merenje" }}
      </button> -->
      <div
        class="d-flex justify-content-center align-items-center flex-row flex-lg-column"
      >
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="expand()"
        >
          <img
            src="assets/editor-icons/fit.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="alignVertical()"
        >
          <img
            src="assets/editor-icons/align_vertical_center.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="alignHorizontal()"
        >
          <img
            src="assets/editor-icons/align_horizontal_center.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="alignTop()"
        >
          <img
            src="assets/editor-icons/align_top.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="alignBottom()"
        >
          <img
            src="assets/editor-icons/align_bottom.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="alignRight()"
        >
          <img
            src="assets/editor-icons/align_right.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
        <div
          class="position-relative h-px-25 w-px-25 p-1 m-1 circle-shadow bg-brendly-red d-flex justify-content-center align-items-center"
          (click)="alignLeft()"
        >
          <img
            src="assets/editor-icons/align_left.svg"
            height="16"
            width="16"
            alt=""
          />
        </div>
      </div>
    </div>

    <kadu-button
      *ngIf="
        !imageSelected && image.allowBackgroundColorChooser && visibleEditingBox
      "
      type="text"
      class="w-100"
      elClass="btn btn-submit btn-small"
      [cpPosition]="'bottom'"
      [(colorPicker)]="visibleEditingBox.backgroundColor"
      [cpCmykEnabled]="true"
      [value]="visibleEditingBox.backgroundColor"
      (colorPickerChange)="setBackgroundColor()"
    >
      {{ "dashboard.editor.chooseBgColor" | transloco }}
    </kadu-button>
  </div>
  <div style="flex: 1" class="position-relative">
    <div [class.position-absolute]="screen === 'desktop'" #canvasWrapper>
      <canvas class="w-100 h-100" #canvas> </canvas>
      <div class="d-flex">
        <div class="" *ngIf="devEnvironment">
          <canvas class="position-absolute" #debugCanvas></canvas>
        </div>
        <div
          class="position-absolute"
          [class.d-none]="!devEnvironment"
          style="left: 100%"
        >
          <canvas
            class="position-absolute"
            #canvasWithTransparentObjects
          ></canvas>
        </div>
      </div>
    </div>
  </div>

  <div
    class="d-inline-flex flex-row flex-lg-column justify-content-center justify-content-lg-start align-items-center"
    style="min-width: 65px; flex-basis: 40px; flex-wrap: wrap"
    [ngStyle]="{ height: screen === 'desktop' ? '300px' : '65px' }"
  >
    <div
      [kaduTooltip]="color.name"
      *ngFor="let color of colors"
      class="color circle-shadow w-lg-100 mx-2 mx-lg-0"
      (click)="changeColorAndBackground(color)"
      [ngStyle]="createColor(color)"
    >
      <i class="fas fa-circle" *ngIf="color.selected"></i>
    </div>
  </div>
</div>

<!-- filters -->
<!-- <div style="flex-basis: 400px" class="flex-grow-0 flex-shrink-0">
      <div class="w-100" *ngIf="false">
        <div class="col-12" *ngIf="imageSelected">
          <div *ngIf="isImageJPG" class="col-12">
            <p>
              {{ "dashboard.editor.filterOnPng" | transloco }}
            </p>
          </div>
          <div *ngIf="!isImageJPG" class="col-12">
            <div class="row">
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[4]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(brownie.checked, 4)"
                  #brownie
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[4]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(brownie.checked, 4)"
                  #brownie
                  >Brownie</kadu-input
                >
              </div>
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[3]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(sepia.checked, 3)"
                  #sepia
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[3]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(sepia.checked, 3)"
                  #sepia
                  >Sepia</kadu-input
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[19]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(blackwhite.checked, 19)"
                  #blackwhite
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[19]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(blackwhite.checked, 19)"
                  #blackwhite
                  >Black/white</kadu-input
                >
              </div>
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[1]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(invert.checked, 1)"
                  #invert
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[1]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(invert.checked, 1)"
                  #invert
                  >Invert</kadu-input
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[9]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(vintage.checked, 9)"
                  #vintage
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[9]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(vintage.checked, 9)"
                  #vintage
                  >Vintage</kadu-input
                >
              </div>
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[18]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(kodachrome.checked, 18)"
                  #kodachrome
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[18]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(kodachrome.checked, 18)"
                  #kodachrome
                  >Kodachrome</kadu-input
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[14]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(technicolor.checked, 14)"
                  #technicolor
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[14]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(technicolor.checked, 14)"
                  #technicolor
                  >Technicolor</kadu-input
                >
              </div>
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[15]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(polaroid.checked, 15)"
                  #polaroid
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[15]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(polaroid.checked, 15)"
                  #polaroid
                  >Polaroid</kadu-input
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[12]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(sharpen.checked, 12)"
                  #sharpen
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[12]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(sharpen.checked, 12)"
                  #sharpen
                  >Sharpen</kadu-input
                >
              </div>
              <div class="col-6">
                <kadu-input
                  *ngIf="!activeObject?.filters[13]"
                  type="checkbox"
                  (clickHandler)="toggleFilter(emboss.checked, 13)"
                  #emboss
                ></kadu-input>
                <kadu-input
                  *ngIf="activeObject?.filters[13]"
                  checked
                  type="checkbox"
                  (clickHandler)="toggleFilter(emboss.checked, 13)"
                  #emboss
                  >Emboss</kadu-input
                >
              </div>
            </div>
            <div class="col-7">
              <div class="row">
                <div class="col-6">
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(removeColor.checked, 2)"
                      #removeColor
                    />Remove color>
                  </div>
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(contrast.checked, 6)"
                      #contrast
                    />Contrast>
                  </div>
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(grayscale.checked, 0)"
                      #grayscale
                    />Grayscale>
                  </div>
                </div>
                <div class="col-6">
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(brightness.checked, 5)"
                      #brightness
                    />Brightness>
                  </div>
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(noise.checked, 8)"
                      #noise
                    />Noise>
                  </div>
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(pixelate.checked, 10)"
                      #pixelate
                    />Pixelate>
                  </div>
                  <div class="col-12">
                    <input
                      type="checkbox"
                      (click)="toggleFilter(colorFilter.checked, 0)"
                      #colorFilter
                    />Color filter>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1">
              <input
                class="color-picker col-12"
                [(colorPicker)]="color"
                appResizer
                [style.background]="color"
              />
            </div>
          </div>
          <div class="finish col-12">
            <kadu-button
              elClass="btn btn-submit"
              (clickHandler)="closeFilters()"
            >
              {{ "dashboard.editor.finish" | transloco }}
            </kadu-button>
          </div>
        </div>
        <div class="col-12" *ngIf="textSelected">
          <div class="col-12">
            <div>Opacity</div>
            <div>
              <input
                type="number"
                class="editor-margin-top-low form-control"
                [(ngModel)]="activeObject.opacity"
                (change)="setOpacity()"
                min="0"
                max="1"
                step="0.01"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="editor-margin-top-low">Fill</div>

            <div class="color-picker-wrapper">
              <input
                type="text"
                class="form-control editor-margin-top-low"
                [cpPosition]="'bottom'"
                [(colorPicker)]="activeObject.fill"
                [value]="activeObject.fill"
                (colorPickerChange)="setFill()"
              />
              <span
                [style.background]="activeObject.fill"
                class="color-picker-value editor-margin-top-low"
              ></span>
            </div>
          </div>
          <div class="col-12">
            <div class="editor-margin-top-low">Font family</div>
            <div>
              <select
                [(ngModel)]="activeObject.fontFamily"
                class="form-control editor-margin-top-low"
                (change)="loadFont()"
              >
                <option value="arial">Arial</option>
                <option value="helvetica" selected>Helvetica</option>
                <option value="Helvetica Neue">Helvetica Neue</option>
                <option value="verdana">Verdana</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Garamond">Garamond</option>
                <option value="Trajan">Trajan</option>
                <option value="Timothy">Timothy</option>
                <option value="Tomato Soup">Tomato Soup</option>
                <option value="Nature Spirit">Nature Spirit</option>
                <option value="Jocker Extrude">Jocker Extrude</option>
                <option value="courier">Courier</option>
                <option value="Roboto">Roboto</option>
                <option value="Open Sans">Open Sans</option>
                <option value="Zilla Slab">Zilla Slab</option>
                <option value="Lato">Lato</option>
                <option value="Bellefair">Bellefair</option>
                <option value="Fresca">Fresca</option>
                <option value="Raleway">Raleway</option>
                <option value="Open Sans Condensed">Open Sans Condensed</option>
                <option value="Indie Flower">Indie Flower</option>
                <option value="Josefin Sans">Josefin Sans</option>
                <option value="Inconsolata">Inconsolata</option>
                <option value="Pacifico">Pacifico</option>
                <option value="Gloria Hallelujah">Gloria Hallelujah</option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-item-body text-center editor-margin-top-high">
              <div class="btn-group" role="group" aria-label="...">
                <kadu-button
                  type="button"
                  elClass="btn btn-submit btn-sm"
                  [ngClass]="{ active: activeObject.fontWeight }"
                  (clickHandler)="setBold()"
                >
                  <i class="fa fa-bold"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-submit btn-sm"
                  [ngClass]="{ active: activeObject.fontStyle === 'italic' }"
                  (clickHandler)="setFontStyle()"
                >
                  <i class="fa fa-italic"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-submit btn-sm"
                  [ngClass]="{ active: activeObject.underline }"
                  (clickHandler)="setUnderline()"
                >
                  <i class="fa fa-underline"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-submit btn-sm"
                  [ngClass]="{ active: activeObject.overline }"
                  (clickHandler)="setOverline()"
                >
                  <i class="fa fa-underline fa-flip-vertical"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-submit btn-sm"
                  [ngClass]="{ active: activeObject.linethrough }"
                  (clickHandler)="setLineThrough()"
                >
                  <i class="fa fa-strikethrough"></i>
                </kadu-button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="custom-item-body text-center editor-margin-top-high">
              <div class="btn-group" role="group" aria-label="...">
                <kadu-button
                  type="button"
                  elClass="btn btn-submit btn-sm"
                  [ngClass]="{ active: activeObject.textAlign === 'left' }"
                  (clickHandler)="setTextAlign('left')"
                >
                  <i class="fa fa-align-left"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-primary btn-sm"
                  [ngClass]="{ active: activeObject.textAlign === 'center' }"
                  (clickHandler)="setTextAlign('center')"
                >
                  <i class="fa fa-align-center"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-primary btn-sm"
                  [ngClass]="{ active: activeObject.textAlign === 'right' }"
                  (clickHandler)="setTextAlign('right')"
                >
                  <i class="fa fa-align-right"></i>
                </kadu-button>
                <kadu-button
                  type="button"
                  elClass="btn btn-primary btn-sm"
                  [ngClass]="{ active: activeObject.textAlign === 'justify' }"
                  (clickHandler)="setTextAlign('justify')"
                >
                  <i class="fa fa-align-justify"></i>
                </kadu-button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div>
              <div class="editor-margin-top-low">Font Size</div>
              <div>
                <kadu-input
                  elClass="form-control editor-margin-top-low"
                  type="number"
                  [(ngModel)]="activeObject.fontSize"
                  (inputHandler)="setFontSize()"
                  step="10"
                  min="10"
                  max="3000"
                ></kadu-input>
              </div>
            </div>
            <div>
              <div class="editor-margin-top-low">Line Height</div>
              <div>
                <kadu-input
                  elClass="form-control editor-margin-top-low"
                  type="number"
                  [(ngModel)]="activeObject.lineHeight"
                  (inputHandler)="setLineHeight()"
                  step="0.01"
                  min="0.1"
                  max="10"
                ></kadu-input>
              </div>
            </div>
            <div>
              <div class="editor-margin-top-low">Char Spacing</div>
              <div>
                <kadu-input
                  elClass="form-control editor-margin-top-low"
                  type="number"
                  [(ngModel)]="activeObject.charSpacing"
                  (inputHandler)="setCharSpacing()"
                  step="1"
                  min="-20"
                  max="80"
                ></kadu-input>
              </div>
            </div>
          </div>
          <div class="finish col-12">
            <kadu-button
              elClass="btn btn-submit"
              (clickHandler)="closeFilters()"
            >
              {{ "dashboard.editor.finish" | transloco }}
            </kadu-button>
          </div>
        </div>
      </div>
    </div> -->
<!-- filters end -->
