import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SKIP_DTO_TRANSFORM_INTERCEPTOR,
  SKIP_INTERCEPTORS,
} from './skip-interceptors';

import camelcaseKeys from 'camelcase-keys';
import * as snakecaseKeys from 'snakecase-keys';

@Injectable({
  providedIn: 'root',
})
export class DtoTransformInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }
    if (
      req.headers.get(SKIP_DTO_TRANSFORM_INTERCEPTOR) ===
      SKIP_DTO_TRANSFORM_INTERCEPTOR
    ) {
      return next.handle(req);
    }

    if (req.body && !(req.body instanceof FormData)) {
      req = req.clone({
        body: Object.assign(
          req.body instanceof Array ? [] : {},
          req.body,
          snakecaseKeys(req.body, {
            deep: true,
            exclude: [/.*\..*/],
          }),
        ),
      });
    }

    if (req.params && req.params['map']) {
      req.params['map'] = new Map(
        Object.entries(snakecaseKeys(Object.fromEntries(req.params['map']))),
      );
    }

    return next.handle(req).pipe(
      map((res: HttpResponse<unknown>) => {
        if (!res.body) return res;
        return res.clone({
          body: Object.assign(
            res.body instanceof Array ? [] : {},
            res.body,
            camelcaseKeys(res.body, {
              deep: true,
              exclude: [/.*\..*/],
            }),
          ),
        });
      }),
    );
  }
}
