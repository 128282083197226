<ng-container *transloco="let t">
  <div class="wrapper">
    <div
      class="w-100 d-flex flex-column align-items-center justify-content-evenly h-100"
    >
      <ng-template
        [ngIf]="!(instructionsSent | async) && !(passwordReset | async)"
      >
        <div class="w-75">
          <div>
            <h4 class="fw-700">{{ t("common.forgottenPassword") }}</h4>
            <p class="p2 mb-0 mt-3 text-start">
              {{ t("auth.forgotPassword.instructions") }}
            </p>
          </div>

          <kadu-form
            elClass="w-100 d-flex flex-column mt-3"
            (submitHandler)="emailInstructions($event.email, 'send')"
            [controls]="emailFormControls"
          >
            <ng-template kaduTemplate let-formGroup>
              <ng-container [formGroup]="formGroup">
                <kadu-input
                  #email
                  formControlName="email"
                  elClass="p3 p-2"
                  placeholder="Email"
                ></kadu-input>
                <app-form-errors
                  [formElement]="email"
                  [control]="formGroup.controls['email']"
                  [Errors]="{
                    pattern: t('errors.email'),
                    emailNotFound: 'errors.forgottenPasswordEmail' | transloco
                  }"
                ></app-form-errors>

                <kadu-button
                  kaduSubmitButton
                  type="submit"
                  class="d-flex justify-content-center w-100"
                  elClass="fw-600 btn btn-medium btn-submit w-75 mt-2"
                >
                  {{ t("auth.forgotPassword.resetPassword") }}
                </kadu-button>
                <p
                  class="fw-500 mt-2 p3 text-clickable cursor-pointer"
                  routerLink="/login"
                >
                  {{ t("common.signIn") }}
                </p>
              </ng-container>
            </ng-template>
          </kadu-form>
        </div>
      </ng-template>

      <ng-template [ngIf]="instructionsSent | async">
        <div class="w-75 d-flex flex-column h-100 justify-content-evenly">
          <h4 class="fw-700 mt-2">
            {{ t("auth.forgotPassword.passwordChange") }}
          </h4>
          <p class="p2 mb-0 text-justify">
            {{
              t("auth.forgotPassword.successfulChange", {
                email: (resetEmail$ | async)
              })
            }}
          </p>
          <p class="p2 mb-0 mt-1 text-justify">
            {{ t("auth.forgotPassword.emailNotFound") }}
          </p>
          <kadu-button
            type="submit"
            (clickHandler)="emailInstructions(null, 'resend')"
            class="w-100"
            elClass="fw-600 btn btn-medium btn-submit w-50 mt-1"
          >
            {{ t("common.resend") }}
          </kadu-button>
          <p
            class="fw-500 cursor-pointer text-clickable p3"
            routerLink="/login"
          >
            {{ t("common.signIn") }}
          </p>
        </div>
      </ng-template>

      <ng-template [ngIf]="passwordReset | async">
        <div class="w-100">
          <kadu-form
            elClass="d-flex flex-column justify-content-center"
            class="w-100"
            (submitHandler)="resetPassword($event)"
            [options]="resetPasswordValidators"
            [controls]="resetPasswordControls"
          >
            <ng-template kaduTemplate let-formGroup>
              <ng-container [formGroup]="formGroup">
                <h4 class="fw-700">
                  {{ t("auth.forgotPassword.passwordChange") }}
                </h4>

                <div>
                  <kadu-input
                    #password
                    formControlName="password"
                    type="password"
                    elClass="p3 p-2 w-75"
                    class="mt-3 w-100"
                    [placeholder]="t('common.password')"
                  ></kadu-input>
                  <app-form-errors
                    [formElement]="password"
                    [control]="formGroup.controls['password']"
                    [Errors]="{
                      pattern: t('errors.passwordPattern')
                    }"
                  ></app-form-errors>
                </div>

                <div>
                  <kadu-input
                    #passwordAgain
                    type="password"
                    formControlName="passwordAgain"
                    elClass="p3 p-2 w-75"
                    class="w-100"
                    [placeholder]="t('auth.forgotPassword.confirmPassword')"
                  ></kadu-input>
                  <app-form-errors
                    [formElement]="passwordAgain"
                    [controls]="[formGroup.get('passwordAgain'), formGroup]"
                    [Errors]="{
                      passwordsDoNotMatch:
                        'errors.passwordsDoNotMatch' | transloco,
                      invalidToken:
                        'errors.resetPasswordInvalidToken' | transloco,
                      invalidPassword:
                        'errors.resetPasswordInvalidPassword' | transloco
                    }"
                  >
                  </app-form-errors>
                </div>

                <div class="mt-2">
                  <kadu-button
                    kaduSubmitButton
                    type="submit"
                    class="d-flex justify-content-center w-100"
                    elClass="fw-600 btn btn-medium btn-submit w-50"
                  >
                    {{ t("common.confirm") }}
                  </kadu-button>
                  <p
                    class="fw-500 mt-2 text-clickable p3 cursor-pointer"
                    routerLink="/login"
                  >
                    {{ t("common.signIn") }}
                  </p>
                </div>
              </ng-container>
            </ng-template>
          </kadu-form>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
