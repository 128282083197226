import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth/auth.service';
import {
  SKIP_ERROR_HANDLING_INTERCEPTORS,
  SKIP_INTERCEPTORS,
} from './skip-interceptors';
import { PopupService } from '../_services/popup/popup.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingInterceptorService implements HttpInterceptor {
  text: string;
  title: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    private popupService: PopupService,
    private translateService: TranslocoService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }

    if (
      req.headers.get(SKIP_ERROR_HANDLING_INTERCEPTORS) ===
      SKIP_ERROR_HANDLING_INTERCEPTORS
    ) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (Math.floor(err.status / 100) === 5) {
          this.title = this.translateService.translate('errors.500');
        } else if (err.status === 401) {
          this.title = this.translateService.translate('errors.401');
        } else if (err.status === 403) {
          this.title = this.translateService.translate('errors.403');
          this.authService.logout();
          this.router.navigate(['/']);
        } else if (Math.floor(err.status / 100) === 4) {
          return throwError(err);
        }
        this.popupService.fire({
          type: 'error',
          titleText: this.title,
          text: this.text,
        });
        return throwError(err);
      }),
    );
  }
}
