import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-signup-survey',
  templateUrl: './signup-survey.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupSurveyComponent implements OnInit, OnDestroy {
  form: FormGroup;

  customerSegmentFreeEntry = new FormControl('');
  refererOriginFreeEntry = new FormControl('');

  subs = new SubSink();
  constructor(private fb: FormBuilder, public ref: DynamicDialogRef) {}

  ngOnInit = (): void => {
    this.form = this.fb.group({
      customer_segment: ['', Validators.required],
      referer_origin: ['', Validators.required],
    });

    this.subs.add(
      this.form.get('customer_segment').valueChanges.subscribe((value) => {
        if (value !== 'false' && this.form.get('customer_segment_free_entry')) {
          this.form.removeControl('customer_segment_free_entry');
        } else if (
          value === 'false' &&
          !this.form.get('customer_segment_free_entry')
        ) {
          this.form.addControl(
            'customer_segment_free_entry',
            this.customerSegmentFreeEntry,
          );
        }
      }),
    );
    this.subs.add(
      this.form.get('referer_origin').valueChanges.subscribe((value) => {
        if (value !== 'false' && this.form.get('referer_origin_free_entry')) {
          this.form.removeControl('referer_origin_free_entry');
        } else if (
          value === 'false' &&
          !this.form.get('referer_origin_free_entry')
        ) {
          this.form.addControl(
            'referer_origin_free_entry',
            this.refererOriginFreeEntry,
          );
        }
      }),
    );
  };

  submit(): void {
    const values = this.form.value;
    if (values['customer_segment'] === 'false') {
      values['customer_segment'] = values['customer_segment_free_entry'];
      delete values['customer_segment_free_entry'];
    }
    if (values['referer_origin'] === 'false') {
      values['referer_origin'] = values['referer_origin_free_entry'];
      delete values['referer_origin_free_entry'];
    }
    this.ref.close(this.form.value);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
