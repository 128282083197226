import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Shop } from '../../_models/shop';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Category } from '../../_models/category';
import { concatMap, map, tap } from 'rxjs/operators';
import { PaginatedResult } from '@kadung/kadung/kadu-table';
import { Params } from '@angular/router';
import { SKIP_DTO_TRANSFORM_INTERCEPTOR } from 'src/app/_interceptors/skip-interceptors';
import { CompensationData } from 'src/app/_models/compensation-data';
import { PaymentOption } from '../../_models/payment-option';
import { BillingOption } from '../../_models/billing-option';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';
import { ReclamationPolicy } from '../../_models/reclamation-policy';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  selectedShop$ = new BehaviorSubject<Shop>(null);
  shops$ = new BehaviorSubject<Shop[]>(null);

  constructor(private http: HttpClient, private authService: AuthService) {}

  downloadInvoices(params: Params): Observable<any> {
    return this.http.get<string[]>(`${environment.apiUrl}invoices/download`, {
      params,
    });
  }

  downloadEmailListCsv(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/email_list_csv`,
    );
  }

  getOrderStateTransitions(orderId: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}orders/${orderId}/order_state_transitions`,
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
        ),
      },
    );
  }

  getOrderStates(): Observable<any> {
    return this.http.get(`${environment.apiUrl}orders/order_states`);
  }

  getOrderFulfillmentTypes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}orders/order_fulfillment_types`);
  }

  getShopById(id: number): Observable<Shop> {
    return this.http.get<Shop>(`${environment.apiUrl}e_commerces/${id}`);
  }

  getShops(): Observable<Shop[]> {
    return this.http
      .get<Shop[]>(
        `${
          environment.apiUrl
        }users/${this.authService.getUserId()}/e_commerces`,
        {
          headers: new HttpHeaders().set(
            SKIP_DTO_TRANSFORM_INTERCEPTOR,
            SKIP_DTO_TRANSFORM_INTERCEPTOR,
          ),
        },
      )
      .pipe(tap((shops) => this.shops$.next(shops)));
  }

  postShop(data: any): Observable<Shop> {
    return this.http
      .post<Shop>(
        `${environment.apiUrl}e_commerces`,
        {
          ...data,
          owner_id: this.authService.getUserId(),
        },
        {
          headers: new HttpHeaders().set(
            SKIP_LOADING_INTERCEPTOR,
            SKIP_LOADING_INTERCEPTOR,
          ),
        },
      )
      .pipe(concatMap((shop) => this.getShops().pipe(map((shops) => shop))));
  }

  deleteShop(id: number): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}e_commerces/${id}`)
      .pipe(concatMap((shop) => this.getShops()));
  }

  selectShop(shop: Shop): void {
    this.selectedShop$.next(shop);
  }

  getShopOrders(params: any): Observable<PaginatedResult<any>> {
    return this.http.get<PaginatedResult<any>>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/orders`,
      {
        params,
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      },
    );
  }

  getShopBalance(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/e_commerce_balance`,
    );
  }

  getShopCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/categories`,
    );
  }

  updateSettings(settings): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerces/${this.selectedShop$.getValue().id}`,
      settings,
      {
        headers: new HttpHeaders()
          .set(SKIP_DTO_TRANSFORM_INTERCEPTOR, SKIP_DTO_TRANSFORM_INTERCEPTOR)
          .set(SKIP_LOADING_INTERCEPTOR, SKIP_LOADING_INTERCEPTOR),
      },
    );
  }

  updateMarketing(settings): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/marketing`,
      settings,
    );
  }

  getMarketing(): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}e_commerces/${
          this.selectedShop$.getValue().id
        }/marketing`,
      )
      .pipe(
        map((res) => {
          return {
            facebookPixelId: res['facebook_pixel_id'],
            facebookDomainVerificationId:
              res['facebook_domain_verification_id'],
            googleTagManagerAccountId: res['gtm_account_id'],
            googleAnalyticsTrackingId: res['ga_tracking_id'],
            gtmInitError: res['gtm_init_error'],
            gtmInitStarted: res['started_gtm_init'],
            gtmInitCompleted: res['gtm_initialized'],
            googleSearchConsoleDomainVerificationId:
              res['google_search_console_domain_verification_id'],
          };
        }),
      );
  }

  getGoogleOauthAuthorizationRequestUri(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}analytics/get_google_oauth_authorization_request_uri`,
    );
  }

  initializeGTM(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}analytics/initialize_gtm`, {
      authorization_code: data.authorizationCode,
      ga_tracking_id: data.googleAnalyticsTrackingId,
      gtm_account_id: data.googleTagManagerAccountId,
      e_commerce_id: this.selectedShop$.getValue().id,
    });
  }

  destroyGTM(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}analytics/destroy_gtm`, {
      authorization_code: data.authorizationCode,
      e_commerce_id: this.selectedShop$.getValue().id,
    });
  }

  updateImage(type: string, image): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    return this.http.put(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/${type}`,
      formData,
    );
  }

  previewShop(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/preview`,
    );
  }

  publishShop(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/publish`,
    );
  }

  getCOFData(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_cof_data`,
    );
  }

  getCompensationData(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_compensation_data`,
    );
  }

  getPaymentOptions(): Observable<PaymentOption[]> {
    return this.http.get<PaymentOption[]>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/payment_options`,
    );
  }

  getBillingOptions(): Observable<BillingOption[]> {
    return this.http.get<BillingOption[]>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/billing_options`,
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
        ),
      },
    );
  }

  saveCOFData(data: any): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_cof_data`,
      data,
      {
        headers: new HttpHeaders().set(
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
          SKIP_DTO_TRANSFORM_INTERCEPTOR,
        ),
      },
    );
  }

  saveShopPaymentOption(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/payment_options`,
      data,
    );
  }

  deleteShopPaymentOption(paymentOptionId: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/payment_options/${paymentOptionId}`,
    );
  }

  chooseCOFData(id): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_cof_data/${id}/choose`,
      {},
    );
  }

  chooseCompensationData(id): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_compensation_data/${id}/choose`,
      {},
    );
  }

  deleteCOFData(id): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_cof_data/${id}`,
      {},
    );
  }

  deleteCompensationData(id): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_compensation_data/${id}`,
      {},
    );
  }

  saveCompensationData(compensationData: CompensationData): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/user_compensation_data`,
      compensationData,
    );
  }

  savePaypal(settings): Observable<any> {
    settings.ecommerceId = this.selectedShop$.getValue().id;
    return this.http.post(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/paypal`,
      settings,
    );
  }

  deletePaypal(): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/paypal`,
    );
  }

  logPaymentRegistrationResponse(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/log_payment_registration_response`,
      {
        response: JSON.stringify(data),
      },
    );
  }

  getReclamationPolicy(): Observable<ReclamationPolicy> {
    return this.http.get<ReclamationPolicy>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/reclamation_policy`,
    );
  }

  updateReclamationPolicy(
    data: ReclamationPolicy,
  ): Observable<ReclamationPolicy> {
    return this.http.put<ReclamationPolicy>(
      `${environment.apiUrl}e_commerces/${
        this.selectedShop$.getValue().id
      }/reclamation_policy`,
      {
        ...data,
      },
    );
  }
}
