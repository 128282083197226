import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResult } from '@kadung/kadung/kadu-table';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SKIP_LOADING_INTERCEPTOR } from 'src/app/_interceptors/loading-interceptor.service';
import {
  SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
  SKIP_JWT_RENEW_INTERCEPTOR,
} from 'src/app/_interceptors/skip-interceptors';
import { DefaultPreferences } from 'src/app/_models/defaultPreferences';
import { Notification } from 'src/app/_models/notification';
import { NotificationService } from 'src/app/_services/notification/notification.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../_services/auth/auth.service';
import { MarketWithEcommerce } from '../../_models/market-with-ecommerce';
import { UserBanState } from '../../_models/user-ban-state';
import { SubSink } from 'subsink';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userBanState$ = new BehaviorSubject<UserBanState>({
    accountBanned: false,
    banReason: null,
  });
  userBanned;
  subs = new SubSink();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translateService: TranslocoService,
  ) {
    this.subs.add(
      this.userBanState$.subscribe((userBanState) => {
        if (userBanState.accountBanned) {
          this.userBanned = {
            position: 'top',
            html: `<p class="p-1 text-white m-0">${this.translateService.translate(
              'common.userBannedTitle',
            )} ${this.userBanState$.value.banReason}</p>`,
            name: 'userBanned',
            preventOthers: true,
          };

          if (
            !this.userBanState$.value?.accountBanned &&
            this.authService.isUserLoggedIn()
          ) {
            this.notificationService.addNotification(this.userBanned);
          } else {
            this.notificationService.removeNotification(this.userBanned);
          }
        }
      }),
    );
  }

  signUp(authData): Observable<any> {
    return this.http.post(`${environment.apiUrl}users/register`, authData, {
      headers: new HttpHeaders()
        .set(
          SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
          SKIP_JWT_AUTHORIZATION_INTERCEPTOR,
        )
        .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR)
        .set(SKIP_LOADING_INTERCEPTOR, SKIP_LOADING_INTERCEPTOR),
    });
  }

  updateDefaultPreferences(defaultPreferences): Observable<DefaultPreferences> {
    return this.http.put<DefaultPreferences>(
      `${
        environment.apiUrl
      }users/${this.authService.getUserId()}/update_default_preferences`,
      {
        country: defaultPreferences.country,
        language: defaultPreferences.language,
      },
      {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      },
    );
  }

  getDefaultPreferences(): Observable<DefaultPreferences> {
    return this.http.get<DefaultPreferences>(
      `${
        environment.apiUrl
      }users/${this.authService.getUserId()}/default_preferences`,
      {
        headers: new HttpHeaders().set(
          SKIP_LOADING_INTERCEPTOR,
          SKIP_LOADING_INTERCEPTOR,
        ),
      },
    );
  }

  getUserBalance(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}users/${this.authService.getUserId()}/user_balance`,
    );
  }

  getUserOrders(params: any): Observable<PaginatedResult<any>> {
    return this.http.get<PaginatedResult<any>>(
      `${environment.apiUrl}users/${this.authService.getUserId()}/orders`,
      { params },
    );
  }

  getMarketWithEcommerces(): Observable<MarketWithEcommerce> {
    return this.http.get<MarketWithEcommerce>(
      `${
        environment.apiUrl
      }users/${this.authService.getUserId()}/e_commerces_per_market`,
    );
  }

  getUserBanState(): Observable<UserBanState> {
    return this.http
      .get<UserBanState>(
        `${
          environment.apiUrl
        }users/${this.authService.getUserId()}/user_ban_state`,
      )
      .pipe(
        tap((userBanState) => {
          this.userBanState$.next(userBanState);

          if (userBanState.accountBanned) {
            this.notificationService.addNotification(this.userBanned);
          } else {
            this.notificationService.removeNotification(this.userBanned);
          }
        }),
      );
  }
}
