import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppService } from '../_services/app/app.service';
import { AuthService } from '../_services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigResolver implements Resolve<boolean> {
  constructor(
    private authService: AuthService,
    private appService: AppService,
  ) {}

  resolve(): boolean | Promise<boolean> | Observable<boolean> {
    if (!this.authService.isUserLoggedIn()) {
      let country = this.appService.countries.find((country) =>
        window.location.href.includes(country.alpha2Code.toLowerCase()),
      );

      // legacy dev domains - localhost
      if (!country) {
        country = this.appService.countries[0];
        this.appService.country = country;
        this.appService.language = this.appService.findLanguageByCode(
          country.alpha2Code,
        );
        return of(true);
      }

      // check if current country and language are present in local storage
      if (
        this.appService.currentCountry &&
        this.appService.currentLanguage &&
        !country
      ) {
        this.appService.country = this.appService.findCountryByName(
          this.appService.currentCountry,
        );
        this.appService.language = this.appService.findLanguageByCode(
          this.appService.currentLanguage,
        );
      } else {
        this.appService.country = country;
        this.appService.language = this.appService.findLanguageByCode(
          country.alpha2Code,
        );
      }
    }

    return of(true);
  }
}
