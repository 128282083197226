import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './_pages/login/login.component';
import { SignupComponent } from './_pages/signup/signup.component';
import { AlreadyLoggedInGuard } from './../_guards/already-logged-in.guard';
import { ForgotPasswordComponent } from './_pages/forgot-password/forgot-password.component';
import { AppConfigResolver } from '../_resolvers/app-config.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    resolve: [AppConfigResolver],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'login' },
      {
        path: 'signup',
        canActivate: [AlreadyLoggedInGuard],
        component: SignupComponent,
      },
      {
        path: 'login',
        canActivate: [AlreadyLoggedInGuard],
        component: LoginComponent,
      },
      { path: 'reset-password', component: ForgotPasswordComponent },
      { path: '**', redirectTo: 'login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
